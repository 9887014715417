import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useRef } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import PasswordPolicy from "../components/PasswordPolicy";
import Select from "../components/Select";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { PASSWORD_POLICY, REQUIRED_RULE } from "../helpers/Form";
import useCreateAdminAccount from "../hooks/api/useCreateAdminAccount";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";

const ROLES = [
  {
    text: "Operator",
    value: "operator",
  },
  {
    text: "Admin",
    value: "admin",
  },
];

const UsernameInput = styled(TextField).attrs({
  name: "username",
  label: "Username",
  rules: REQUIRED_RULE,
})({});

// const NameInput = styled(TextField).attrs({
//   name: "name",
//   label: "Full Name",
//   rules: REQUIRED_RULE,
// })({});

const RoleSelect = styled(Select).attrs({
  name: "role",
  label: "Role",
  rules: REQUIRED_RULE,
  items: ROLES,
  displayEmpty: false,
})({});

const CreditInput = styled(TextField).attrs({
  name: "credit",
  label: "Credit",
  type: "number",
  rules: REQUIRED_RULE,
})({});

const PasswordInput = styled(TextField).attrs({
  name: "password",
  label: "Password",
  type: "password",
  autoComplete: "new-password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

// const StatusSwitch = styled(Switch).attrs({
//   name: "active",
//   label: "Active",
// })({});

function AddAdminAccount() {
  const { setPageTitle, setMessage } = useAppContext();
  const [, loading, create] = useCreateAdminAccount();

  const form = useForm({
    defaultValues: {
      role: ROLES[0]?.value,
    },
  });
  const { handleSubmit, watch, reset } = form;

  const password = useRef();
  password.current = watch("password", "");

  useMount(() => {
    setPageTitle(`Add Admin Account`);
  });

  async function onSubmit({ username, ...body }) {
    try {
      await create({
        ...body,
        username: `${username}`.toLowerCase(),
      });
      setMessage("Account added");
      reset();
    } catch (ex) {
      const { message = "Unable create admin account" } = ex;
      setMessage({ message });
    }
  }

  function renderDetail() {
    const policyProps = {
      value: password.current,
      isSubmitted: false,
    };

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <UsernameInput />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordInput />
                    <PasswordPolicy {...policyProps} />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <NameInput />
                  </Grid> */}
                  <Grid item xs={12}>
                    <RoleSelect />
                  </Grid>
                  <Grid item xs={12}>
                    <CreditInput />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <StatusSwitch />
                  </Grid> */}
                </Grid>
              </FormProvider>
            </CardContent>
            <CardActions>
              <Button type="submit" color="primary">
                Create Admin Account
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item md={6} xs={12}>
        {loading ? <Loader /> : renderDetail()}
      </Grid>
    </Grid>
  );
}

export default AddAdminAccount;
