import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import packageJson from "../../package.json";

const { version } = packageJson;

const Text = styled.span({
  color: "grey",
  fontSize: "0.9em",
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © VBOSS888 "}
      {new Date().getFullYear()}
      {". "}
      <Text>v{version}</Text>
    </Typography>
  );
}

export default Copyright;
