import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import BankAccountInput from "../addBankAccount/BankAccountInput";
import Button from "../components/Button";
import Dropzone from "../components/Dropzone";
import Loader from "../components/Loader";
import Select from "../components/Select";
import Switch from "../components/Switch";
import { PureTextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { formatAll } from "../helpers/DateTime";
import { REQUIRED_RULE } from "../helpers/Form";
import useBankAccount from "../hooks/api/useBankAccount.Admin";
import useForm from "../hooks/useForm";
import Toggle from "../users/Toggle";

const METHODS = [
  {
    text: "Bank Account",
    value: "BANK",
  },
  {
    text: "DuitNow",
    value: "DUITNOW",
  },
];
const Line = styled.div(({ approved }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: approved ? green[500] : red[900],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

const StatusSwitch = styled(Switch).attrs({
  name: "active",
  label: "Active",
})({});

function BankAccountDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { id = "" } = useParams();
  const { response, loading, refresh, update } = useBankAccount(id);
  const [toggleItem, setToggleItem] = useState();

  const { createdAt, updatedAt, active: approved } = response || {};

  const form = useForm();

  const { handleSubmit, watch, reset } = form;
  const method = watch("method");

  useEffect(() => {
    reset(response);
  }, [reset, response]);

  useEffect(() => {
    setPageTitle("Bank Account Detail");
  }, []);

  async function onSubmit(body) {
    try {
      const { method, image, ...data } = body;
      const form =
        method === METHODS[1].value ? { image, method } : { ...data, method };

      await update(form);
      setMessage("Account updated");
      reset();
    } catch (ex) {
      const { message = "Unable update bank account" } = ex;
      setMessage({ message });
    }
  }

  function renderActions() {
    const btnProps = {
      color: "primary",
      children: "Update Bank Account",
      type: "submit",
      size: "small",
    };

    return <Button {...btnProps} />;
  }

  function renderDuitNowInput() {
    return (
      <Grid item xs={12}>
        <Dropzone
          label={"Upload QR Code"}
          name="image"
          url={`/admin/bankAccount/duitNow`}
          rules={{
            ...REQUIRED_RULE,
          }}
        />
      </Grid>
    );
  }

  function renderBankAccount() {
    if (!response) {
      return;
    }

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Line approved={approved} />
            <CardContent>
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <TextField label="Account No." name="accountNum" />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Bank Name" name="bank" />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Account Name" name="accountName" />
                </Grid> */}

                <Grid item xs={12}>
                  <Select
                    label="Method"
                    name="method"
                    items={METHODS}
                    displayEmpty={false}
                  />
                </Grid>
                {method === METHODS[0]?.value ? (
                  <BankAccountInput />
                ) : (
                  renderDuitNowInput()
                )}

                <Grid item xs={12}>
                  <PureTextField
                    label="Created at"
                    readOnly
                    value={formatAll(createdAt)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PureTextField
                    label="Last Updated at"
                    readOnly
                    value={formatAll(updatedAt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StatusSwitch />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>{renderActions()}</CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  function renderContent() {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid item md={6} xs={12}>
          {loading ? <Loader paper /> : renderBankAccount()}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {renderContent()}
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}
export default BankAccountDetail;
