import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { DRAW_TYPE } from "../constants/DrawResult";
import { useAppContext } from "../context/AppContext";
import { formatAmount, formatBetAmount } from "../helpers/Common";
import { capitalize } from "../helpers/String";
import useWinningTxn from "../hooks/api/useWinningTxn";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
};

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function TxnDetailWin() {
  const { setPageTitle } = useAppContext();
  const { id = "" } = useParams();
  const [txn, loading] = useWinningTxn(id);

  const {
    betNumber,
    drawType,
    drawDate,
    betPosition,
    betAmount,
    strikePosition,
    strikeAmount,
    betTxnId,
    status,
    desc,
    amount,
  } = txn || {};

  useEffect(() => {
    setPageTitle("Win Number Detail");
  }, []);

  function renderBetActions() {
    return (
      <Button
        size="small"
        component={Link}
        color="primary"
        to={`/record/${betTxnId}`}
      >
        View Bet Record
      </Button>
    );
  }

  function renderWithdrawTxn() {
    if (!txn) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Card>
          <Line status={status} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Txn No." readOnly value={id} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Status" readOnly value={capitalize(status)} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  readOnly
                  value={formatBetAmount(amount)}
                />
              </Grid>
              {desc && (
                <Grid item xs={12}>
                  <TextField rows={4} label="Remark" readOnly value={desc} />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function renderWinningTxn() {
    if (!txn) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Card>
          <Line status={status} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Txn No." readOnly value={id} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Status" readOnly value={capitalize(status)} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Bet Number" readOnly value={betNumber} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Draw Date" readOnly value={drawDate} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Game"
                  readOnly
                  value={DRAW_TYPE[drawType]?.text}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Bet Position" readOnly value={betPosition} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bet Amount"
                  readOnly
                  value={formatAmount(betAmount)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Strike Position"
                  readOnly
                  value={strikePosition}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Strike Amount"
                  readOnly
                  value={formatAmount(strikeAmount)}
                />
              </Grid>
              {desc && (
                <Grid item xs={12}>
                  <TextField rows={4} label="Remark" readOnly value={desc} />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions>{renderBetActions()}</CardActions>
        </Card>
      </Grid>
    );
  }

  function renderContent() {
    return (
      <Grid container spacing={2}>
        {amount < 0 ? renderWithdrawTxn() : renderWinningTxn()}
      </Grid>
    );
  }

  return loading ? <Loader paper /> : renderContent();
}

export default TxnDetailWin;
