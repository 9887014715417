import { useForm as useLibForm } from "react-hook-form";

const useForm = (props) => {
  return useLibForm({
    mode: "onSubmit",
    ...props,
  });
};

export default useForm;
