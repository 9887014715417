import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// export const ALL_SELECTION = ["Today Match", "Future Match"];

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginBottom: theme.spacing(1),
  "& > *": {
    margin: theme.spacing(0.5),
  },
}));

function FilterChips({ selected: prev, onChange, items = [] }) {
  const [selected, setSelected] = useState(prev ?? []);

  useEffect(() => {
    onChange?.(selected);
  }, [onChange, selected]);

  function updateSelected(item) {
    const found = selected.includes(item);
    if (!found) {
      setSelected((s) => {
        const all = [...s, item];
        if (all.length === items.length) {
          return [];
        }

        return all;
      });
    } else {
      setSelected((s) => s.filter((i) => i !== item));
    }
  }

  function renderAllChip() {
    const isSelect = selected.length === 0 || selected.length === items.length;
    const chipProps = {
      icon: isSelect ? <DoneIcon size="small" /> : null,
      color: isSelect ? "secondary" : "",
      label: "All",
      onClick: () => setSelected([]),
    };

    return <Chip {...chipProps}>All</Chip>;
  }

  return (
    <Container>
      {renderAllChip()}
      {items.map(({ text, value }) => {
        const isSelect = selected.includes(value);
        const chipProps = {
          icon: isSelect ? <DoneIcon size="small" /> : null,
          color: isSelect ? "secondary" : "default",
          label: text,
          clickable: true,
          onClick: () => updateSelected(value),
        };
        return <Chip key={value} {...chipProps}></Chip>;
      })}
    </Container>
  );
}

export default FilterChips;
