import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useAdminAccount(id, detail) {
  const prefix = id ? `${API_URL}/admin/adminAccount/${id}` : "";
  const url = `${prefix}${detail ? "/all" : ""}`;
  const { response, loading, get, put } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  function toggle() {
    return put({
      url: `${prefix}/status`,
    });
  }

  function update(body) {
    return put({
      url: prefix,
      body,
    });
  }

  return [response, loading, refresh, toggle, update];
}

export default useAdminAccount;
