import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { Lightbox } from "react-modal-image";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { PAYMENT_METHOD, STATUS_COLOR } from "../constants/Txn";
import { useAdminContext } from "../context/AdminContext";
import { useAppContext } from "../context/AppContext";
import { formatAmount, formatBetAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";
import useWalletTxn from "../hooks/api/useWalletTxn.Admin";
import ConfirmationAccept from "./Accept";
import ConfirmationReject from "./Reject";

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

const ReceiptContent = styled.div(({ theme }) => ({
  height: 250,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderWidth: 1,
  border: "1px solid rgba(255, 255, 255, 0.23)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  position: "relative",
  overflow: "hidden",
  cursor: "zoom-in",
}));

const Image = styled.div(({ $src }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${$src})`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",

  "&:after": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(66,66,66, .25)",
  },
}));

function WalletTxnDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { refresh: refreshAccount } = useAdminContext();
  const { id = "", wallet, userId } = useParams();
  const [txn, loading, refresh, toggle] = useWalletTxn(wallet, id, userId);

  const [open, setOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [accept, setAccept] = useState(false);

  const {
    amount,
    method,
    createdAt,
    status,
    txnId,
    desc,
    receipt,
    bonusTxnId,
    bonusAmount,
    profitAmount,

    approvedAt,
    approvedBy,
    rejectedAt,
    rejectedBy,
  } = txn || {};

  const approved = status === "approved";
  const pending = status === "new";
  const paidTxn = amount < 0;

  useEffect(() => {
    setPageTitle(
      ["COM", "WIN"].includes(method) ? "Transfer Detail" : "Wallet Detail"
    );
  }, [method]);

  useEffect(() => {
    refresh();
  }, [id, userId]);

  function renderBetDetail() {
    const type = approved ? "Payment" : "Cancel";
    return (
      <>
        <Grid item xs={12}>
          <TextField label="Txn Type" readOnly value={type} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Bet Txn No." readOnly value={txnId} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Bet Place at"
            readOnly
            value={formatAll(createdAt)}
          />
        </Grid>
      </>
    );
  }

  function renderBetActions() {
    // TODO:
    return (
      <Button
        size="small"
        color="primary"
        component={Link}
        to={`/admin/txn/bet/${wallet}/${userId}/${txnId}`}
      >
        View Bet Record
      </Button>
    );
  }

  function renderTxnDetail() {
    const type = PAYMENT_METHOD[method];

    return (
      <>
        <Grid item xs={12}>
          <TextField label="Txn Type" readOnly value={type} />
        </Grid>
        {desc && (
          <Grid item xs={12}>
            <TextField rows={4} label="Remark" readOnly value={desc} />
          </Grid>
        )}
        {renderReceipt()}
        <Grid item xs={12}>
          <TextField label="Created at" readOnly value={formatAll(createdAt)} />
        </Grid>
        {approvedBy && (
          <Grid item xs={12}>
            <TextField label="Approved by" readOnly value={approvedBy} />
          </Grid>
        )}
        {approvedAt && (
          <Grid item xs={12}>
            <TextField
              label="Approved at"
              readOnly
              value={formatAll(approvedAt)}
            />
          </Grid>
        )}
        {rejectedBy && (
          <Grid item xs={12}>
            <TextField label="Rejected by" readOnly value={rejectedBy} />
          </Grid>
        )}
        {rejectedAt && (
          <Grid item xs={12}>
            <TextField
              label="Rejected at"
              readOnly
              value={formatAll(rejectedAt)}
            />
          </Grid>
        )}
      </>
    );
  }

  function renderApproveOrReject() {
    const acceptProps = {
      id,
      open: accept,
      onConfirm: async () => {
        try {
          await toggle("approved");
          setMessage("Transaction Approved");
          refreshAccount(1500);
          setAccept(false);
          refresh();
        } catch (ex) {
          const { message = "Unable to approve transaction" } = ex;
          setMessage({ message });
          setAccept(false);
        }
      },
      onClose: () => {
        setAccept(false);
      },
    };

    const rejectProps = {
      id,
      open: reject,
      onConfirm: async (desc) => {
        await toggle("rejected", desc);
        setReject(false);
        refresh();
      },
      onClose: () => {
        setReject(false);
      },
    };

    const btnAcceptProps = {
      size: "small",
      color: "primary",
      children: "Approve",
      onClick: () => {
        setAccept(true);
      },
    };

    const btnRejectProps = {
      size: "small",
      color: "secondary",
      children: "Reject",
      onClick: () => {
        setReject(true);
      },
    };

    return (
      <>
        <Button {...btnAcceptProps} />
        <Button {...btnRejectProps} />
        <ConfirmationReject {...rejectProps} />
        <ConfirmationAccept {...acceptProps} />
      </>
    );
  }

  function renderTxnActions() {
    return (
      <>
        {txnId && (
          <Button
            size="small"
            color="primary"
            component={Link}
            to={`/admin/txn/wallet/${wallet}/${userId}/${txnId}`}
          >
            View Top up
          </Button>
        )}

        {bonusTxnId && (
          <Button
            size="small"
            color="primary"
            component={Link}
            to={`/admin/txn/wallet/${wallet}/${userId}/${bonusTxnId}`}
          >
            View Bonus
          </Button>
        )}

        {pending && renderApproveOrReject()}
      </>
    );
  }

  function renderReceipt() {
    if (!receipt) {
      return;
    }

    const btnProps = {
      onClick: () => setOpen(true),
    };

    const lgboxProps = {
      medium: receipt,
      large: receipt,
      onClose: () => setOpen(false),
    };

    return (
      <Grid item xs={12}>
        <ReceiptContent {...btnProps}>
          <Image $src={receipt} />
        </ReceiptContent>
        {open && <Lightbox {...lgboxProps} />}
      </Grid>
    );
  }

  function renderWalletTxn() {
    if (!txn) {
      return;
    }

    const txnStatus = status === "new" ? "Pending" : capitalize(status);

    return (
      <Card>
        <Line status={status} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Txn No." readOnly value={id} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Username" readOnly value={userId} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Status" readOnly value={txnStatus} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Amount"
                readOnly
                value={formatBetAmount(amount)}
              />
            </Grid>
            {bonusAmount && (
              <Grid item xs={12}>
                <TextField
                  label="Bonus Amount"
                  readOnly
                  value={formatAmount(bonusAmount)}
                />
              </Grid>
            )}
            {profitAmount && (
              <Grid item xs={12}>
                <TextField
                  label="Profit Amount"
                  readOnly
                  value={formatAmount(profitAmount)}
                />
              </Grid>
            )}
            {paidTxn ? renderBetDetail() : renderTxnDetail()}
          </Grid>
        </CardContent>
        <CardActions>
          {paidTxn ? renderBetActions() : renderTxnActions()}
        </CardActions>
      </Card>
    );
  }

  function renderContent() {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid item md={6} xs={12}>
          {loading ? <Loader paper /> : renderWalletTxn()}
        </Grid>
      </Grid>
    );
  }

  return renderContent();
}

export default WalletTxnDetail;
