import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useDownlines() {
  const url = `${API_URL}/agent`;
  const { response, loading, get } = useAuthFetch({});

  function refresh() {
    return get({ url });
  }

  function search({ from, to }) {
    return get({ url: `${url}/search/from/${from}/to/${to}` });
  }

  return [response, loading, refresh, search];
}

export default useDownlines;
