import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useAdminCredits(id) {
  const url = `${API_URL}/admin/adminCredit/${id}`;
  const { response, loading, get } = useAuthFetch({ url: "" });

  function refresh() {
    return get({ url });
  }

  function search({ from, to }) {
    return get({ url: `${url}/search/from/${from}/to/${to}` });
  }

  return [response, loading, refresh, search];
}

export default useAdminCredits;
