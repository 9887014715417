import damacaiImage from "../assets/damacai.png";
import gdImage from "../assets/gd.png";
import magnumImage from "../assets/magnum.png";
import sabahImage from "../assets/sabah.png";
import sarawakImage from "../assets/sarawak.png";
import sgpoolImage from "../assets/sgpool.png";
import stcImage from "../assets/stc.png";
import totoImage from "../assets/toto.png";

export const BET_TYPE = [
  { text: "All", value: "All" },
  ...["4D", "5D", "6D"].map((value) => ({
    value,
    text: value,
  })),
];

// 1-M, 2-P, 3-T, 4-S, 5-B, 6-K, 7-W, 8-G

export const DRAW_TYPE_ENUM = {
  1: "magnum",
  2: "damacai",
  3: "toto",
  4: "sgpool",
  5: "sabah",
  6: "stc",
  7: "sarawak",
  8: "gd",
};

export const DRAW_TYPE = {
  magnum: {
    text: "MAGNUM",
    color: "#ffc20d",
    textColor: "#000",
    image: magnumImage,
  },
  damacai: {
    text: "DAMACAI",
    textColor: "#fff",
    color: "#817ecd",
    image: damacaiImage,
  },
  toto: {
    text: "TOTO",
    textColor: "#000",
    color: "#ff0000",
    image: totoImage,
  },
  sgpool: {
    text: "SINGAPORE",
    textColor: "#000",
    color: "#0098e6",
    image: sgpoolImage,
  },
  sabah: {
    text: "SABAH LOTTO88",
    textColor: "#000",
    color: "#99ddff",
    image: sabahImage,
  },
  stc: {
    text: "SANDAKAN STC",
    textColor: "#000",
    color: "#99cc00",
    image: stcImage,
  },
  sarawak: {
    text: "CASHSWEEP",
    textColor: "#fff",
    color: "#004d00",
    image: sarawakImage,
  },
  gd: {
    text: "GD LOTTO",
    textColor: "#e7d766",
    color: "#c1272d",
    image: gdImage,
  },
};

export const DRAW_TYPES = [
  { text: "All", value: "All" },
  ...Object.entries(DRAW_TYPE).reduce(
    (prev, [key, value]) => [...prev, { text: value.text, value: key }],
    []
  ),
];

export const getDrawType = (drawType) =>
  DRAW_TYPE?.[DRAW_TYPE_ENUM[drawType]] || {};
