import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import Button from "../components/Button";
import Dropzone from "../components/Dropzone";
import Loader from "../components/Loader";
import Select from "../components/Select";
import Switch from "../components/Switch";
import { PAYMENT_METHOD_BANKS } from "../constants/Withdraw";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useBankAccount from "../hooks/api/useBankAccount.Admin";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import BankAccountInput from "./BankAccountInput";

const METHODS = [
  {
    text: "Bank Account",
    value: "BANK",
  },
  {
    text: "DuitNow",
    value: "DUITNOW",
  },
];

const StatusSwitch = styled(Switch).attrs({
  name: "active",
  label: "Active",
})({});

function AddBankAccount() {
  const { setPageTitle, setMessage } = useAppContext();
  const { loading, create } = useBankAccount();

  const form = useForm({
    defaultValues: {
      method: METHODS[0]?.value,
      active: true,
    },
  });
  const { handleSubmit, watch, reset, setValue } = form;
  const method = watch("method");

  useMount(() => {
    setPageTitle(`Add Bank Account`);
  });

  useEffect(() => {
    setValue("bank", PAYMENT_METHOD_BANKS[0]?.value);
  }, [setValue, method]);

  async function onSubmit(body) {
    try {
      const { method, image, ...data } = body;
      const form =
        method === METHODS[1].value ? { image, method } : { ...data, method };

      await create(form);
      setMessage("Account added");
      reset();
    } catch (ex) {
      const { message = "Unable create bank account" } = ex;
      setMessage({ message });
    }
  }

  function renderDuitNowInput() {
    return (
      <Grid item xs={12}>
        <Dropzone
          label={"Upload QR Code"}
          name="image"
          url={`/admin/bankAccount/duitNow`}
          rules={{
            ...REQUIRED_RULE,
          }}
        />
      </Grid>
    );
  }

  function renderDetail() {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      label="Method"
                      name="method"
                      items={METHODS}
                      displayEmpty={false}
                    />
                  </Grid>
                  {method === METHODS[0]?.value ? (
                    <BankAccountInput />
                  ) : (
                    renderDuitNowInput()
                  )}

                  <Grid item xs={12}>
                    <StatusSwitch />
                  </Grid>
                </Grid>
              </FormProvider>
            </CardContent>
            <CardActions>
              <Button type="submit" color="primary">
                Create Bank Account
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item md={6} xs={12}>
        {loading ? <Loader /> : renderDetail()}
      </Grid>
    </Grid>
  );
}

export default AddBankAccount;
