import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import LibLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logoImage from "../assets/logo.png";
import Copyright from "../components/Copyright";
import LanguageSwitch from "../components/LanguageSwitch";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { getPhoneOrUsername } from "../helpers/Phone";
import useAnyApi from "../hooks/api/useAnyApi";
import useForm from "../hooks/useForm";
import ResetPassword from "./Forget.ResetPassword";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    width: 150,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forget: { justifyContent: "flex-end", display: "flex", textAlign: "right" },
}));

export default function ForgetPassword() {
  const classes = useStyles();

  const [t] = useTranslation();
  const form = useForm();
  const { handleSubmit } = form;

  const [toResetUsername, setToResetUsername] = useState();

  const { forgotPassword } = useAuthContext();
  const { setMessage } = useAppContext();

  const [loading, invokeForget] = useAnyApi();

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  async function onSubmit({ username }) {
    try {
      const usernameOrPhone = getPhoneOrUsername(username);
      await invokeForget(() => forgotPassword(usernameOrPhone));
      setMessage("Code Sent. Please check your phone SMS");
      setToResetUsername(usernameOrPhone);
    } catch (ex) {
      setMessage(ex);
    }
  }

  function renderContent() {
    if (toResetUsername) {
      return <ResetPassword username={toResetUsername} />;
    }

    return (
      <FormProvider {...form}>
        <form className={classes.form} {...formProps}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label={t("Username / Phone No")} name="username" />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("Forget Password")}
          </Button>
          <Grid container>
            <Grid item xs={6}>
              <LibLink
                color="secondary"
                component={Link}
                to="register"
                variant="body2"
              >
                {t("Don't have an account? Sign Up")}
              </LibLink>
            </Grid>
            <Grid item xs={6} className={classes.forget}>
              <LibLink
                color="secondary"
                component={Link}
                to="login"
                variant="body2"
              >
                {t("Already have an account? Sign in")}
              </LibLink>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={logoImage} alt="logo" />
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          {t("Forget Password")}
        </Typography>
        {loading ? <Loader /> : renderContent()}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Box mt={2} align="center">
        <LanguageSwitch />
      </Box>
    </Container>
  );
}
