import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useCreateAdminAccount() {
  const url = `${API_URL}/admin/adminAccount`;
  const { response, loading, get, put, post } = useAuthFetch({ url });

  function create(body) {
    return post({ url, body });
  }

  return [response, loading, create];
}

export default useCreateAdminAccount;
