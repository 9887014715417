import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useDrawResult() {
  const url = `${API_URL}/drawResult`;
  const { response, loading, get } = useAuthFetch({});

  function search({ date, type }) {
    const params = [date, type].filter((s) => !!s).join("/");
    return get({ url: `${url}/${params}` });
  }

  return [response, loading, search];
}

export default useDrawResult;
