import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import DraftsIcon from "@material-ui/icons/Drafts";
import React from "react";
import { formatStandard } from "../helpers/DateTime";

function Inbox({ messages }) {
  if (messages.length === 0) {
    return null;
  }

  return (
    <Paper>
      <List>
        {messages.map(({ id, value, createdAt }) => (
          <ListItem alignItems="flex-start" key={id}>
            <ListItemAvatar>
              <Avatar>
                <DraftsIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={value}
              secondary={formatStandard(createdAt)}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}
export default Inbox;
