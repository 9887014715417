import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FilterChips from "../components/FilterChips";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import { formatAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import { numberWithCommas } from "../helpers/String";
import useBetTxns from "../hooks/api/useBetTxns.Admin";
import useMount from "../hooks/useMount";
import useSearchParams from "../hooks/useSearchParams";
import Filter from "./Filter";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
  void: grey[500],
};

const WALLETS = [
  {
    text: "Wallet 1",
    value: "wallet.mkt",
  },
  {
    text: "Wallet 2",
    value: "wallet.gd",
  },
  // {
  //   text: "Pending",
  //   value: "status.new",
  // },
  {
    text: "Approved",
    value: "status.approved",
  },
  {
    text: "Rejected",
    value: "status.rejected",
  },
  {
    text: "Void",
    value: "status.void",
  },
  // {
  //   text: "Today",
  //   value: "date.today",
  // },
];

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS_COLOR[status],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#ffc107" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({
  paddingLeft: 8,
  paddingRight: 8,
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

const TODAY_START = moment().startOf("day");
const TODAY_END = moment().endOf("day");

const DEFAULT_PARAMS = {
  createdAtStart: TODAY_START.toJSON(),
  createdAtEnd: TODAY_END.toJSON(),
};

function BetTxns() {
  const { setPageTitle } = useAppContext();

  const [params, setParams] = useSearchParams();

  const [selectedWallets, setSelectedWallets] = useState([
    "wallet.mkt",
    "wallet.gd",
    "status.approved",
    // "date.today",
  ]);

  const [topup1, loading1, refresh1, search1] = useBetTxns("mkt");
  const [topup2, loading2, refresh2, search2] = useBetTxns("gd");

  const loading = loading1 || loading2;

  const txns = useMemo(() => {
    const t1 = topup1 ?? [];
    const t2 = topup2 ?? [];

    let all = [...t1, ...t2];

    if (selectedWallets.length === 0) {
      return all;
    }

    const wallets = selectedWallets
      .filter((s) => s.startsWith("wallet"))
      .map((s) => s.split(".")[1]);

    if (wallets.length === 0 || wallets.length === 2) {
      return all;
    }

    if (selectedWallets.includes("wallet.mkt")) {
      return t1;
    }

    if (selectedWallets.includes("wallet.gd")) {
      return t2;
    }
  }, [selectedWallets, topup1, topup2]);

  const response = useMemo(() => {
    let all = [...txns];

    const statuses = selectedWallets
      .filter((s) => s.startsWith("status"))
      .map((s) => s.split(".")[1]);

    if (statuses.length !== 0 && statuses.length !== 3) {
      all = all.filter(({ status }) => statuses.includes(status));
    }

    // const dates = selectedWallets
    //   .filter((s) => s.startsWith("date"))
    //   .map((s) => s.split(".")[1]);

    // if (dates.length !== 0) {
    //   all = all.filter(({ createdAt }) =>
    //     moment(createdAt).isBetween(tStart, tEnd)
    //   );
    // }

    return all;
  }, [selectedWallets, txns]);

  const summary = useMemo(() => {
    const approved = txns.filter((s) => s.status === "approved");

    const totalAmount = numberWithCommas(
      formatAmount(
        approved.reduce((prev, { grandTotal }) => prev + grandTotal, 0),
        false
      )
    );

    return {
      totalAmount,
      totalCount: approved.length,
    };
  }, [txns]);

  const refresh = useCallback(() => {
    setParams();
    refresh1();
    refresh2();
  }, [refresh1, refresh2, setParams]);

  const search = useCallback(
    (data) => {
      setParams(data);
      search1(data);
      search2(data);
    },
    [search1, search2, setParams]
  );

  const [showFilter, setShowFilter] = useState();

  useMount(() => {
    setPageTitle(`Bet Transactions`);
  });

  useMount(() => {
    search(params ?? DEFAULT_PARAMS);
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Txn No.",
      custom: renderStatus,
      customHeader: renderStatusHeader,
    },
    {
      id: "userId",
      numeric: false,
      label: "Username",
    },
    {
      id: "drawDates",
      numeric: false,
      label: "Draw Dates",
      format: ({ drawDates }) =>
        drawDates.map((date) => moment(date).format("DD/MM")).join(", "),
    },
    {
      id: "grandTotal",
      numeric: false,
      label: "Grand Total",
      format: ({ grandTotal }) => formatAmount(grandTotal),
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status head>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { id, userId, status, wallet } = item;

    return (
      <StatusContent>
        <Dot status={status} />

        <StatusText to={`/admin/txn/bet/${wallet}/${userId}/${id}`}>
          {id}
        </StatusText>
      </StatusContent>
    );
  }

  function renderSummary() {
    if (loading) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SummaryCard>
              <Value $primary>{summary?.totalAmount}</Value>
              <Title>Total Amount ($)</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <SummaryCard>
              <Value $primary>{summary?.totalCount}</Value>
              <Title>Total Approved</Title>
            </SummaryCard>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: (data) => {
      setShowFilter();
      if (!data) {
        refresh();
      } else {
        search(data);
      }
    },
  };

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Transactions",
    defaultSort: "createdAt",
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      <FilterChips
        items={WALLETS}
        onChange={setSelectedWallets}
        selected={selectedWallets}
      />

      <Grid container spacing={2}>
        {renderSummary()}
        <Grid item xs={12}>
          {loading ? <Loader paper /> : <Table {...tableProps} />}
        </Grid>
      </Grid>
      <Filter {...filterProps} />
    </>
  );
}

export default BetTxns;
