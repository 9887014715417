import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FilterChips from "../components/FilterChips";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import { formatAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import useUserAccount from "../hooks/api/useUserAccount.Admin";
import useUsers from "../hooks/api/useUsers";
import useUsersSummary from "../hooks/api/useUsersSummary.Admin";
import useMount from "../hooks/useMount";
import Filter from "./Filter";
import Toggle from "./Toggle";

const STATUS_COLOR = {
  active: green[500],
  inactive: red[900],
};

const WALLETS = [
  {
    text: "Active",
    value: "status.active",
  },
  {
    text: "Inactive",
    value: "status.inactive",
  },
  // {
  //   text: "Today",
  //   value: "date.today",
  // },
];

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS_COLOR[status],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#ffc107" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({
  paddingLeft: 8,
  paddingRight: 8,
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

const UserWallet = ({ wallet, id }) => {
  const [account, loading] = useUserAccount(id);
  const { walletGD, walletMKT } = account ?? {};

  if (loading) {
    return <>...</>;
  }

  return <>{formatAmount(wallet === "mkt" ? walletMKT : walletGD)}</>;
};

function User() {
  const { setPageTitle } = useAppContext();

  const [selectedWallets, setSelectedWallets] = useState([
    "status.active",
    // "date.today",
  ]);

  const [showFilter, setShowFilter] = useState();

  const [toggleItem, setToggleItem] = useState();
  const [users = [], loading, refresh, search] = useUsers();
  const [uSummary, sLoading] = useUsersSummary();

  const summary = useMemo(() => {
    const approved = users.filter((s) => s.status === "active");
    const newUser = users.filter(({ createdAt }) =>
      moment(createdAt).isBetween(
        moment().startOf("day").valueOf(),
        moment().endOf("day").valueOf()
      )
    );

    return {
      totalCount: approved.length,
      totalNewUsers: newUser.length,
    };
  }, [users]);

  // const [userAccounts, accLoading] = useUserAccountIds(
  //   users.map(({ id }) => id)
  // );

  // const accounts = useMemo(() => {
  //   return userAccounts.reduce(
  //     (prev, acc) => ({
  //       ...prev,
  //       [acc.id]: acc,
  //     }),
  //     {}
  //   );
  // }, [...userAccounts]);

  useMount(() => {
    setPageTitle("User Management");
  });

  useMount(() => {
    refresh();
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Username",
      custom: renderStatus,
      customHeader: renderStatusHeader,
    },
    {
      id: "mobile",
      numeric: false,
      label: "Phone Number",
    },
    {
      id: "walletMKT",
      numeric: false,
      label: "Wallet 1",
      component: (item) => <UserWallet wallet="mkt" {...item} />,
    },
    {
      id: "walletGD",
      numeric: false,
      label: "Wallet 2",
      component: (item) => <UserWallet wallet="gd" {...item} />,
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Registered At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status head>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { id, status } = item;
    // const active = status === "active";

    return (
      <StatusContent>
        <Dot status={status} />

        <StatusText to={`/admin/user/${id}`}>{id} </StatusText>

        {/* <IconButton
          title={active ? "Disable User" : "Enable User"}
          size="small"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setToggleItem(item);
          }}
        >
          {active ? <OnIcon active={active} /> : <OffIcon active={active} />}
        </IconButton> */}
      </StatusContent>
    );
  }
  function renderSummary() {
    if (sLoading || loading) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <SummaryCard>
              <Value $primary>{summary?.totalCount}</Value>
              <Title>Total Active</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryCard>
              <Value $primary>{summary?.totalNewUsers}</Value>
              <Title>Total New User</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryCard>
              <Value $primary>
                {formatAmount(uSummary?.commission, false)}
              </Value>
              <Title>Total Commission</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryCard>
              <Value $primary>{formatAmount(uSummary?.walletMKT, false)}</Value>
              <Title>Total Wallet 1</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryCard>
              <Value $primary>{formatAmount(uSummary?.walletGD, false)}</Value>
              <Title>Total Wallet 2</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryCard>
              <Value $primary>
                {formatAmount(uSummary?.winningMKT, false)}
              </Value>
              <Title>Total Winning 1</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryCard>
              <Value $primary>{formatAmount(uSummary?.winningGD, false)}</Value>
              <Title>Total Winning 2</Title>
            </SummaryCard>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: (data) => {
      setShowFilter();
      if (!data) {
        refresh();
      } else {
        search(data);
      }
    },
  };

  const tableProps = {
    headCells,
    items: users,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Users",
    defaultSort: "createdAt",
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      <FilterChips
        items={WALLETS}
        onChange={setSelectedWallets}
        selected={selectedWallets}
      />
      <Grid container spacing={2}>
        {renderSummary()}
        <Grid item xs={12}>
          {loading ? <Loader paper /> : <Table {...tableProps} />}
        </Grid>
      </Grid>
      <Filter {...filterProps} />
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default User;
