import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useWalletContext } from "../context/WalletContext";
import useBetTxn from "../hooks/api/useBetTxn";
import ConfirmVoid from "./Confirm.Void";

const Line = styled.div(({ approved }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: approved ? green[500] : red[900],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function BetNowConfirm() {
  const { setPageTitle, setMessage } = useAppContext();
  const { id = "" } = useParams();
  const [txn, txnLoading, refresh] = useBetTxn(id);

  const [whatsappPhone, setWhatsappPhones] = useState();

  const [showConfirmation, setShowConfirmation] = useState();

  const { userAccount } = useWalletContext();

  const [, , refreshAccount, setLoading] = userAccount;

  const syncingFromServer = useMemo(() => txn?.status === "new", [txn?.status]);
  const isVoidExpired = useMemo(() => {
    var betTime = moment(txn?.createdAt);
    var expireTime = moment(betTime).add(30, "minutes");
    return moment().isSameOrAfter(expireTime);
  }, [txn?.createdAt]);

  const loading = txnLoading || syncingFromServer;

  const { api28, status } = txn || {};
  const { receipt, message, orderId } = api28 || {};

  const approved = status === "approved";
  const voided = status === "void";

  console.log({ status });

  useEffect(() => {
    setPageTitle("Bet Now");
  }, []);

  useEffect(() => {
    async function init() {
      if (!syncingFromServer || txnLoading) {
        return;
      }

      refresh();
      refreshAccount(1500);
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnLoading, syncingFromServer]);

  useEffect(() => {
    setLoading(syncingFromServer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncingFromServer]);

  function getReceipt() {
    if (!txn) {
      return;
    }

    return `${voided ? "VOID\r\n=============\r\n" : ""}${receipt.replace(
      "\r\n\r\n",
      "\r\n"
    )}TXNID=${id}`;
  }

  async function onVoid() {
    if (isVoidExpired) {
      setMessage({ message: "Void Expired" });
      return;
    }

    setShowConfirmation(true);
  }

  function whatsappMobile() {
    if (!whatsappPhone) {
      const url = `https://wa.me/?text=${encodeURIComponent(getReceipt())}`;
      window.open(url, "_blank");
      return;
    }

    const mobile =
      whatsappPhone.startsWith("+6") || whatsappPhone.startsWith("6")
        ? whatsappPhone
        : `6${whatsappPhone}`;
    const url = `https://wa.me/${mobile}?text=${encodeURIComponent(
      getReceipt()
    )}`;
    window.open(url, "_blank");
  }

  function renderBetResult() {
    const voidProps = {
      onClick: () => onVoid(),
    };

    const waProps = {
      label: "Whatsapp Number",
      name: "mboile",
      type: "whatsapp",
      value: whatsappPhone,
      noForceNumber: true,
      onChange: ({ target }) => setWhatsappPhones(target?.value),
      onIconClick: () => whatsappMobile(),
    };
    return (
      <Grid item xs={12} key={orderId}>
        <Card>
          <Line approved={approved} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Txn No."
                  readOnly
                  errorText={voided}
                  value={id}
                />
              </Grid>
              {receipt && (
                <Grid item xs={12}>
                  <TextField
                    label="Receipt"
                    readOnly
                    rows={8}
                    errorText={voided}
                    value={getReceipt()}
                  />
                </Grid>
              )}
              {message && (
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    readOnly
                    rows={4}
                    value={message}
                  />
                </Grid>
              )}
              {receipt && (
                <Grid item xs={12}>
                  <TextField {...waProps} />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              component={Link}
              color="primary"
              to={`/bet/rebuy/${id}`}
            >
              Rebuy 重新购买
            </Button>
            {!isVoidExpired && approved && orderId && (
              <Button size="small" color="secondary" {...voidProps}>
                Void 取消
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    );
  }

  function renderModal() {
    const props = {
      id,
      orderId,
      open: showConfirmation,
      onClose: () => setShowConfirmation(),
      onConfirm: () => {
        refresh();
        refreshAccount(2500);
      },
    };

    return <ConfirmVoid {...props} />;
  }

  function renderContent() {
    return (
      <Grid container spacing={2}>
        {renderBetResult()}
      </Grid>
    );
  }

  return loading ? <Loader paper /> : [renderContent(), renderModal()];
}
export default BetNowConfirm;
