import Chip from "@material-ui/core/Chip";
import React from "react";
import styled from "styled-components";

// export const ALL_SELECTION = ["Today Match", "Future Match"];

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  "& > *": {
    margin: theme.spacing(0.5),
  },
}));

function AmountChips({ value, onChange, items = [] }) {
  return (
    <Container>
      {items.map(({ text, value: val }) => {
        const isSelect = `${value}` === `${val}`;
        const chipProps = {
          color: isSelect ? "primary" : "default",
          label: text,
          clickable: true,
          onClick: () => onChange?.(val),
        };
        return <Chip key={val} {...chipProps}></Chip>;
      })}
    </Container>
  );
}

export default AmountChips;
