import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import LibLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../assets/logo.png";
import Copyright from "../components/Copyright";
import LanguageSwitch from "../components/LanguageSwitch";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useAnyApi from "../hooks/api/useAnyApi";
import useForm from "../hooks/useForm";
import useLocalStorage from "../hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    width: 150,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forget: { justifyContent: "flex-end", display: "flex", textAlign: "right" },
}));

export default function LogIn() {
  const classes = useStyles();

  const [t] = useTranslation();
  const form = useForm();
  const { handleSubmit, reset } = form;

  const { signIn } = useAuthContext();
  const { setMessage } = useAppContext();

  const { state } = useLocation();
  const { username } = state ?? {};

  const [loading, invokeLogin] = useAnyApi();
  const [savedLogin, setSavedLogin] = useLocalStorage("savedLogin", {});

  useEffect(() => {
    if (username) {
      reset({ username });
    } else {
      reset(savedLogin);
    }
  }, [savedLogin, username, reset]);

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  async function loginWithUsername(data) {
    try {
      await invokeLogin(() => signIn(data));
      setSavedLogin(data);
      return true;
    } catch (ex) {
      return false;
    }
  }

  async function loginWithMobile({ username, password }) {
    await invokeLogin(() => signIn({ username: `+6${username}`, password }));
  }

  async function onSubmit(data) {
    const valid = await loginWithUsername(data);
    if (valid) {
      return;
    }

    try {
      await loginWithMobile(data);
      setSavedLogin(data);
    } catch (ex) {
      setMessage(ex);
    }
  }

  function renderContent() {
    return (
      <form className={classes.form} {...formProps}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t("Username / Phone No")}
              name="username"
              rules={{
                ...REQUIRED_RULE,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label={t("Password")}
              type="password"
              autoComplete="current-password"
              rules={{
                ...REQUIRED_RULE,
              }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t("Log in")}
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LibLink
              color="secondary"
              component={Link}
              to="register"
              variant="body2"
            >
              {t("Don't have an account? Sign Up")}
            </LibLink>
          </Grid>
          <Grid item xs={6} className={classes.forget}>
            <LibLink
              color="secondary"
              component={Link}
              to="forget"
              variant="body2"
            >
              {t("Forget Password?")}
            </LibLink>
          </Grid>
        </Grid>
      </form>
    );
  }

  return (
    <FormProvider {...form}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img className={classes.logo} src={logoImage} alt="logo" />
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            {t("Log in")}
          </Typography>
          {loading ? <Loader /> : renderContent()}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        <Box mt={2} align="center">
          <LanguageSwitch />
        </Box>
      </Container>
    </FormProvider>
  );
}
