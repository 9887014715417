import { API_URL } from "../../constants/EnvConfig";
import { useWalletContext } from "../../context/WalletContext";
import useAuthFetch from "../useAuthFetch";

function useUpdateBetTxn(id, orderId) {
  const { selected } = useWalletContext();
  const prefix = `${API_URL}/betTxn/${selected}`;
  const url = id && orderId ? `${prefix}/${id}/${orderId}` : "";
  const { loading, del } = useAuthFetch({ url });

  function voidTxn({ id, orderId }) {
    return del({ url: `${prefix}/${id}/${orderId}` });
  }

  return [loading, voidTxn];
}

export default useUpdateBetTxn;
