import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../components/Button";
import Datepicker from "../components/Datepicker";
import Loader from "../components/Loader";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useNumberSummary from "../hooks/api/useNumberSummary";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import Summary from "./Home.Summary";
import Table from "./Home.Table";

const TODAY = moment().toJSON();
const FORMAT = "YYYY-MM-DD";
const TODAY_F = moment(TODAY).format(FORMAT);

const Gap = styled.div({
  height: 20,
});

function HomeAdmin() {
  const { setPageTitle } = useAppContext();
  const [response = {}, loading, refresh] = useNumberSummary(TODAY_F, 10);

  useMount(() => {
    setPageTitle("Dashboard");
  });

  function onSubmit({ drawDate }) {
    refresh(moment(drawDate).format(FORMAT));
  }

  return (
    <Grid container spacing={1}>
      <SearchBox onSubmit={onSubmit} />
      {loading ? (
        <Loader />
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={2} justify="center">
            {Object.entries(response).map(([title, txns]) =>
              title === "summary" ? (
                <DrawGameSummary summary={txns} />
              ) : (
                <DrawGame title={title} txns={txns} />
              )
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
export default HomeAdmin;

function DrawGame({ txns, title }) {
  return (
    <Grid item xs={12} lg={6}>
      <Table title={title} txns={txns} />
    </Grid>
  );
}

function DrawGameSummary({ summary }) {
  return (
    <Grid item xs={12} lg={6}>
      <Summary summary={summary} />
    </Grid>
  );
}

function SearchBox({ onSubmit }) {
  const [t] = useTranslation();

  const form = useForm({
    defaultValues: {
      drawDate: TODAY,
    },
  });
  const { handleSubmit } = form;

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  return (
    <Grid item xs={12} lg={4}>
      <FormProvider {...form}>
        <Card component="form" {...formProps}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Datepicker
                  label={t("Draw Date")}
                  name="drawDate"
                  rules={{
                    ...REQUIRED_RULE,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              {t("Search")}
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
      <Gap />
    </Grid>
  );
}
