export const isEmpty = (value) => {
  return value === null || value === undefined || value === "";
};

export const replaceOrDefault = (text, replace, value) => {
  return value !== undefined ? text.replace(replace, value) : text;
};

export const capitalize = (s) => {
  if (typeof s !== "string") {
    return "";
  }

  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
};

export const regularExpressionCharacters = (num) => {
  if (isEmpty(num)) {
    return "";
  }

  return num.toString().replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "");
};

export const stripCommas = (s) => s && s.toString().replace(/,/g, "");

export const numberWithCommas = (x) => {
  if (isNaN(+x)) {
    return 0;
  }

  return (+x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const maskEmail = (email = "") => {
  return email.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, "*") + c
  );
};

export const tryParse = (json, defaultValue) => {
  try {
    return JSON.parse(json);
  } catch (ex) {
    return defaultValue;
  }
};
