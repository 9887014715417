import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useWinningTxn(wal, id, userId) {
  const wallet = `${wal}`.toLowerCase();
  const url = id ? `${API_URL}/admin/winningTxn/${wallet}/${userId}/${id}` : "";
  const { response, loading, get, put } = useAuthFetch({});

  function refresh() {
    return get({ url });
  }

  function toggle(status, desc) {
    return put({ url: `${url}/status/${status}`, body: { desc } });
  }

  return [response, loading, refresh, toggle];
}

export default useWinningTxn;
