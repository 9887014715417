import React from "react";
import Confirmation from "../components/Confirmation";
import { useAppContext } from "../context/AppContext";
import useUpdateBetTxn from "../hooks/api/useUpdateBetTxn";

function Toggle({ id, orderId, open, onClose, onConfirm }) {
  const { setMessage } = useAppContext();

  const [loading, voidTxn] = useUpdateBetTxn();

  const conProps = {
    title: "Void Bet",
    desc: `Are you sure want to void bet ${id}`,
    open,
    onClose,
    loading,
    onConfirm: async () => {
      try {
        await voidTxn({ id, orderId });
      } catch (ex) {
        setMessage(ex);
      }
      onConfirm();
      onClose();
    },
  };
  return <Confirmation {...conProps} />;
}

export default Toggle;
