import { Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { formatAll } from "../helpers/DateTime";

function TimeNow() {
  const ref = useRef();

  useEffect(() => {
    ref.current.innerHTML = formatAll();

    const int = setInterval(() => {
      ref.current.innerHTML = formatAll();
    }, 1000);

    return () => {
      clearInterval(int);
    };
  }, []);

  return (
    <Typography
      ref={ref}
      gutterBottom
      component="h6"
      align="center"
    ></Typography>
  );
}

export default TimeNow;
