import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { STATUS_COLOR } from "../constants/Txn";
import { useAdminContext } from "../context/AdminContext";
import { useAppContext } from "../context/AppContext";
import { formatAmount, formatBetAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";
import useWithdrawTxn from "../hooks/api/useWithdrawTxn.Admin";
import ConfirmationAccept from "./Accept";
import ConfirmationReject from "./Reject";

const FROM_ACCOUNT = {
  COM: "Commission",
  WIN: "Winning",
};

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});
function WithdrawTxnDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { refresh: refreshAccount } = useAdminContext();
  const { id = "", userId, wallet } = useParams();
  const [txn, loading, refresh, toggle] = useWithdrawTxn(wallet, id, userId);
  const [reject, setReject] = useState(false);
  const [accept, setAccept] = useState(false);

  const {
    amount,
    createdAt,
    status,
    desc,
    fromAccount,
    toAccount,
    transferUserId,
    profitAmount,

    bankName,
    accountName,
    accountNumber,

    txnId,
    approvedAt,
    approvedBy,
    rejectedAt,
    rejectedBy,
  } = txn || {};

  const pending = status === "new";

  useEffect(() => {
    setPageTitle(transferUserId ? "Transfer Detail" : "Withdraw Detail");
  }, [transferUserId]);

  useEffect(() => {
    refresh();
  }, [wallet, id, userId]);

  function renderTxnDetail() {
    const labelTo = toAccount === "USER" ? "Transfer To" : "Withdraw To";
    const labelFrom = toAccount === "USER" ? "Transfer From" : "Withdraw From";
    const withdrawTo = toAccount === "USER" ? transferUserId : toAccount;

    return (
      <>
        {profitAmount && (
          <Grid item xs={12}>
            <TextField
              label="Profit Amount"
              readOnly
              value={formatAmount(profitAmount)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label={labelFrom}
            readOnly
            value={FROM_ACCOUNT[fromAccount]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label={labelTo} readOnly value={withdrawTo} />
        </Grid>
        {desc && (
          <Grid item xs={12}>
            <TextField rows={4} label="Remark" readOnly value={desc} />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField label="Created at" readOnly value={formatAll(createdAt)} />
        </Grid>

        {approvedBy && (
          <Grid item xs={12}>
            <TextField label="Approved by" readOnly value={approvedBy} />
          </Grid>
        )}
        {approvedAt && (
          <Grid item xs={12}>
            <TextField
              label="Approved at"
              readOnly
              value={formatAll(approvedAt)}
            />
          </Grid>
        )}
        {rejectedBy && (
          <Grid item xs={12}>
            <TextField label="Rejected by" readOnly value={rejectedBy} />
          </Grid>
        )}
        {rejectedAt && (
          <Grid item xs={12}>
            <TextField
              label="Rejected at"
              readOnly
              value={formatAll(rejectedAt)}
            />
          </Grid>
        )}
      </>
    );
  }

  function renderApproveOrReject() {
    const acceptProps = {
      id,
      open: accept,
      onConfirm: async () => {
        try {
          await toggle("approved");
          setMessage("Transaction Approved");
          refreshAccount(1500);
          setAccept(false);
          refresh();
        } catch (ex) {
          const { message = "Unable to approve transaction" } = ex;
          setMessage({ message });
          setAccept(false);
        }
      },
      onClose: () => {
        setAccept(false);
      },
    };

    const rejectProps = {
      id,
      open: reject,
      onConfirm: async (desc) => {
        await toggle("rejected", desc);
        setReject(false);
        refresh();
      },
      onClose: () => {
        setReject(false);
      },
    };

    const btnAcceptProps = {
      size: "small",
      color: "primary",
      children: "Approve",
      onClick: () => {
        setAccept(true);
      },
    };

    const btnRejectProps = {
      size: "small",
      color: "secondary",
      children: "Reject",
      onClick: () => {
        setReject(true);
      },
    };

    return (
      <>
        <Button {...btnAcceptProps} />
        <Button {...btnRejectProps} />
        <ConfirmationReject {...rejectProps} />
        <ConfirmationAccept {...acceptProps} />
      </>
    );
  }

  function renderViewTransaction() {
    if (!txnId) {
      return;
    }

    const url = `${txnId}`.startsWith("A")
      ? `/admin/txn/comm/${userId}/${txnId}`
      : `/admin/txn/win/${wallet}/${userId}/${txnId}`;

    const viewAction = `${txnId}`.startsWith("A") ? "View Comm" : "View Win";

    const btnViewProps = {
      size: "small",
      color: "primary",
      children: viewAction,
      to: url,
      component: Link,
    };

    return <Button {...btnViewProps} />;
  }

  function renderTxnActions() {
    return <>{pending ? renderApproveOrReject() : renderViewTransaction()}</>;
  }

  function renderWithdrawTxn() {
    if (!txn) {
      return;
    }

    return (
      <Card>
        <Line status={status} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Txn No." readOnly value={id} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Username" readOnly value={userId} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Status" readOnly value={capitalize(status)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Amount"
                readOnly
                value={formatBetAmount(-amount)}
              />
            </Grid>
            {renderTxnDetail()}
          </Grid>
        </CardContent>
        <CardActions>{renderTxnActions()}</CardActions>
      </Card>
    );
  }

  function renderBankAccount() {
    return (
      <Card>
        <Line status={status} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Bank Name" readOnly value={bankName} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Account Name" readOnly value={accountName} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Account Number"
                readOnly
                value={accountNumber}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Grid container spacing={2} direction="row" justifyContent="center">
      <Grid item md={6} xs={12}>
        {loading ? <Loader paper /> : renderWithdrawTxn()}
      </Grid>
      {toAccount === "BANK" && (
        <Grid item md={6} xs={12}>
          {renderBankAccount()}
        </Grid>
      )}
    </Grid>
  );
}

export default WithdrawTxnDetail;
