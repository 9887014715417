export const BANK_NAMES = [
  "Maybank",
  "CIMB Bank",
  "RHB Bank",
  "Bank Islam",
  "Bank Muamalat",
  "Bank Rakyat",
  "Bank Simpanan Nasional",
  "Citibank",
  "Hong Leong Bank",
  "HSBC Bank",
  "OCBC Bank",
  "Public Bank",
  "Affin Bank",
  "AmBank",
  "Agro Bank",
  "Alliance Bank",
  "Al-Rajhi Bank",
  "Standard Chartered Bank",
  "United Overseas Bank",
];

export const PAYMENT_METHOD_BANKS = [
  { text: "Maybank", value: "MBB" },
  { text: "CIMB Bank", value: "CIMB" },
  { text: "RHB Bank", value: "RHB" },
  { text: "Bank Islam", value: "BIS" },
  { text: "Bank Muamalat", value: "BMU" },
  { text: "Bank Rakyat", value: "BRA" },
  { text: "Bank Simpanan Nasional", value: "BSN" },
  { text: "Citibank", value: "CITI" },
  { text: "Hong Leong Bank", value: "HLB" },
  { text: "HSBC Bank", value: "HSBC" },
  { text: "OCBC Bank", value: "OCBC" },
  { text: "Public Bank", value: "PBB" },
  { text: "Affin Bank", value: "AFF" },
  { text: "AmBank", value: "AMB" },
  { text: "Agro Bank", value: "AGB" },
  { text: "Alliance Bank", value: "ALZ" },
  { text: "Al-Rajhi Bank", value: "ARB" },
  { text: "Standard Chartered Bank", value: "SCB" },
  { text: "United Overseas Bank", value: "UOB" },
];

export const PAYMENT_METHODS = PAYMENT_METHOD_BANKS.reduce(
  (prev, { text, value }) => ({ ...prev, [value]: text }),
  {
    DUITNOW: "DuitNow",
    WIN: "Win",
    COM: "Com",
    TRANSFER: "Transfer",
    CASH: "Cash",
  }
);

export const BANKS = BANK_NAMES.map((value) => ({ text: value, value }));
