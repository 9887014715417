import {
  PhoneNumberFormat as PNF,
  PhoneNumberUtil,
} from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isValidNumber = (val) => {
  try {
    const phone = tryParse(val);
    return phoneUtil.isValidNumber(phone);
  } catch (ex) {
    return false;
  }
};

export const formatNumber = (val) => {
  try {
    const phone = tryParse(val);
    return phoneUtil.format(phone, PNF.E164);
  } catch (ex) {
    return val;
  }
};

export const getPhoneOrUsername = (username = "") => {
  const isPhone =
    isValidNumber(username) ||
    isValidNumber(`+${username}`) ||
    isValidNumber(`+6${username}`) ||
    isValidNumber(`6${username}`);

  if (!isPhone || username.startsWith("+6")) {
    return username;
  }

  if (username.startsWith("6")) {
    return `+${username}`;
  }

  return `+6${username}`;
};

const tryParse = (val) => {
  try {
    return phoneUtil.parse(val);
  } catch (ex) {
    return;
  }
};
