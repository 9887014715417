import { isEmptyObject } from "./Object";
import { isEmpty } from "./String";

export const isArray = (array) => Array.isArray(array);

export const isEmptyArray = (array) => !array || array.length === 0;

export const isSomeEmptyArray = (array) =>
  isArray(array) && array.some((o) => isEmptyObject(o) || isEmpty(o));

export const isEveryEmptyArray = (array) =>
  isArray(array) && array.every((o) => isEmptyObject(o) || isEmpty(o));

export const uniqueArray = (array) =>
  isArray(array) ? [...new Set(array)] : [];

export const uniqueFilledArray = (array) =>
  isArray(array) ? [...new Set(array.filter((o) => !isEmptyObject(o)))] : [];

export const isArrayEquals = (array1 = [], array2 = []) =>
  array1.length === array2.length &&
  array1.every((value, index) => value === array2[index]);

export const substractArray = (superArray, subArray) =>
  superArray.filter((s) => subArray.indexOf(s) === -1);

export const substractObjectArray = (superArray, subArray, key) =>
  superArray.filter((s) => subArray.findIndex((d) => d[key] === s[key]) === -1);

export const filterArray = (superArray, subArray) =>
  superArray.filter((s) => subArray.indexOf(s) !== -1);

export const filterObjectArray = (superArray, subArray, key) =>
  superArray.filter((s) => subArray.findIndex((d) => d[key] === s[key]) !== -1);

export const trimArray = (array = []) => array.filter((s) => !!s);
