import React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import Forget from "./auth/Forget";
import Login from "./auth/Login";
import LoginAdmin from "./auth/Login.Admin";
import Signup from "./auth/Signup";
import Loader from "./components/Loader";
import Snackbar from "./components/Snackbar";
import { useAppContext } from "./context/AppContext";
import { useAuthContext } from "./context/AuthContext";
import Root from "./root/Root";
import RootAdmin from "./root/Root.Admin";

const Main = styled.main({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function App() {
  const { message, setMessage } = useAppContext();
  const { loading, isLogged, user } = useAuthContext();
  const { isAdmin } = user ?? {};

  const messageProps = { message, onClose: () => setMessage() };

  if (loading) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  function renderContent() {
    return isLogged ? (
      isAdmin ? (
        <RootAdmin />
      ) : (
        <Root />
      )
    ) : (
      <Switch>
        <Route path="/admin/login">
          <LoginAdmin />
        </Route>
        <Route path="/admin">
          <Redirect to="/admin/login" />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register/:reference?">
          <Signup />
        </Route>
        <Route path="/forget">
          <Forget />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <>
      {renderContent()}
      <Snackbar {...messageProps} />
    </>
  );
}

export default App;
