import Button from "@material-ui/core/Button";
import LibCard from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useEffect } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import Copyright from "../components/Copyright";
import LibDatepicker from "../components/Datepicker";
import Loader from "../components/Loader";
import LibTextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { roundToDigit, roundToPercent } from "../helpers/Common";
import { MAX_RULE } from "../helpers/Form";
import { tryParse } from "../helpers/String";
import useSettings from "../hooks/api/useSettings.Admin";
import useForm from "../hooks/useForm";

const TO_BE_PER = [
  "walletMKTBonus",
  "walletGDBonus",
  "walletMKTComm",
  "walletGDComm",
  "topupMKTProfit",
  "topupGDProfit",
  "winUserMKTProfit",
  "winUserGDProfit",
  "winMKTComm",
  "winGDComm",
];

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

const TextField = styled(LibTextField)({
  flex: 1,
  width: "calc(100% - 80px)",
  marginRight: 15,
});

const Datepicker = styled(LibDatepicker)({
  flex: 1,
  width: "calc(100% - 80px)",
  marginRight: 15,
});

function TxnDetailComm() {
  const { setPageTitle, setMessage } = useAppContext();
  const [setting, loading, update] = useSettings();

  const form = useForm({
    defaultValues: {
      specialDates: [{ date: "" }],
    },
  });
  const { reset, handleSubmit, control, register } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "specialDates",
  });

  useEffect(() => {
    setPageTitle("Setting");
  }, []);

  useEffect(() => {
    if (!setting) {
      return;
    }

    const formatted = Object.entries(setting).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: TO_BE_PER.includes(key) ? roundToPercent(value) : value,
      }),
      {}
    );

    const { specialDates, ...rest } = formatted;

    reset({
      ...rest,
      specialDates: tryParse(specialDates, [])?.map((date) => ({ date })) || [
        { date: "" },
      ],
    });
  }, [setting]);

  async function onUpdateDate({ specialDates }) {
    const filtered = specialDates.map(({ date }) => date).filter((s) => !!s);

    await update({
      id: "specialDates",
      value: JSON.stringify(filtered),
    });
    setMessage(`Special Date updated!`);
  }

  function renderSingle({ key, label, perc, max, ...rest }) {
    const onSubmit = async ({ [key]: value }) => {
      await update({ id: key, value: perc ? roundToDigit(value) : value });
      setMessage(`${label} updated!`);
    };

    const btnProps = {
      color: "primary",
      children: "Update",
      onClick: handleSubmit(onSubmit),
    };

    const inputProps = {
      label,
      name: key,
      endIcon: perc ? (
        <InputAdornment position="end">
          {max ? ` / ${roundToPercent(max)} %` : "%"}
        </InputAdornment>
      ) : null,
      rules: max
        ? {
            ...MAX_RULE(roundToPercent(max)),
          }
        : {},
      ...rest,
    };

    return (
      <Grid item xs={12} spacing={2}>
        <TextField {...inputProps} />
        <Button size="small" {...btnProps} />
      </Grid>
    );
  }

  function renderPublic() {
    if (!setting) {
      return;
    }

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {renderSingle({
                key: "annoucement",
                label: "Annoucement",
                rows: 8,
              })}
              {renderSingle({
                key: "custServiceText",
                label: "Whatsapp Message",
                rows: 6,
              })}
              {renderSingle({
                key: "custServiceContact",
                label: "Whatsapp Number",
              })}
            </Grid>
          </CardContent>
        </Card>
      </FormProvider>
    );
  }

  function renderWallet() {
    if (!setting) {
      return;
    }

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {renderSingle({
                key: "walletMKTBonus",
                label: "Wallet 1 Bonus",
                perc: true,
              })}
              {renderSingle({
                key: "walletGDBonus",
                label: "Wallet 2 Bonus",
                perc: true,
              })}
              {renderSingle({
                key: "walletMKTComm",
                label: "Upline 1 Bonus",
                perc: true,
              })}
              {renderSingle({
                key: "walletGDComm",
                label: "Upline 2 Bonus",
                perc: true,
              })}
            </Grid>
          </CardContent>
        </Card>
      </FormProvider>
    );
  }

  function renderAPI() {
    if (!setting) {
      return;
    }

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {renderSingle({
                key: "apiUser",
                label: "API Username",
              })}
              {renderSingle({
                key: "apiPass",
                label: "Api Password",
              })}
              {renderSingle({
                key: "masterUser",
                label: "Master Username",
              })}
              {renderSingle({
                key: "masterPass",
                label: "Master Password",
              })}
            </Grid>
          </CardContent>
        </Card>
      </FormProvider>
    );
  }

  function renderComm() {
    if (!setting) {
      return;
    }

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {renderSingle({
                key: "topupMKTProfit",
                label: "Topup 1 Profit",
                perc: true,
              })}
              {renderSingle({
                key: "topupGDProfit",
                label: "Topup 2 Profit",
                perc: true,
              })}
              {renderSingle({
                key: "winUserMKTProfit",
                label: "Com & Win > Wallet 1 Profit",
                perc: true,
              })}
              {renderSingle({
                key: "winUserGDProfit",
                label: "Com & Win > Wallet 2 Profit",
                perc: true,
              })}
              {renderSingle({
                key: "winMKTComm",
                label: "Winning 1 Profit",
                perc: true,
              })}
              {renderSingle({
                key: "winGDComm",
                label: "Winning 2 Profit",
                perc: true,
              })}
            </Grid>
          </CardContent>
        </Card>
      </FormProvider>
    );
  }

  function renderSpecial() {
    if (!setting) {
      return;
    }

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {fields.map((item, index) => {
                return (
                  <Grid item xs={12} spacing={2}>
                    <Datepicker
                      key={item.id}
                      {...register(`specialDates.${index}.date`)}
                    />
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => remove(index)}
                    >
                      Delete
                    </Button>
                  </Grid>
                );
              })}
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      append({ date: "" });
                    }}
                  >
                    Add Special Date
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit(onUpdateDate)}
                  >
                    Update Special Date
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        {loading ? <Loader paper /> : renderAPI()}
      </Grid>
      <Grid item md={6} xs={12}>
        {loading ? <Loader paper /> : renderWallet()}
      </Grid>
      <Grid item md={6} xs={12}>
        {loading ? <Loader paper /> : renderPublic()}
      </Grid>
      <Grid item md={6} xs={12}>
        {loading ? <Loader paper /> : renderComm()}
      </Grid>
      <Grid item md={6} xs={12}>
        {loading ? <Loader paper /> : renderSpecial()}
      </Grid>
      <Grid item spacing={2} xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
}
export default TxnDetailComm;
