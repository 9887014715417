import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import Datepicker from "../components/Datepicker";
import Select from "../components/Select";
import { getTxnTypes } from "../constants/Txn";
import { useWalletContext } from "../context/WalletContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useUserAccount from "../hooks/api/useUserAccount";
import useForm from "../hooks/useForm";
import TxnComm from "./Txn.Comm";
import TxnWallet from "./Txn.Wallet";
import TxnWin from "./Txn.Win";

const TODAY = moment().toJSON();

function Txn({ win }) {
  const { selected } = useWalletContext();
  const [userAccount, loading] = useUserAccount();
  const txnRef = useRef();

  const txnTypes = useMemo(() => {
    const all = getTxnTypes({ userAccount, loading });

    const filtered =
      selected === "GD"
        ? all.filter((s) => s.wallet !== "MKT")
        : all.filter((s) => s.wallet !== "GD");

    return win
      ? filtered.filter((s) => s.value === "WIN")
      : filtered.filter((s) => s.value !== "WIN");
  }, [userAccount, win, loading, selected]);

  const form = useForm({
    defaultValues: {
      type: txnTypes[0]?.value,
      from: TODAY,
      to: TODAY,
    },
  });
  const [t] = useTranslation();

  const { watch, setValue, clearErrors, handleSubmit } = form;

  const [formValue, toValue, txnType] = watch(["from", "to", "type"]);

  useEffect(() => {
    setValue("from", TODAY);
    setValue("to", TODAY);
  }, [setValue, txnType]);

  function onReset() {
    clearErrors();
    setValue("from", TODAY);
    setValue("to", TODAY);
    txnRef.current.submit({ from: TODAY, to: TODAY });
  }

  function onSubmit({ from, to }) {
    txnRef.current.submit({ from, to });
  }

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  const txnProps = {
    txnRef,
    onSubmit,
  };

  function renderSearchBox() {
    return (
      <FormProvider {...form}>
        <Card component="form" {...formProps}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  label={t("Transaction Type")}
                  name="type"
                  items={txnTypes}
                  displayEmpty={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Datepicker
                  label={t("From Date")}
                  name="from"
                  maxDate={toValue}
                  rules={{
                    ...REQUIRED_RULE,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Datepicker
                  label={t("To Date")}
                  name="to"
                  minDate={formValue}
                  rules={{
                    ...REQUIRED_RULE,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              {t("Search")}
            </Button>
            <Button onClick={onReset} color="secondary">
              {t("Reset")}
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  function renderTable() {
    if (txnType === "GD" || txnType === "MKT") {
      return <TxnWallet {...txnProps} />;
    }

    if (txnType === "COM") {
      return <TxnComm {...txnProps} />;
    }

    if (txnType === "WIN") {
      return <TxnWin {...txnProps} />;
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderSearchBox()}
      </Grid>
      <Grid item xs={12}>
        {renderTable()}
      </Grid>
    </Grid>
  );
}
export default Txn;
