import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import GamesIcon from "@material-ui/icons/Games";
import LockIcon from "@material-ui/icons/Lock";
import MessageIcon from "@material-ui/icons/Message";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAuthContext } from "../context/AuthContext";

export const DRAWER_WIDTH = 280;

const ToolbarIcon = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 8px",
  ...theme.mixins.toolbar,
}));

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
}));

const SHARED_MENUS = [
  {
    icon: AccountBalanceWalletIcon,
    text: "Topup Transactions",
    url: `/admin/txn/topup`,
  },
  {
    icon: GamesIcon,
    text: "Bet Transactions",
    url: `/admin/txn/bet`,
  },
  {
    icon: EmojiEventsIcon,
    text: "Win Transactions",
    url: `/admin/txn/win`,
  },
  {
    icon: SwapHorizIcon,
    text: "Transfer Transactions",
    url: `/admin/txn/transfer`,
  },
  {
    icon: SystemUpdateAltIcon,
    text: "Withdraw Transactions",
    url: `/admin/txn/withdraw`,
  },
  {
    icon: AttachMoneyIcon,
    text: "Comm. Transactions",
    url: `/admin/txn/comm`,
  },
];

const ADMIN_MENUS = [
  {
    title: "Transactions",
    menus: [
      {
        icon: DashboardIcon,
        text: "Dashboard",
        url: `/`,
      },
      ...SHARED_MENUS,
    ],
  },
  {
    title: "Users",
    menus: [
      {
        icon: PeopleIcon,
        text: "User Management",
        url: `/admin/user/`,
      },
      {
        icon: AccountBalanceIcon,
        text: "Bank Accounts",
        url: `/admin/bank/`,
      },
      {
        icon: SettingsIcon,
        text: "Settings",
        url: `/admin/setting/`,
      },
      {
        icon: MessageIcon,
        text: "Inbox",
        url: `/admin/message/`,
      },
    ],
  },
  {
    title: "Account",
    menus: [
      {
        icon: SupervisorAccountIcon,
        text: "Admin Management",
        url: "/admin/operator/",
      },
      {
        icon: LockIcon,
        text: "Change Password",
        url: "/admin/password/",
      },
    ],
  },
];

const OPERATOR_MENUS = [
  {
    title: "Transactions",
    menus: [...SHARED_MENUS],
  },
  {
    title: "Users",
    menus: [
      {
        icon: PeopleIcon,
        text: "User Management",
        url: `/admin/user/`,
      },
    ],
  },
  {
    title: "Account",
    menus: [
      {
        icon: LockIcon,
        text: "Change Password",
        url: "/admin/password/",
      },
    ],
  },
];

function AppDrawer({ open, setOpen }) {
  const classes = useStyles();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { user } = useAuthContext();
  const { role } = user ?? {};
  const isAdmin = role === "admin";
  const allMenus = isAdmin ? ADMIN_MENUS : OPERATOR_MENUS;

  const renderItem = ({ icon: Icon, text, url }) => {
    return (
      <ListItem
        key={url}
        button
        component={Link}
        to={url}
        onClick={handleDrawerClose}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  return (
    <Drawer
      // variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      anchor="left"
      open={open}
    >
      <ToolbarIcon>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>
      <Divider />

      {/* {userMenus.length !== 0 &&  <List>
        <ListSubheader inset>User</ListSubheader>
        {userMenus.map(renderItem)}
      </List>}
      <Divider />
      <List>
        <ListSubheader inset>Gaming</ListSubheader>
        {gameMenus.map(renderItem)}
      </List> */}
      {allMenus.map(({ title, menus }) => (
        <List>
          <ListSubheader inset>{title}</ListSubheader>
          {menus.map(renderItem)}
        </List>
      ))}
    </Drawer>
  );
}

export default AppDrawer;
