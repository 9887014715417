import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import HelpButton from "../components/HelpButton";
import { useAppContext } from "../context/AppContext";
import Txn from "../txn/Txn";

function Account({ win }) {
  const { setPageTitle } = useAppContext();

  useEffect(() => {
    setPageTitle(win ? "Winning Number" : "My Account");
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Txn win={win} />
      </Grid>
      <Grid item xs={12}>
        <HelpButton />
      </Grid>
    </Grid>
  );
}
export default Account;
