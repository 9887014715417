import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {
  DatePicker as LibDatePicker,
  DateTimePicker as LibDateTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

const DatePicker = ({
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
  time,
  minDate,
  maxDate,

  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const props = {
    ...rest,
    label,
    inputRef,
    onChange: (val) => {
      const formatted = time
        ? val.toJSON()
        : moment(val).startOf("day").toJSON();
      onChange(formatted);
    },
    value: value || null,
    error,
    helperText: message,
    disabled,
    fullWidth: true,
    disableToolbar: time ? false : true,
    inputVariant: "outlined",
    variant: "inline",
    format: time ? "dd/MM/yyyy HH:mm:ss" : "dd/MM/yyyy",
    autoOk: true,
    minDate: minDate ? moment(minDate).toDate() : undefined,
    maxDate: maxDate ? moment(maxDate).toDate() : undefined,
    InputProps: {
      endAdornment: (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onChange(null);
          }}
        >
          <ClearIcon />
        </IconButton>
      ),
    },
  };

  if (time) {
    return <LibDateTimePicker {...props} />;
  }

  return <LibDatePicker {...props} />;
};

export default DatePicker;
