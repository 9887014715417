import LibSnackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Snackbar({ message, onClose: pOnClose }) {
  const [close, setClose] = useState(true);

  const success = typeof message === "string" || typeof message === "undefined";

  function onClose() {
    setClose(true);
    pOnClose && pOnClose();
  }

  useEffect(() => {
    setClose(!message);
  }, [message]);

  const snackProps = {
    anchorOrigin: { vertical: "top", horizontal: "right" },
    open: !close,
    onClose,
  };

  const props = success
    ? {
        ...snackProps,
        autoHideDuration: 3000,
      }
    : {
        ...snackProps,
      };

  const alertProps = success
    ? {
        severity: "success",
        onClose,
        children: message,
      }
    : {
        severity: "error",
        onClose,
        children: message?.message,
      };

  return (
    <LibSnackbar {...props}>
      <Alert {...alertProps} />
    </LibSnackbar>
  );
}

export default Snackbar;
