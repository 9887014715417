import LibCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import styled from "styled-components";

const Image = styled.img({
  height: 28,
  width: 28,
  display: "flex",
  margin: "auto",
  objectFit: "contain",
  marginLeft: 2,
});

const Slim9Label = styled(FormControlLabel)(({ $slim9 }) => ({
  marginRight: $slim9 && 0,
  marginLeft: $slim9 && 0,

  ".MuiTypography-body1": {
    fontSize: $slim9 ? 13 : "1rem",
  },

  "> span": {
    paddingRight: $slim9 && 0,
  },

  // ".MuiSvgIcon-root": {
  //   width: $slim9 ? 18 : "1em",
  // },
}));

const AppCheckbox = styled(LibCheckbox)(({ $slim9 }) => ({
  padding: $slim9 && 5,
}));

function Checkbox({ slim9, value, text, image, checked, onChange }) {
  const handleChange = () => {
    onChange(value);
  };

  if (image) {
    return (
      <Slim9Label
        $slim9={slim9}
        control={
          <AppCheckbox
            $slim9={slim9}
            checked={checked}
            onChange={handleChange}
          />
        }
        label={<Image src={image} />}
      />
    );
  }

  return (
    <Slim9Label
      $slim9={slim9}
      control={
        <AppCheckbox $slim9={slim9} checked={checked} onChange={handleChange} />
      }
      label={text}
    />
  );
}

export default Checkbox;
