import { Button } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useController, useFormContext } from "react-hook-form";
import Resizer from "react-image-file-resizer";
import styled from "styled-components";
import { API_URL } from "../constants/EnvConfig";
import { useAuthContext } from "../context/AuthContext";
import useAnyApi from "../hooks/api/useAnyApi";
import Loader from "./Loader";

const Container = styled.div({});

const Content = styled.div(({ type, error, theme }) => ({
  minHeight: type === "small" ? 100 : 400,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderWidth: 1,
  border: "1px solid rgba(255, 255, 255, 0.23)",
  borderColor: error ? theme.palette.error.main : "rgba(255, 255, 255, 0.23)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  position: "relative",
  marginTop: theme.spacing(2),
  overflow: "hidden",
}));

const Image = styled.div(({ $src }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${$src})`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",

  "&:after": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(66,66,66, .25)",
  },
}));

const upload = async (url, body, token) => {
  const response = await fetch(`${API_URL}${url}`, {
    body,
    headers: {
      Accept: "application/json",
      Authorization: token && `Bearer ${token}`,
    },
    method: "PUT",
  });
  return await response.json();
};

function Dropzone({
  url,
  label,
  type,
  name,
  rules,
  defaultValue = "",
  disabled,
}) {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const [loading, invokeUpload] = useAnyApi();
  const { userToken: token } = useAuthContext();

  const resizeFile = useCallback(
    (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          800,
          800,
          "JPEG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
        );
      }),
    []
  );

  const remoteUpload = useCallback(
    async (file) => {
      const body = new FormData();
      try {
        const resized = await resizeFile(file);
        body.append("asset", resized);
        const { key } = await upload(url, body, token);
        return key;
      } catch (ex) {
        console.log({ ex });
      }
    },
    [token, url, resizeFile]
  );

  const onDrop = async (files) => {
    const file = await invokeUpload(() => remoteUpload(files[0]));
    onChange(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/*",
  });

  const contProps = {
    error,
    type,
  };

  const btnProps = {
    variant: "outlined",
    fullWidth: true,
    component: "label",
    color: "secondary",
  };

  if (loading) {
    return (
      <>
        <Button {...btnProps}>{label}</Button>
        <Content {...contProps}>
          <Loader />
        </Content>
      </>
    );
  }

  if (disabled && value) {
    return (
      value && (
        <Content {...contProps}>
          <Image $src={value} />
        </Content>
      )
    );
  }

  return (
    <>
      <Container {...getRootProps()}>
        <input {...getInputProps()} />
        <Button {...btnProps}>{label}</Button>
        {value && (
          <Content {...contProps}>
            <Image $src={value} />
          </Content>
        )}
      </Container>
      {message && <FormHelperText {...contProps}>{message}</FormHelperText>}
    </>
  );
}

export default Dropzone;
