import { IconButton } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { PAYMENT_METHOD_BANKS } from "../constants/Withdraw";
import { useAppContext } from "../context/AppContext";
import { formatStandard } from "../helpers/DateTime";
import useBankAccount from "../hooks/api/useBankAccount.Admin";
import useMount from "../hooks/useMount";
import Toggle from "./Toggle";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ active, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: active ? green[500] : red[900],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const OffIcon = styled(ToggleOffIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));

const OnIcon = styled(ToggleOnIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));

function BankAccount() {
  const { setPageTitle } = useAppContext();
  const [toggleItem, setToggleItem] = useState();
  const history = useHistory();

  const { url } = useRouteMatch();

  const { response, loading, list } = useBankAccount();

  useMount(() => {
    list();
    setPageTitle("Bank Account");
  });

  const headCells = [
    {
      id: "accountNum",
      numeric: false,
      label: "Bank Account",
      custom: renderStatus,
      customHeader: renderStatusHeader,
    },
    {
      id: "bank",
      numeric: false,
      label: "Bank",
      format: ({ bank }) =>
        PAYMENT_METHOD_BANKS.find(({ value }) => bank === value)?.text,
    },
    {
      id: "accountName",
      numeric: false,
      label: "Bank Name",
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status head>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { accountNum, id, active, method } = item;

    return (
      <StatusContent>
        <Dot active={active} />

        <StatusText to={`/admin/bank/${id}`}>
          {method === "DUITNOW" ? "DuitNow" : accountNum}
        </StatusText>

        <IconButton
          title={active ? "Disable Bank" : "Enable Bank"}
          size="small"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setToggleItem(item);
          }}
        >
          {active ? <OnIcon active={active} /> : <OffIcon active={active} />}
        </IconButton>
      </StatusContent>
    );
  }

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Bank Accounts",
    defaultSort: "createdAt",
    onAdd: () => {
      history.push(`${url}add`);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => list()}
      />
    </>
  );
}

export default BankAccount;
