import Auth from "@aws-amplify/auth";
import { clean } from "./Object";

export async function isLogged() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return !!user;
  } catch (ex) {
    return false;
  }
}

export async function getAccessToken(bypassCache) {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache });
    const session = cognitoUser.signInUserSession;
    const { jwtToken } = session.getIdToken();
    return jwtToken;
  } catch (ex) {
    return "";
  }
}

export async function getCurrentUser(bypassCache = false) {
  const { attributes, username, signInUserSession } =
    await Auth.currentAuthenticatedUser({
      bypassCache,
    });

  const { payload } = signInUserSession?.idToken;
  const { "cognito:groups": groups = [] } = payload ?? {};

  const { phone_number: mobile, "custom:role": role } = attributes;

  return {
    mobile,
    role,
    id: username,
    isAdmin: groups.includes("admin"),
  };
}

export function cleanUpdatePersonal(personal) {
  const {
    title: middle_name,
    firstname: given_name,
    lastname: family_name,
    mobile: phone_number,
    ...rest
  } = personal;

  return clean({
    ...rest,
    middle_name,
    given_name,
    family_name,
    phone_number,
  });
}
