import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "../components/Select";
import TextField from "../components/TextField";
import { BANKS } from "../constants/Withdraw";
import { REQUIRED_RULE } from "../helpers/Form";

function BankAccount() {
  const [t] = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <Select
          label={t("Bank Name")}
          name="bankName"
          items={BANKS}
          displayEmpty={false}
          rules={{
            ...REQUIRED_RULE,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t("Account No")}
          name="accountNumber"
          rules={{
            ...REQUIRED_RULE,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t("Account Name")}
          name="accountName"
          rules={{
            ...REQUIRED_RULE,
          }}
        />
      </Grid>
    </>
  );
}
export default BankAccount;
