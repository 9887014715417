import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Button from "../components/Button";
import Loader from "./Loader";

export default function AlertDialog({
  onClose,
  onConfirm,

  loading,
  open,
  title,
  desc,
  children,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) {
  function renderContent() {
    const confirmColor = onConfirm ? "primary" : "secondary";
    const cancelColor = !onConfirm ? "primary" : "secondary";

    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {desc && <DialogContentText>{desc}</DialogContentText>}
          {children}
        </DialogContent>
        <DialogActions>
          {onConfirm && (
            <Button
              variant="text"
              fullWidth={false}
              onClick={onConfirm}
              color={confirmColor}
            >
              {confirmText}
            </Button>
          )}
          <Button
            variant="text"
            fullWidth={false}
            onClick={onClose}
            color={cancelColor}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {loading ? <Loader /> : renderContent()}
    </Dialog>
  );
}
