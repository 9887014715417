import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import product1Image from "../assets/product1.png";
import product2Image from "../assets/product2.png";
import { useAppContext } from "../context/AppContext";
import { useWalletContext } from "../context/WalletContext";

const Content = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  flex: 1,
});

const Menu = styled.div({
  margin: 20,
  alignSelf: "center",
});

const Image = styled.img({});

const MENU = [
  {
    id: "MKT",
    image: product2Image,
  },
  {
    id: "GD",
    image: product1Image,
  },
];

function Home() {
  const { setPageTitle } = useAppContext();
  const { setSelected } = useWalletContext();

  const history = useHistory();

  const [t] = useTranslation();

  useEffect(() => {
    setPageTitle(t("Platform"));
    setSelected();
  }, []);

  function handleMenu(id) {
    setSelected(id);
    history.push("main");
  }

  function renderItem({ id, image }) {
    const menuProps = {
      key: id,
      onClick: () => handleMenu(id),
    };

    return (
      <Menu {...menuProps}>
        <Image src={image} />
      </Menu>
    );
  }

  return <Content>{MENU.map(renderItem)}</Content>;
}
export default Home;
