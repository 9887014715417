import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import React, { useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";
import Toggle from "../adminAccount/Toggle";
import Button from "../components/Button";
import Loader from "../components/Loader";
import PasswordPolicy from "../components/PasswordPolicy";
import TextField, { PureTextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { PASSWORD_POLICY, REQUIRED_RULE } from "../helpers/Form";
import { capitalize } from "../helpers/String";
import useAdminAccount from "../hooks/api/useAdminAccount.Admin";
import useAdminCreditTotal from "../hooks/api/useAdminCreditTotal.Admin";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import AddCredit from "./AddCredit";
import CreditTxns from "./CreditTxns";

const STATUS = {
  active: green[500],
  inactive: red[900],
  [undefined]: amber[500],
};

const ROLES = [
  {
    text: "Operator",
    value: "operator",
  },
  {
    text: "Admin",
    value: "admin",
  },
];

const Line = styled.div(({ $status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS[$status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

const PasswordInput = styled(TextField).attrs({
  name: "password",
  label: "Password",
  type: "password",
  autoComplete: "new-password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

function AdminAccountDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { id = "" } = useParams();
  const [user, loading, refresh, toggle, update] = useAdminAccount(id, true);
  const [cTotal, cLoading] = useAdminCreditTotal(id);
  const { role, credit = 0, status } = user ?? {};
  const { total: approvedCredit = 0 } = cTotal ?? {};

  const [toggleItem, setToggleItem] = useState();
  const [creditItem, setCreditItem] = useState();

  const form = useForm({
    defaultValues: {
      role: ROLES[0]?.value,
    },
  });
  const { handleSubmit, watch, reset } = form;

  const password = useRef();
  password.current = watch("password", "");

  useMount(() => {
    setPageTitle(`Admin Account Detail`);
  });

  async function onSubmit({ password }) {
    try {
      await update({ password });
      setMessage("Password Updated");
      reset();
    } catch (ex) {
      const { message = "Unable update admin account" } = ex;
      setMessage({ message });
    }
  }

  function renderCredit() {
    return (
      <Card>
        <Line $status={status} />
        <CardContent>
          <FormProvider {...form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PureTextField
                  label="Role"
                  value={ROLES.find(({ value }) => value === role)?.text}
                  readOnly
                />
              </Grid>
              <Grid item xs={12}>
                <PureTextField label="Current Credit" value={credit} readOnly />
              </Grid>
              <Grid item xs={12}>
                <PureTextField
                  label="Approved Credit"
                  value={cLoading ? "..." : approvedCredit}
                  readOnly
                />
              </Grid>
            </Grid>
          </FormProvider>
        </CardContent>
        <CardActions>
          <Button
            type="button"
            color="primary"
            onClick={() => setCreditItem(user)}
          >
            Add Credit
          </Button>
        </CardActions>
      </Card>
    );
  }

  function rendePassword() {
    const policyProps = {
      value: password.current,
      isSubmitted: false,
    };

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Line $status={status} />
            <CardContent>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PureTextField label="Username" value={id} readOnly />
                  </Grid>
                  <Grid item xs={12}>
                    <PureTextField
                      label="Status"
                      value={capitalize(status)}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordInput />
                    <PasswordPolicy {...policyProps} />
                  </Grid>
                </Grid>
              </FormProvider>
            </CardContent>
            <CardActions>
              <Button type="submit" color="primary">
                Update Password
              </Button>

              <Button
                color="secondary"
                onClick={(e) => {
                  setToggleItem(user);
                }}
              >
                {status === "active" ? "Disable " : "Enable "} User
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  return (
    <>
      <Grid container spacing={2} justify="center">
        <Grid item md={6} xs={12}>
          {loading ? <Loader paper /> : rendePassword()}
        </Grid>
        <Grid item md={6} xs={12}>
          {loading ? <Loader paper /> : renderCredit()}
        </Grid>
        <CreditTxns id={id} />
      </Grid>
      <Toggle
        loading={loading}
        item={toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={async () => {
          await toggle();
          await refresh();
        }}
      />
      <AddCredit
        item={creditItem}
        onClose={() => setCreditItem()}
        onAddCredit={() => {
          refresh();
        }}
      />
    </>
  );
}

export default AdminAccountDetail;
