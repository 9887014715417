import Paper from "@material-ui/core/Paper";
import LibTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";

const Gap = styled.div(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Table = styled(LibTable)({
  th: {
    whiteSpace: "nowrap",
  },
});

function createData(col1, col2, col3, col4, col5, col6, col7, col8) {
  return { col1, col2, col3, col4, col5, col6, col7, col8 };
}

const sd01MktRows = [
  createData(
    "票价",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00"
  ),
  createData(
    "头奖",
    "2,750",
    "3,850",
    "6,600",
    "720",
    "240",
    "16,600",
    "111,000"
  ),
  createData("二奖", "1,100", "2,200", "", "", "240", "5,250", "3,300"),
  createData("三奖", "550", "1,100", "", "", "240", "3,300", "330"),
  createData("特别奖", "220", "", "", "", "", "550", "33"),
  createData("安慰奖", "66", "", "", "", "", "22", "4.40"),
  createData("奖金6", "", "", "", "", "", "5.50"),
];

const defaultMktRows = [
  createData(
    "票价",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00",
    "1.00"
  ),
  createData(
    "头奖",
    "2,500",
    "3,500",
    "6,000",
    "645",
    "215",
    "15,750",
    "105,000"
  ),
  createData("二奖", "1,000", "2,000", "", "", "215", "5,250", "3,150"),
  createData("三奖", "500", "1,000", "", "", "215", "3,150", "315"),
  createData("特别奖", "200", "", "", "", "", "525", "31.50"),
  createData("安慰奖", "60", "", "", "", "", "21", "4.20"),
  createData("奖金6", "", "", "", "", "", "5.25"),
];

// const mktRows = CLIENT_ID === "jt" ? sd01MktRows : defaultMktRows;
const mktRows = defaultMktRows;

const gdRows = [
  createData("票价", "1.00", "1.00", "1.00", "1.00", "1.00", "1.00"),
  createData("头奖", "2,500", "3,500", "6,000", "660", "220", "150,000"),
  createData("二奖", "1,000", "2,000", "", "", "220", "5,000"),
  createData("三奖", "500", "1,000", "", "", "220", "500"),
  createData("特别奖", "200", "", "", "", "", "50"),
  createData("安慰奖", "60", "", "", "", "", "5"),
];

function MKTPackage() {
  return (
    <Paper>
      <Toolbar variant="dense">
        <Typography variant="h6" component="div">
          M-P-T-S-B-K-W Package
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table size="col3">
          <TableHead>
            <TableRow>
              <TableCell>Prize</TableCell>
              <TableCell>col2</TableCell>
              <TableCell>col3</TableCell>
              <TableCell>4A</TableCell>
              <TableCell>3A</TableCell>
              <TableCell>3C</TableCell>
              <TableCell>5D</TableCell>
              <TableCell>6D</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mktRows.map((row) => (
              <TableRow
                key={row.col1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.col1}
                </TableCell>
                <TableCell>{row.col2}</TableCell>
                <TableCell>{row.col3}</TableCell>
                <TableCell>{row.col4}</TableCell>
                <TableCell>{row.col5}</TableCell>
                <TableCell>{row.col6}</TableCell>
                <TableCell>{row.col7}</TableCell>
                <TableCell>{row.col8}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function GDPackage() {
  return (
    <Paper>
      <Toolbar variant="dense">
        <Typography variant="h6" component="div">
          Grand Dragon Package
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table size="col3">
          <TableHead>
            <TableRow>
              <TableCell>Prize</TableCell>
              <TableCell>col2</TableCell>
              <TableCell>col3</TableCell>
              <TableCell>4A</TableCell>
              <TableCell>3A</TableCell>
              <TableCell>3C</TableCell>
              <TableCell>6D</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gdRows.map((row) => (
              <TableRow
                key={row.col1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.col1}
                </TableCell>
                <TableCell>{row.col2}</TableCell>
                <TableCell>{row.col3}</TableCell>
                <TableCell>{row.col4}</TableCell>
                <TableCell>{row.col5}</TableCell>
                <TableCell>{row.col6}</TableCell>
                <TableCell>{row.col7}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function Package() {
  return (
    <>
      <MKTPackage />
      <Gap />
      <GDPackage />
    </>
  );
}

export default Package;
