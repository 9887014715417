import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useMessages() {
  const url = `${API_URL}/setting/message`;
  const { response, loading, post } = useAuthFetch({ url, default: [] });

  function create(message) {
    return post({ url: `${API_URL}/admin/setting/message`, body: { message } });
  }

  return [response, loading, create];
}

export default useMessages;
