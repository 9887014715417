import { API_URL } from "../../constants/EnvConfig";
import { useWalletContext } from "../../context/WalletContext";
import useAuthFetch from "../useAuthFetch";

function useCreateBetTxn() {
  const { selected } = useWalletContext();
  const url = `${API_URL}/betTxn/${selected}`;
  const { response, loading, post } = useAuthFetch({});

  function create(body) {
    return post({ url, body });
  }

  return [response, loading, create];
}

export default useCreateBetTxn;
