import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useBetTxns(wal) {
  const wallet = `${wal}`.toLowerCase();
  const url = `${API_URL}/admin/betTxn/${wallet}`;
  const { response, loading, get, post } = useAuthFetch({});

  function refresh() {
    return get({ url });
  }

  function search(body) {
    return post({ url: `${url}/search`, body });
  }

  return [response, loading, refresh, search];
}

export default useBetTxns;
