import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useBetTxn(wal, id, userId) {
  const wallet = `${wal}`.toLowerCase();
  const url = id ? `${API_URL}/admin/betTxn/${wallet}/${userId}/${id}` : "";
  const { response, loading, get, del } = useAuthFetch({});

  function refresh() {
    return get({ url });
  }

  function voidTxn(orderId) {
    return del({ url: `${url}/${orderId}` });
  }

  return [response, loading, refresh, voidTxn];
}

export default useBetTxn;
