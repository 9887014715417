import Auth from "@aws-amplify/auth";
import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import {
  AUTH_IDENTITY_POOL_ID,
  AUTH_OAUTH_URL,
  AUTH_REDIRECT_URL,
  AUTH_USER_POOL_ID,
  AUTH_USER_POOL_WEB_CLIENT_ID,
  AWS_REGION,
} from "./constants/EnvConfig";
import SharedContextProvider from "./context/SharedContext";
import ThemeContextProvider, { useThemeContext } from "./context/ThemeContext";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Auth.configure({
  Auth: {
    identityPoolId: AUTH_IDENTITY_POOL_ID,
    region: AWS_REGION,
    identityPoolRegion: AWS_REGION,
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: AUTH_OAUTH_URL,
      scope: ["openid", "profile"],
      redirectSignIn: AUTH_REDIRECT_URL,
      redirectSignOut: AUTH_REDIRECT_URL,
      responseType: "code",
    },
  },
});

const Index = () => {
  const { theme } = useThemeContext();

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SharedContextProvider>
              <CssBaseline />
              <Router>
                <App />
              </Router>
            </SharedContextProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

ReactDOM.render(
  <ThemeContextProvider>
    <Index />
  </ThemeContextProvider>,
  document.getElementById("root")
);

serviceWorkerRegistration.validateRegister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
