import { useState } from "react";

const useAnyApi = (oriFn) => {
  const [loading, setLoading] = useState();

  async function invokeApi(fn = oriFn) {
    setLoading(true);
    try {
      const response = fn ? await fn() : await Promise.resolve();
      setLoading(false);
      return response;
    } catch (ex) {
      setLoading(false);
      throw ex;
    }
  }

  return [loading, invokeApi];
};

export default useAnyApi;
