import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";
// import useFetch from "../useFetch";

function useUser(id) {
  const url = id ? `${API_URL}/admin/user/${id}` : "";
  const { response, loading, get, put } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  function update(body) {
    return put({
      url,
      body,
    });
  }

  function toggle() {
    return put({
      url: `${url}/status`,
    });
  }

  return [response, loading, refresh, update, toggle];

  // function refresh() {
  //   return get({ url });
  // }

  // function create(body) {
  //   return post({
  //     body,
  //     url,
  //   });
  // }

  // function toggle() {
  //   return put({
  //     url: `${url}/status`,
  //   });
  // }

  // function search(body) {
  //   return post({
  //     body: {
  //       ...body,
  //       dateOnly: true,
  //     },
  //     url: `${url}/search`,
  //   });
  // }

  // return { response, loading, refresh, search, create, update, toggle };
}

export default useUser;
