import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import CheckboxesGroup from "../components/CheckboxGroup";
import Loader from "../components/Loader";
import Select from "../components/Select";
import TextField from "../components/TextField";
import { BET_TYPES, DRAW_DATES, DRAW_TYPES } from "../constants/BetNow";
import { useAppContext } from "../context/AppContext";
import { useWalletContext } from "../context/WalletContext";
import { filterArray, isEmptyArray, uniqueArray } from "../helpers/Array";
import { calculateBetTxnsAmount } from "../helpers/Bet";
import { formatAmount } from "../helpers/Common";
import {
  MAX_RULE,
  MIN_RULE,
  MKT_3D_RULE,
  MKT_4D_RULE,
  MKT_6D_RULE,
} from "../helpers/Form";
import { tryParse } from "../helpers/String";
import useBetTxn from "../hooks/api/useBetTxn";
import useCreateBetTxn from "../hooks/api/useCreateBetTxn";
import useSettings from "../hooks/api/useSettings";
import useForm from "../hooks/useForm";

const MAX_DRAW_DATE = {
  GD: 7,
  MKT: 4,
};

const defaultForms = Array(15).fill(1);

const Slim9TextInput = styled(TextField)(({ $main }) => ({
  flex: 1, //$main && 1,
  width: "auto", //$main ? "auto" : 55,
  minWidth: 0,
  fontSize: 8,
  margin: 0,

  input: {
    fontSize: 11.5,
    paddingLeft: 4,
    paddingRight: 2,
    paddingTop: 10,
    paddingBottom: 10,
  },

  "fieldset > legend > span": {
    padding: 0,
  },
}));

const Slim9Select = styled(Select).attrs({
  slim9: true,
})({
  marginLeft: 8,
});

const Slim9CardContent = styled(CardContent)({
  padding: 0,

  ":last-child": {
    paddingBottom: 0,
  },
});

const Slim9WrapRow = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  marginBottom: 5,
});

const Slim9Row = styled.div(({ last, date }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: date && "space-between",
  paddingRight: date && 10,
  marginBottom: last && 5,
}));

const NotAvaiContainer = styled.div({
  height: 400,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  fontSize: 60,
});

const NotAvaiContainerText = styled(Typography).attrs({
  component: "h1",
  variant: "h5",
})({
  marginTop: 16,
});

const AMOUNT_PROPS = {
  maxLength: 3,
  noMesssage: true,
  rules: {
    ...MIN_RULE(0.1),
    ...MAX_RULE(999),
  },
};

function Topup() {
  const { setPageTitle, setMessage } = useAppContext();

  const { id = "" } = useParams();
  const [txn, txnLoading] = useBetTxn(id);

  const [t] = useTranslation();
  const history = useHistory();

  const [, betLoading, createBetTxn] = useCreateBetTxn();
  const { selected, amount, userAccount } = useWalletContext();
  const [settings, settingLoading] = useSettings();
  const [, , refreshAccount] = userAccount;

  const { specialDates = [] } = settings || {};

  const form = useForm({
    defaultValues: {
      drawType: BET_TYPES[1]?.value,
    },
  });
  const { handleSubmit, reset, watch } = form;

  const notAvailable = useMemo(() => {
    const start = moment().hours(19);
    const end = moment().hours(20).minutes(30);

    return moment().isSameOrAfter(start) && moment().isSameOrBefore(end);
  }, []);

  const [drawType, betTxns, dDates, dG1 = [], dG2 = []] = watch([
    "drawType",
    "betTxns",
    "drawDates",
    "drawGames",
    "drawGames1",
  ]);

  const dGames = useMemo(
    () => uniqueArray([...dG1, ...dG2].filter((s) => !!s)),
    [dG1, dG2]
  );

  const [drawGames, setDrawGames] = useState([]);

  const loading = txnLoading || betLoading || settingLoading;

  useEffect(() => {
    setPageTitle("Bet Now");
  }, []);

  useEffect(() => {
    if (!txn) {
      reset({
        drawType,
      });
      setDrawGames(DRAW_TYPES[selected][drawType]);
      return;
    }

    const { betTxns, drawType: dT, drawGames: dG } = txn;

    const allGames = DRAW_TYPES[selected][dT];
    const allGames1 = allGames.slice(0, 4).map(({ value }) => value);
    const allGames2 = allGames
      .slice(4, allGames.length)
      .map(({ value }) => value);

    reset({
      betTxns,
      drawType: dT,
      drawGames1: filterArray(allGames1, dG),
      drawGames: filterArray(allGames2, dG),
    });

    setDrawGames(allGames);
  }, [txn, drawType, reset, selected]);

  const defaultDrawDates = useMemo(
    () =>
      Array(12)
        .fill(1)
        .map((item, index) => moment().add(index, "days"))
        .filter((mmt) => {
          const day = mmt.format("ddd").toUpperCase();

          const valid = DRAW_DATES[selected].includes(day);
          return valid;
        })
        .map((mmt) => ({
          text: mmt.format("DD/MM ddd").toUpperCase(),
          value: mmt.startOf("day").valueOf(),
        })),
    [selected]
  );

  const specialDrawDates = useMemo(() => {
    if (selected === "GD") {
      const gd = [...defaultDrawDates];
      gd.splice(8);
      return gd;
    }

    const minDate = moment().startOf("day");
    const maxDate = moment().add(8, "day").endOf("day");

    const withinDates = tryParse(specialDates, [])
      .filter((date) =>
        moment(date).isBetween(minDate, maxDate, undefined, "[]")
      )
      .map((date) => moment(date).startOf("day").valueOf());

    const all = [...defaultDrawDates.map(({ value }) => value), ...withinDates];
    const sorted = all.sort((b, a) => b - a);

    return sorted.map((date) => ({
      text: moment(date).format("DD/MM ddd").toUpperCase(),
      value: moment(date).startOf("day").valueOf(),
    }));
  }, [selected, defaultDrawDates, specialDates]);

  const drawDates = useMemo(() => {
    const dates = specialDrawDates.filter(({ value }) => {
      if (moment(value).isAfter(moment())) {
        return true;
      }

      return moment().isBefore(moment().hours(19));
    });

    const maxDates = [...dates];
    maxDates.splice(MAX_DRAW_DATE[selected]);
    return maxDates;
  }, [selected, specialDrawDates]);

  const totalAmount = useMemo(
    () => calculateBetTxnsAmount(betTxns, dDates?.length * dGames?.length),
    [betTxns, dDates, dGames]
  );

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  function checkWalletAmount() {
    if (totalAmount <= 0) {
      throw new Error("Bet must more than zero");
    }

    if (amount < totalAmount) {
      throw new Error("Insufficient funds");
    }
  }

  function checkBetDetail(data) {
    const { drawDates, drawGames } = data;
    if (isEmptyArray(drawDates)) {
      throw new Error("drawDates is empty");
    }

    if (isEmptyArray(drawGames)) {
      throw new Error("drawGames is empty");
    }
  }

  async function onSubmit({ drawGames1 = [], drawGames = [], ...data }) {
    try {
      const body = {
        drawGames: uniqueArray(
          [...drawGames1, ...drawGames].filter((s) => !!s)
        ),
        ...data,
      };
      checkBetDetail(body);
      checkWalletAmount();

      const { id } = await createBetTxn(body);
      reset();

      history.push(`/bet/${id}`);

      refreshAccount(2500);
    } catch (ex) {
      setMessage(ex);
    }
  }

  function render3DForm(index) {
    const fieldName = `betTxns[${index}]`;
    const inputProps = {
      type: "number",
      InputLabelProps: { shrink: true },
      size: "small",
      margin: "dense",
      fullWidth: false,
    };

    return (
      <Slim9WrapRow>
        <Slim9TextInput
          {...inputProps}
          placeholder={t("Number")}
          name={`${fieldName}.betNumber`}
          maxLength={3}
          noForceNumber
          noMesssage
          rules={{
            ...MKT_3D_RULE,
          }}
          $main
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("A")}
          name={`${fieldName}.big`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("ABC")}
          name={`${fieldName}.small`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("BOX A")}
          name={`${fieldName}.boxBig`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("BOX ABC")}
          name={`${fieldName}.boxSmall`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("IBOX A")}
          name={`${fieldName}.iBoxBig`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("IBOX ABC")}
          name={`${fieldName}.iBoxSmall`}
          {...AMOUNT_PROPS}
        />
      </Slim9WrapRow>
    );
  }

  function render4DForm(index) {
    const fieldName = `betTxns[${index}]`;
    const inputProps = {
      type: "number",
      InputLabelProps: { shrink: true },
      size: "small",
      margin: "dense",
      fullWidth: false,
      variant: "standard",
    };

    return (
      <Slim9WrapRow>
        <Slim9TextInput
          {...inputProps}
          placeholder={t("Number")}
          name={`${fieldName}.betNumber`}
          maxLength={4}
          noForceNumber
          noMesssage
          rules={{
            ...MKT_4D_RULE,
          }}
          $main
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("B")}
          name={`${fieldName}.big`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("S")}
          name={`${fieldName}.small`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("4A")}
          name={`${fieldName}.fourA`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder="BOX B"
          name={`${fieldName}.boxBig`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder="BOX S"
          name={`${fieldName}.boxSmall`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder="IBOX B"
          name={`${fieldName}.iBoxBig`}
          {...AMOUNT_PROPS}
        />
        <Slim9TextInput
          {...inputProps}
          placeholder="IBOX S"
          name={`${fieldName}.iBoxSmall`}
          {...AMOUNT_PROPS}
        />
      </Slim9WrapRow>
    );
  }

  function render6DForm(index) {
    const fieldName = `betTxns[${index}]`;
    const inputProps = {
      type: "tel",
      InputLabelProps: { shrink: true },
      size: "small",
      margin: "dense",
      fullWidth: false,
    };

    return (
      <Slim9WrapRow>
        <Slim9TextInput
          {...inputProps}
          placeholder={t("Number")}
          name={`${fieldName}.betNumber`}
          maxLength={6}
          noForceNumber
          noMesssage
          rules={{
            ...MKT_6D_RULE,
          }}
          $main
        />
        <Slim9TextInput
          {...inputProps}
          placeholder={t("Amount")}
          name={`${fieldName}.big`}
          $main
          {...AMOUNT_PROPS}
        />
      </Slim9WrapRow>
    );
  }

  function renderGdDrawForm() {
    return (
      <Card>
        <Slim9CardContent>
          <Slim9WrapRow>
            <CheckboxesGroup
              noContainer
              name="drawDates"
              slim9
              items={drawDates}
            />
            <CheckboxesGroup
              noContainer
              name="drawGames"
              slim9
              items={drawGames}
            />
            <Slim9Select
              items={BET_TYPES}
              name="drawType"
              displayEmpty={false}
            />
          </Slim9WrapRow>
        </Slim9CardContent>
      </Card>
    );
  }

  function renderMktDrawForm() {
    return (
      <Card>
        <Slim9CardContent>
          <Slim9Row date>
            <CheckboxesGroup
              noContainer
              name="drawDates"
              slim9
              items={drawDates}
            />
          </Slim9Row>
          <Slim9Row>
            <CheckboxesGroup
              noContainer
              name="drawGames1"
              slim9
              items={drawGames.slice(0, 4)}
            />
          </Slim9Row>
          <Slim9Row last>
            <CheckboxesGroup
              noContainer
              name="drawGames"
              slim9
              items={drawGames.slice(4, drawGames.length)}
            />
            <Slim9Select
              items={BET_TYPES}
              name="drawType"
              displayEmpty={false}
            />
          </Slim9Row>
        </Slim9CardContent>
      </Card>
    );
  }

  function renderForm(index) {
    if (drawType === "5D/6D") {
      return render6DForm(index);
    }

    if (drawType === "4D") {
      return render4DForm(index);
    }

    if (drawType === "3D") {
      return render3DForm(index);
    }
  }

  function renderContent() {
    if (!drawType) {
      return null;
    }

    if (loading) {
      return (
        <Grid item xs={12}>
          <Loader paper />
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <Card>
            <Slim9CardContent>
              {defaultForms.map((_, index) => renderForm(index))}
            </Slim9CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth type="submit" variant="contained" color="primary">
            {t("Bet Now")} ({formatAmount(totalAmount)})
          </Button>
        </Grid>
      </>
    );
  }

  function renderUnavaible() {
    return (
      <NotAvaiContainer>
        <ReportProblemIcon color="error" fontSize="inherit" />
        <NotAvaiContainerText>No bet at 7:00pm - 8:30pm</NotAvaiContainerText>
      </NotAvaiContainer>
    );
  }

  if (notAvailable) {
    return renderUnavaible();
  }

  return (
    <FormProvider {...form}>
      <Grid component="form" container {...formProps}>
        <Grid item xs={12}>
          {`${selected}`.toLowerCase() === "gd"
            ? renderGdDrawForm()
            : renderMktDrawForm()}
        </Grid>
        {renderContent()}
      </Grid>
    </FormProvider>
  );
}
export default Topup;
