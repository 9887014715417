import LibButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import WhatsApp from "@material-ui/icons/WhatsApp";
import React from "react";
import useSettings from "../hooks/api/useSettings";

const WA_COLOR = "hsl(173, 86%, 20%)";
const WA1_COLOR = "hsl(173, 86%, 40%)";

const WaButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(WA_COLOR),
    backgroundColor: WA_COLOR,
    "&:hover": {
      backgroundColor: WA1_COLOR,
    },
  },
}))(LibButton);

function HelpButton() {
  const [setting] = useSettings();
  const { custServiceContact, custServiceText } = setting ?? {};

  function whatsappCS() {
    const url = `https://wa.me/${custServiceContact}?text=${encodeURIComponent(
      custServiceText
    )}`;
    window.open(url, "_blank");
  }

  const commProps = {
    variant: "contained",
    fullWidth: true,
    onClick: () => whatsappCS(),
    startIcon: <WhatsApp />,
  };
  return <WaButton {...commProps}>Contact Customer Service</WaButton>;
}

export default HelpButton;
