import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useUserAccount(id) {
  const url = id ? `${API_URL}/admin/user/${id}/wallet` : "";
  const { response, loading, get } = useAuthFetch({ url });

  function refresh(newId) {
    const updated = newId ? `${API_URL}/admin/user/${newId}/wallet` : url;
    return get({ url: updated });
  }

  return [response, loading, refresh];
}

export default useUserAccount;
