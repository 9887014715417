import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useBankAccount(id) {
  const prefix = `${API_URL}/admin/bankAccount`;
  const url = id ? `${prefix}/${id}` : "";
  const { response, loading, get, post, put } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  function list() {
    return get({ url: prefix });
  }

  function create(body) {
    return post({ url: prefix, body });
  }

  function toggle() {
    return put({
      url: `${url}/status`,
    });
  }

  function update(body) {
    return put({
      url,
      body,
    });
  }

  return { response, loading, refresh, list, create, toggle, update };
}

export default useBankAccount;
