import FormControlLabel from "@material-ui/core/FormControlLabel";
import LibSwitch from "@material-ui/core/Switch";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

function Switch({ label, name, rules, defaultValue = "", disabled }) {
  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const props = {
    label,
    inputRef,
    onChange,
    // value: `${value}`,
    checked: value,
    error,
    helperText: message,
    disabled,
  };

  return <FormControlLabel control={<LibSwitch {...props} />} label={label} />;
}

export default Switch;
