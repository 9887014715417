import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { Lightbox } from "react-modal-image";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { PAYMENT_METHOD } from "../constants/Txn";
import { useAppContext } from "../context/AppContext";
import { formatAmount, formatBetAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";
import useWalletTxn from "../hooks/api/useWalletTxn";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
};

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function TxnDetailWallet() {
  const { setPageTitle } = useAppContext();
  const { id = "" } = useParams();
  const [txn, loading] = useWalletTxn(id);

  const [open, setOpen] = useState(false);

  const {
    bonusAmount,
    amount,
    method,
    createdAt,
    status,
    txnId,
    desc,
    receipt,
  } = txn || {};
  const approved = status === "approved";

  const paidTxn = amount < 0;

  useEffect(() => {
    setPageTitle("Wallet Detail");
  }, []);

  function renderBetDetail() {
    const type = approved ? "Payment" : "Cancel";
    return (
      <>
        <Grid item xs={12}>
          <TextField label="Txn Type" readOnly value={type} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Bet Txn No." readOnly value={txnId} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Bet Place at"
            readOnly
            value={formatAll(createdAt)}
          />
        </Grid>
      </>
    );
  }

  function renderBetActions() {
    return (
      <Button
        size="small"
        component={Link}
        color="primary"
        to={`/record/${txnId}`}
      >
        View Bet Record
      </Button>
    );
  }

  function renderTxnDetail() {
    const type = PAYMENT_METHOD[method];

    return (
      <>
        <Grid item xs={12}>
          <TextField label="Txn Type" readOnly value={type} />
        </Grid>
        {desc && (
          <Grid item xs={12}>
            <TextField rows={4} label="Remark" readOnly value={desc} />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField label="Created at" readOnly value={formatAll(createdAt)} />
        </Grid>
      </>
    );
  }

  function renderTxnActions() {
    if (!receipt) {
      return;
    }

    const btnProps = {
      onClick: () => setOpen(true),
    };

    const lgboxProps = {
      medium: receipt,
      large: receipt,
      onClose: () => setOpen(false),
    };

    return (
      <>
        <Button size="small" color="primary" {...btnProps}>
          View Receipt
        </Button>

        {open && <Lightbox {...lgboxProps} />}
      </>
    );
  }

  function renderWalletTxn() {
    if (!txn) {
      return;
    }

    const txnStatus = status === "new" ? "Pending" : capitalize(status);

    return (
      <Grid item xs={12}>
        <Card>
          <Line status={status} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Txn No." readOnly value={id} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Status" readOnly value={txnStatus} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  readOnly
                  value={formatBetAmount(amount)}
                />
              </Grid>
              {bonusAmount && (
                <Grid item xs={12}>
                  <TextField
                    label="Bonus Amount"
                    readOnly
                    value={formatAmount(bonusAmount)}
                  />
                </Grid>
              )}
              {paidTxn ? renderBetDetail() : renderTxnDetail()}
            </Grid>
          </CardContent>
          <CardActions>
            {paidTxn ? renderBetActions() : renderTxnActions()}
          </CardActions>
        </Card>
      </Grid>
    );
  }

  function renderContent() {
    return (
      <Grid container spacing={2}>
        {renderWalletTxn()}
      </Grid>
    );
  }

  return loading ? <Loader paper /> : renderContent();
}
export default TxnDetailWallet;
