import Button from "@material-ui/core/Button";
import LibCard from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import Loader from "../components/Loader";
import LibTextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useMessages from "../hooks/api/useMessages.Admin";
import useForm from "../hooks/useForm";
import Inbox from "../other/Inbox";

const TO_BE_PER = [];

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

const TextField = styled(LibTextField)({
  flex: 1,
  width: "calc(100% - 80px)",
  marginRight: 15,
});

function Message() {
  const { setPageTitle, setMessage } = useAppContext();
  const [messages = [], loading, update] = useMessages();

  const form = useForm();
  const { reset, handleSubmit } = form;

  useEffect(() => {
    setPageTitle("Inbox Message");
  }, []);

  function renderSingle({ key, label, ...rest }) {
    const onSubmit = async ({ message }) => {
      await update(message);
      setMessage(`Message Sent`);
      reset({ message: null });
    };

    const btnProps = {
      color: "primary",
      children: "Send",
      onClick: handleSubmit(onSubmit),
    };

    const inputProps = {
      label,
      name: "message",
      rules: REQUIRED_RULE,
      ...rest,
    };

    return (
      <Grid item xs={12} spacing={2}>
        <TextField {...inputProps} />
        <Button size="small" {...btnProps} />
      </Grid>
    );
  }

  function renderSendMessage() {
    if (!messages) {
      return;
    }

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {renderSingle({
                label: "New Message",
                rows: 8,
              })}
            </Grid>
          </CardContent>
        </Card>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} alignContent="center" justifyContent="center">
      <Grid item md={8} xs={12}>
        {loading ? <Loader paper /> : renderSendMessage()}
      </Grid>
      <Grid item md={8} xs={12}>
        {loading ? <Loader paper /> : <Inbox messages={messages} />}
      </Grid>
    </Grid>
  );
}
export default Message;
