import LibContainer from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import LibMarquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { useWalletContext } from "../context/WalletContext";
import { formatAmount } from "../helpers/Common";
import useSettings from "../hooks/api/useSettings";
import useWindowSize from "../hooks/useWindowSize";
import BottomMenu from "./BottomMenu";
import NavBar from "./NavBar";
import TimeNow from "./TimeNow";

const Root = styled.div({});

const Marquee = styled(LibMarquee).attrs({
  gradient: false,
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginBottom: theme.spacing(1),
  transition: ".3s",
}));

const News = styled(Typography).attrs({
  component: "h6",
  align: "center",
})(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const AppBarFlex = styled.div(({ theme }) => theme.mixins.toolbar);

const Main = styled.main(({ $height, theme }) => ({
  overflow: "auto",
  height: $height,
  display: "flex",
  flexDirection: "column",
}));

const Container = styled(LibContainer).attrs({
  maxWidth: "xs",
  component: "main",
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(10),
  paddingLeft: 0,
  paddingRight: 0,
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));

const WALLET_NAME = {
  MKT: "Wallet 1",
  GD: "Wallet 2",
};

function SharedLayout({ children }) {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const { height } = useWindowSize();

  const { user } = useAuthContext();
  const { id } = user ?? {};

  const { selected, amount, userAccount } = useWalletContext();

  const { pageTitle: title } = useAppContext();
  const [account, loading] = userAccount;
  const { walletGD = 0, walletMKT = 0 } = account ?? {};

  const [setting] = useSettings();
  const { annoucement } = setting ?? {};

  const drawerProps = {
    open,
    setOpen,
  };

  function renderWallet() {
    if (selected) {
      return (
        <Typography component="h6" align="center">
          Wallet: {loading ? "Loading..." : formatAmount(amount)}
        </Typography>
      );
    }

    return (
      <Typography component="h6" align="center">
        {t(WALLET_NAME.MKT)}: {loading ? "Loading..." : formatAmount(walletMKT)}
        <br />
        {t(WALLET_NAME.GD)}: {loading ? "Loading..." : formatAmount(walletGD)}
      </Typography>
    );
  }

  return (
    <Root>
      <NavBar {...drawerProps} title={title} />
      <Main $height={height}>
        <AppBarFlex />
        <Container>
          {annoucement && (
            <Marquee>
              <News>{annoucement}</News>
            </Marquee>
          )}
          <Typography component="h6" align="center">
            {t("User")}: {id}
          </Typography>
          {renderWallet()}
          <TimeNow />

          {children}
        </Container>
      </Main>
      <BottomMenu />
    </Root>
  );
}

export default SharedLayout;
