import { API_URL } from "../../constants/EnvConfig";
import { useAuthContext } from "../../context/AuthContext";
import useAuthFetch from "../useAuthFetch";

function useUserAccount() {
  const { user } = useAuthContext();
  const { isAdmin } = user ?? {};

  const url = isAdmin ? "" : `${API_URL}/user`;
  const { response, loading, get } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  return [response, loading, refresh];
}

export default useUserAccount;
