import LibAppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LibToolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { useAdminContext } from "../context/AdminContext";
import { useAuthContext } from "../context/AuthContext";
import { formatAmount } from "../helpers/Common";

const Toolbar = styled(LibToolbar)({
  paddingRight: 24,
});

const MenuButton = styled(IconButton).attrs({
  edge: "start",
  color: "inherit",
})({});

const MainTitle = styled(Typography).attrs({
  component: "h1",
  variant: "h6",
  color: "inherit",
  noWrap: true,
})({
  flexGrow: 1,
});

const AppBar = styled(LibAppBar)({
  transition: ".3s",
});

function NavBar({ title, setOpen }) {
  const [t] = useTranslation();

  const { signOut } = useAuthContext();

  const history = useHistory();
  const { pathname } = useLocation();
  const hasHistory = useMemo(() => pathname.split("/").length > 4, [pathname]);

  const { account, loading, refresh } = useAdminContext();
  const { id, credit } = account ?? {};

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleBack = () => {
    try {
      console.log("me", history);

      if (!hasHistory || history.length <= 1) {
        throw new Error("No Route");
      }

      history.goBack();
    } catch (ex) {
      history.replace("/");
    }
  };

  return (
    <AppBar position="absolute">
      <Toolbar>
        <MenuButton onClick={handleDrawerOpen}>
          <MenuIcon />
        </MenuButton>

        {hasHistory && (
          <MenuButton onClick={handleBack}>
            <ArrowBackIcon />
          </MenuButton>
        )}

        <MainTitle>{t(title)}</MainTitle>

        <Button color="inherit" onClick={() => refresh()}>
          {loading ? "Loading..." : `${id}: ${formatAmount(credit || 0)}`}
        </Button>
        <IconButton color="inherit" onClick={signOut}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
