import React from "react";
import AdminContextProvider from "./AdminContext";
import AppContextProvider from "./AppContext";
import AuthContextProvider from "./AuthContext";
import WalletContextProvider from "./WalletContext";

function SharedContext({ children }) {
  return (
    <AppContextProvider>
      <AuthContextProvider>
        <AdminContextProvider>
          <WalletContextProvider>{children}</WalletContextProvider>
        </AdminContextProvider>
      </AuthContextProvider>
    </AppContextProvider>
  );
}

export default SharedContext;
