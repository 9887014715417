import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FilterChips from "../components/FilterChips";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { PAYMENT_METHODS } from "../constants/Withdraw";
import { useAppContext } from "../context/AppContext";
import { formatAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import { numberWithCommas } from "../helpers/String";
import useWithdrawTxns from "../hooks/api/useWithdrawTxns.Admin";
import useMount from "../hooks/useMount";
import useSearchParams from "../hooks/useSearchParams";
import Filter from "./Filter";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
};

const WALLETS = [
  {
    text: "Wallet 1",
    value: "wallet.mkt",
  },
  {
    text: "Wallet 2",
    value: "wallet.gd",
  },
  {
    text: "Commission",
    value: "fromAccount.COM",
  },
  {
    text: "Winning",
    value: "fromAccount.WIN",
  },
  {
    text: "Pending",
    value: "status.new",
  },
  {
    text: "Approved",
    value: "status.approved",
  },
  {
    text: "Rejected",
    value: "status.rejected",
  },
  // {
  //   text: "Today",
  //   value: "date.today",
  // },
];

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS_COLOR[status],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#ffc107" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({
  paddingLeft: 8,
  paddingRight: 8,
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

const TODAY_START = moment().startOf("day");
const TODAY_END = moment().endOf("day");

const DEFAULT_PARAMS = {
  createdAtStart: TODAY_START.toJSON(),
  createdAtEnd: TODAY_END.toJSON(),
};

function StatusHeader({ label }) {
  return (
    <StatusContent>
      <Status head>{label}</Status>
    </StatusContent>
  );
}

function StatusColumn({ item }) {
  const { id, userId, status, wallet } = item;

  return (
    <StatusContent>
      <Dot status={status} />

      <StatusText to={`/admin/txn/withdraw/${wallet}/${userId}/${id}`}>
        {id}
      </StatusText>
    </StatusContent>
  );
}

function Summary({ loading, response }) {
  const summary = useMemo(() => {
    const approved = response.filter((s) => s.status === "approved");
    const totalAmount = approved.reduce((prev, { amount }) => prev + amount, 0);

    return {
      totalAmount: numberWithCommas(totalAmount),
      totalCount: approved.length,
    };
  }, [response]);

  if (loading) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SummaryCard>
            <Value $primary>{summary?.totalAmount}</Value>
            <Title>Total Amount ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SummaryCard>
            <Value $primary>{summary?.totalCount}</Value>
            <Title>Total Approved</Title>
          </SummaryCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

function WithdrawTxns() {
  const { setPageTitle } = useAppContext();

  const [params, setParams] = useSearchParams();

  const [selectedWallets, setSelectedWallets] = useState([
    "wallet.mkt",
    "wallet.gd",
    "status.new",
    // "date.today",
  ]);

  const [txns1 = [], loading1, refresh1, search1] = useWithdrawTxns("mkt");
  const [txns2 = [], loading2, refresh2, search2] = useWithdrawTxns("gd");
  const loading = loading1 || loading2;

  const [showFilter, setShowFilter] = useState();

  const txns = useMemo(() => {
    const t1 = txns1 ?? [];
    const t2 = txns2 ?? [];

    let all = [...t1, ...t2];

    if (selectedWallets.length === 0) {
      return all;
    }

    const wallets = selectedWallets
      .filter((s) => s.startsWith("wallet"))
      .map((s) => s.split(".")[1]);

    if (wallets.length === 0 || wallets.length === 2) {
      return all;
    }

    if (selectedWallets.includes("wallet.mkt")) {
      return t1;
    }

    if (selectedWallets.includes("wallet.gd")) {
      return t2;
    }
  }, [selectedWallets, txns1, txns2]);

  const response = useMemo(() => {
    let all = [...txns];

    const statuses = selectedWallets
      .filter((s) => s.startsWith("status"))
      .map((s) => s.split(".")[1]);

    if (statuses.length !== 0 && statuses.length !== 3) {
      all = all.filter(({ status }) => statuses.includes(status));
    }

    const fromAccounts = selectedWallets
      .filter((s) => s.startsWith("fromAccount"))
      .map((s) => s.split(".")[1]);

    if (fromAccounts.length !== 0 && fromAccounts.length !== 2) {
      all = all.filter(({ fromAccount }) => fromAccounts.includes(fromAccount));
    }

    return all;
  }, [selectedWallets, txns]);

  const refresh = useCallback(() => {
    setParams();
    refresh1();
    refresh2();
  }, [refresh1, refresh2, setParams]);

  const search = useCallback(
    (data) => {
      setParams(data);
      search1(data);
      search2(data);
    },
    [search1, search2, setParams]
  );

  useMount(() => {
    setPageTitle(`Withdraw Transactions`);
  });

  useMount(() => {
    search(params ?? DEFAULT_PARAMS);
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Txn No.",
      custom: (item) => <StatusColumn item={item} />,
      customHeader: (label) => <StatusHeader label={label} />,
    },
    {
      id: "userId",
      numeric: false,
      label: "Username",
    },
    {
      id: "fromAccount",
      numeric: false,
      label: "Method",
      format: ({ fromAccount }) => PAYMENT_METHODS[fromAccount] ?? fromAccount,
    },
    {
      id: "amount",
      numeric: false,
      label: "Amount",
      format: ({ amount }) => formatAmount(amount),
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      if (!data) {
        await refresh();
      } else {
        await search(data);
      }
    },
  };
  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Transactions",
    defaultSort: "createdAt",
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      <FilterChips
        items={WALLETS}
        onChange={setSelectedWallets}
        selected={selectedWallets}
      />

      <Grid container spacing={2}>
        <Summary response={response} loading={loading} />
        <Grid item xs={12}>
          {loading ? <Loader paper /> : <Table {...tableProps} />}
        </Grid>
      </Grid>
      <Filter {...filterProps} />
    </>
  );
}

export default WithdrawTxns;
