import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useUserWinningTxns(wal, userId) {
  const wallet = `${wal}`.toLowerCase();
  const url = `${API_URL}/admin/winningTxn/${wallet}/${userId}`;
  const { response, loading, get } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  return [response, loading, refresh];
}

export default useUserWinningTxns;
