import LibTextField from "@material-ui/core/TextField";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import ReactPhoneInput from "react-phone-input-material-ui";
import { isEmpty } from "../helpers/String";

const DEFAULT_COUNTRY = "my";
const DEFAULT_DIALCODE = "+60";

function PhoneField({
  label,
  name,
  rules,
  defaultValue = "",
  disabled,

  onBlur,
}) {
  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps = {
    label,

    error,
    helperText: message,
    variant: "outlined",
    fullWidth: true,
  };

  const phoneProps = {
    inputRef,
    specialLabel: "",
    country: DEFAULT_COUNTRY,
    placeholder: "+60123456789",
    onlyCountries: [DEFAULT_COUNTRY],
    autoFormat: false,
    onBlur,
    disabled,
    value: `${isEmpty(value) ? DEFAULT_DIALCODE : value}`,
    onChange: (value) => onChange(`+${value}`),
    inputProps,
    component: LibTextField,
  };

  return <ReactPhoneInput {...phoneProps} />;
}

export default PhoneField;
