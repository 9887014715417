import { useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import useFetch from "./useFetch";

const useAuthFetch = (options, format) => {
  const { url, ...restOpt } = options ?? {};
  const { userToken: token, signOut, refreshToken } = useAuthContext();
  const authOps = { token, refreshToken };

  const { get, post, put, del, patch, ...rest } = useFetch(
    { ...restOpt, ...authOps },
    format
  );
  const { error } = rest ?? {};

  useEffect(() => {
    if (!token) {
      return;
    }

    get({ url, ...authOps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const { message } = error;
    if (message === "The incoming token has expired") {
      signOut();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    get: (ops) => get({ ...ops, ...authOps }),
    post: (ops) => post({ ...ops, ...authOps }),
    patch: (ops) => patch({ ...ops, ...authOps }),
    put: (ops) => put({ ...ops, ...authOps }),
    del: (ops) => del({ ...ops, ...authOps }),

    ...rest,
  };
};

export default useAuthFetch;
