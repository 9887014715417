import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import Confirmation from "../components/Confirmation";
import TextField from "../components/TextField";
import { REQUIRED_RULE } from "../helpers/Form";
import useForm from "../hooks/useForm";

function Reject({ id, open, onClose, onConfirm }) {
  const form = useForm();
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset();
  }, []);

  function renderRejectReason() {
    return (
      <TextField
        label={"Reason"}
        rows={4}
        name="desc"
        rules={{
          ...REQUIRED_RULE,
        }}
      />
    );
  }

  const props = {
    title: "Reject Win Number",
    open,
    onClose,
    desc: `Are you sure want to reject ${id}?`,
    children: renderRejectReason(),
    onConfirm: handleSubmit(({ desc }) => {
      onConfirm(desc);
    }),
  };

  return (
    <FormProvider {...form}>
      <Confirmation {...props} />
    </FormProvider>
  );
}

export default Reject;
