import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { formatBetAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import useWinningTxns from "../hooks/api/useWinningTxns";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
};

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS_COLOR[status],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
}));

function TxnComm({ txnRef }) {
  const [t] = useTranslation();

  const history = useHistory();

  const [searched, setSearched] = useState();

  const [items, loading, , search] = useWinningTxns();

  useImperativeHandle(txnRef, () => ({
    submit: ({ from, to }) => {
      setSearched(true);
      search({ from, to });
    },
  }));

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: t("Txn No"),
      customHeader: renderStatusHeader,
      custom: renderStatus,
    },
    {
      id: "createdAt",
      numeric: false,
      label: t("Date/Time"),
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  const tableProps = {
    headCells,
    items,
    rowsPerPage: 10,
    uniqueId: "id",
    title: t("Winning Transactions"),
    defaultOrder: "desc",
    defaultSort: "createdAt",
    noEmptyRows: true,
    onSelect: ({ id }) => {
      history.push(`/txn/win/${id}`);
    },
  };

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status head>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { id, status, strikeAmount, amount } = item;

    return (
      <StatusContent>
        <Dot status={status} />
        <Status>
          {id} ({formatBetAmount(amount ?? strikeAmount)})
        </Status>
      </StatusContent>
    );
  }

  if (!searched) {
    return null;
  }

  return loading ? <Loader paper /> : <Table {...tableProps} />;
}
export default TxnComm;
