import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import PasswordPolicy from "../components/PasswordPolicy";
import TextField, { PureTextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { formatAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { PASSWORD_POLICY, REQUIRED_RULE } from "../helpers/Form";
import { capitalize } from "../helpers/String";
import useUser from "../hooks/api/useUser";
import useForm from "../hooks/useForm";
import Toggle from "../users/Toggle";
import Users from "./Users";

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

const UsernameInput = styled(TextField).attrs({
  name: "id",
  label: "Username",
  rules: REQUIRED_RULE,
  readOnly: true,
})({});

const StatusInput = styled(PureTextField).attrs({
  label: "Status",
  readOnly: true,
})({});

const CreatedAtInput = styled(PureTextField).attrs({
  label: "Created At",
  readOnly: true,
})({});

const UpdatedAtInput = styled(PureTextField).attrs({
  label: "Updated At",
  readOnly: true,
})({});

const PhoneInput = styled(TextField).attrs({
  name: "mobile",
  label: "Mobile",
  rules: REQUIRED_RULE,
  readOnly: true,
})({});

const ReferenceByInput = styled(TextField).attrs({
  name: "referenceBy",
  label: "Referrer",
  readOnly: true,
})({});

const PasswordInput = styled(TextField).attrs({
  name: "password",
  label: "Password",
  type: "password",
  autoComplete: "new-password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

const WalletMKTInput = styled(PureTextField).attrs({
  label: "Wallet 1",
  readOnly: true,
})({});

const WalletGDInput = styled(PureTextField).attrs({
  label: "Wallet 2",
  readOnly: true,
})({});

const CommInput = styled(PureTextField).attrs({
  label: "Commission",
  readOnly: true,
})({});

const WinMKTInput = styled(PureTextField).attrs({
  label: "Winning 1",
  readOnly: true,
})({});
const WinGDInput = styled(PureTextField).attrs({
  label: "Winning 2",
  readOnly: true,
})({});

function Detail({ user }) {
  const {
    updatedAt,
    createdAt,
    walletMKT,
    walletGD,
    commission,
    winningMKT,
    winningGD,
  } = user || {};

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WalletMKTInput value={formatAmount(walletMKT)} />
          </Grid>
          <Grid item xs={12}>
            <WalletGDInput value={formatAmount(walletGD)} />
          </Grid>
          <Grid item xs={12}>
            <CommInput value={formatAmount(commission)} />
          </Grid>
          <Grid item xs={12}>
            <WinMKTInput value={formatAmount(winningMKT)} />
          </Grid>
          <Grid item xs={12}>
            <WinGDInput value={formatAmount(winningGD)} />
          </Grid>
          <Grid item xs={12}>
            <CreatedAtInput value={formatAll(createdAt)} />
          </Grid>
          <Grid item xs={12}>
            <UpdatedAtInput value={formatAll(updatedAt)} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Password({ user, setToggleItem, update }) {
  const { setMessage } = useAppContext();

  const form = useForm();
  const { watch } = form;
  const { password } = watch();

  const { status } = user || {};

  useEffect(() => {
    form.reset(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const policyProps = {
    value: password,
    isSubmitted: false,
  };

  async function onPwdSubmit({ password }) {
    try {
      await update({ password });
      setMessage("User updated");
    } catch (ex) {
      setMessage({ message: "Update user fail" });
    }
  }

  return (
    <FormProvider {...form}>
      <Card component="form" onSubmit={form.handleSubmit(onPwdSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UsernameInput />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ReferenceByInput />
            </Grid>
            <Grid item xs={12} lg={6}>
              <StatusInput value={capitalize(status)} />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput />
              <PasswordPolicy {...policyProps} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button type="submit" color="primary">
            Update Password
          </Button>

          <Button
            color="secondary"
            onClick={(e) => {
              setToggleItem(user);
            }}
          >
            {status === "active" ? "Disable " : "Enable "} User
          </Button>
        </CardActions>
      </Card>
    </FormProvider>
  );
}

function UserDetail() {
  const { setPageTitle } = useAppContext();
  const { id = "" } = useParams();
  const [user, loading, refresh, update] = useUser(id);
  const [toggleItem, setToggleItem] = useState();

  useEffect(() => {
    setPageTitle("User Detail");
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {loading ? (
            <Loader paper />
          ) : (
            <Password
              user={user}
              setToggleItem={setToggleItem}
              update={update}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {loading ? <Loader paper /> : <Detail user={user} />}
        </Grid>
        <Grid item xs={12}>
          <Users />
        </Grid>
      </Grid>
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}
export default UserDetail;
