export function calculateBetTxnsAmount(betTxns = [], multiple = 0) {
  multiple = isNaN(multiple) ? 0 : multiple;
  return (
    betTxns.reduce((prev, txn) => prev + calculateBetAmount(txn ?? {}), 0) *
    multiple
  );
}

function calculateBetAmount(betTxn) {
  const {
    betNumber = "",
    big = 0,
    small = 0,
    fourA = 0,
    boxBig = 0,
    boxSmall = 0,
    iBoxBig = 0,
    iBoxSmall = 0,
  } = betTxn;

  if (!betNumber) {
    return 0;
  }

  const boxNumber = permute(betNumber).length;
  const boxAmount =
    boxNumber * tryParseNumber(boxBig) + boxNumber * tryParseNumber(boxSmall);

  return (
    tryParseNumber(big) +
    tryParseNumber(small) +
    tryParseNumber(fourA) +
    tryParseNumber(iBoxBig) +
    tryParseNumber(iBoxSmall) +
    tryParseNumber(boxAmount)
  );
}

function tryParseNumber(num = 0) {
  return isNaN(+num) ? 0 : +num;
}

function permute(str = "") {
  if (!!str.length && str.length < 2) {
    return str;
  }
  const arr = [];
  for (let i = 0; i < str.length; i++) {
    let char = str[i];
    if (str.indexOf(char) !== i) continue;
    let remainder = str.slice(0, i) + str.slice(i + 1, str.length);
    for (let permutation of permute(remainder)) {
      arr.push(char + permutation);
    }
  }
  return arr;
}
