import React from "react";
import Confirmation from "../components/Confirmation";

function Accept({ id, open, onClose, onConfirm }) {
  const props = {
    title: "Approve Topup",
    open,
    onClose,
    desc: `Are you sure want to approve ${id}?`,
    onConfirm,
  };

  return <Confirmation {...props} />;
}

export default Accept;
