import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";
// import useFetch from "../useFetch";

function useUsers() {
  const url = `${API_URL}/admin/user`;
  const { response, loading, get, post } = useAuthFetch({});

  function refresh() {
    return get({ url });
  }

  function search(body) {
    return post({ url: `${url}/search`, body });
  }

  return [response, loading, refresh, search];

  // function refresh() {
  //   return get({ url });
  // }

  // function create(body) {
  //   return post({
  //     body,
  //     url,
  //   });
  // }

  // function update(body) {
  //   return put({
  //     body,
  //     url,
  //   });
  // }

  // function toggle() {
  //   return put({
  //     url: `${url}/status`,
  //   });
  // }

  // function search(body) {
  //   return post({
  //     body: {
  //       ...body,
  //       dateOnly: true,
  //     },
  //     url: `${url}/search`,
  //   });
  // }

  // return { response, loading, refresh, search, create, update, toggle };
}

export default useUsers;
