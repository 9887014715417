import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import React, { useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import { formatStandard } from "../helpers/DateTime";
import { capitalize, numberWithCommas } from "../helpers/String";
import useAdminAccount from "../hooks/api/useAdminAccount.Admin";
import useAdminAccounts from "../hooks/api/useAdminAccounts.Admin";
import useMount from "../hooks/useMount";
import Toggle from "./Toggle";

const STATUS = {
  active: green[500],
  inactive: red[900],
  [undefined]: amber[500],
};

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: 1,
});

const Dot = styled.div(({ $status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS[$status],
  marginRight: theme.spacing(1),
}));

const Gap = styled.div({
  width: 10,
});

const Column1 = styled.div({
  width: "80%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: 1,
});

const Column2 = styled.div({
  width: 150,
});

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

// const OffIcon = styled(ToggleOffIcon)(({ $status }) => ({
//   color: STATUS[$status],
// }));

// const OnIcon = styled(ToggleOnIcon)(({ $status }) => ({
//   color: STATUS[$status],
// }));

const UserAccount = ({ id, status }) => {
  const active = status === "active";
  const [account, loading] = useAdminAccount(id);
  const { credit } = account ?? {};

  return (
    <StatusContent>
      <Column1>
        <Dot $status={status} />

        <StatusText to={`/admin/operator/${id}`}>{id}</StatusText>

        {/* <IconButton
          title={active ? "Disable Account" : "Enable Account"}
          size="small"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // setToggleItem(item);
          }}
        >
          {active ? <OnIcon $status={status} /> : <OffIcon $status={status} />}
        </IconButton> */}
      </Column1>
      <Gap />
      <Column2>{loading ? "..." : numberWithCommas(credit ?? 0)}</Column2>
    </StatusContent>
  );
};

function AdminAccount() {
  const { setPageTitle } = useAppContext();
  const [toggleItem, setToggleItem] = useState();
  const history = useHistory();

  const { url } = useRouteMatch();

  const [response, loading, list] = useAdminAccounts();

  useMount(() => {
    list();
    setPageTitle("Admin Account");
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Username",
      // custom: renderStatus,

      component: (props) => <UserAccount {...props} />,
      customHeader: renderStatusHeader,
    },
    {
      id: "role",
      numeric: false,
      label: "Role",
      format: ({ role }) => capitalize(role),
    },
    // {
    //   id: "credit",
    //   numeric: false,
    //   label: "Credit",
    //   // format: ({ credit }) => numberWithCommas(credit),

    //   component: (props) => <UserCredit {...props} />,
    // },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Column1>
          <Status head>{label}</Status>
        </Column1>
        <Gap />
        <Column2>Credit</Column2>
      </StatusContent>
    );
  }

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Admin Accounts",
    defaultSort: "createdAt",
    onAdd: () => {
      history.push(`${url}add`);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => list()}
      />
    </>
  );
}

export default AdminAccount;
