import Container from "@material-ui/core/Container";
import React, { useEffect } from "react";
import Loader from "../components/Loader";
import { useAuthContext } from "../context/AuthContext";

function Logout() {
  const { signOut } = useAuthContext();

  useEffect(() => {
    signOut();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Loader />
    </Container>
  );
}

export default Logout;
