import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import Copyright from "../components/Copyright";
import Loader from "../components/Loader";
import { useAppContext } from "../context/AppContext";
import useMessages from "../hooks/api/useMessages.Admin";
import Inbox from "./Inbox";
import Package from "./Package";
import ChangePassword from "./Password";

function Other() {
  const { setPageTitle } = useAppContext();
  const [messages = [], loading] = useMessages();

  useEffect(() => {
    setPageTitle("Others");
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ChangePassword />
      </Grid>
      <Grid item xs={12}>
        <Package />
      </Grid>
      <Grid item xs={12}>
        {loading ? <Loader paper /> : <Inbox messages={messages} />}
      </Grid>
      <Grid item spacing={2} xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
}
export default Other;
