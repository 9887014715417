import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import useCreateAdminCredit from "../hooks/api/useCreateAdminCredit.Admin";

const CreditInput = styled(TextField).attrs({
  name: "credit",
  label: "Credit",
})({});

function AddCredit({ item, onAddCredit, onClose }) {
  const { id } = item ?? {};

  const form = useForm();
  const { setMessage } = useAppContext();
  const { handleSubmit, reset } = form;

  const [, loading, create] = useCreateAdminCredit();

  async function onSubmit({ credit }) {
    try {
      await create({ credit, adminId: id });
      onAddCredit();
      reset();
      onClose();
    } catch (ex) {
      const { message = "Unable to add credit " } = ex;
      setMessage({ message });
    }
  }

  function renderContent() {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add Credit</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CreditInput />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    );
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={!!item} onClose={onClose}>
      {loading ? <Loader /> : renderContent()}
    </Dialog>
  );
}

export default AddCredit;
