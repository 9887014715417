import React from "react";
import { useHistory } from "react-router-dom";
import QrCodeModal from "../components/QrCodeModal";
import { WEB_URL } from "../constants/EnvConfig";
import { useAuthContext } from "../context/AuthContext";

function QrCode() {
  const history = useHistory();
  const { user } = useAuthContext();
  const { id } = user;

  function onClose() {
    history.goBack();
  }

  return (
    <QrCodeModal value={`${WEB_URL}/register/${id}`} open onClose={onClose} />
  );
}

export default QrCode;
