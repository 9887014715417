export const {
  REACT_APP_API_URL: API_URL,
  REACT_APP_WEB_URL: WEB_URL,
  REACT_APP_AWS_REGION: AWS_REGION,
  REACT_APP_AUTH_USER_POOL_ID: AUTH_USER_POOL_ID,
  REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID: AUTH_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AUTH_OAUTH_URL: AUTH_OAUTH_URL,
  REACT_APP_AUTH_REDIRECT_URL: AUTH_REDIRECT_URL,
  REACT_APP_AUTH_IDENTITY_POOL_ID: AUTH_IDENTITY_POOL_ID,
  REACT_APP_CLIENT_ID: CLIENT_ID,
} = process.env;

export const CURRENCY = "$";
