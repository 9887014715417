import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { STATUS_COLOR } from "../constants/Txn";
import { useAppContext } from "../context/AppContext";
import { formatBetAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";
import useCommTxn from "../hooks/api/useCommTxn.Admin";

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function CommTxnDetail() {
  const { setPageTitle } = useAppContext();
  const { id = "", userId } = useParams();
  const [txn, loading, refresh] = useCommTxn(id, userId);

  const {
    amount,
    downline,
    createdAt,
    status,
    desc,
    walletTxnId,
    withdrawTxnId,
  } = txn || {};

  useEffect(() => {
    setPageTitle("Commission Detail");
  }, []);

  useEffect(() => {
    refresh();
  }, [id, userId]);

  function renderTxnDetail() {
    const txnType = amount > 0 ? "Top up" : "Withdraw";

    return (
      <>
        <Grid item xs={12}>
          <TextField label="Txn Type" readOnly value={txnType} />
        </Grid>
        {downline && (
          <Grid item xs={12}>
            <TextField label="Downline" readOnly value={downline} />
          </Grid>
        )}
        {desc && (
          <Grid item xs={12}>
            <TextField rows={4} label="Remark" readOnly value={desc} />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField label="Created at" readOnly value={formatAll(createdAt)} />
        </Grid>
      </>
    );
  }

  function renderTxnActions() {
    const wallet1 = `${walletTxnId}`.startsWith("E") ? "mkt" : "gd";
    const wallet2 = `${withdrawTxnId}`.startsWith("I") ? "mkt" : "gd";

    return (
      <>
        {walletTxnId && (
          <Button
            size="small"
            color="primary"
            component={Link}
            to={`/admin/txn/wallet/${wallet1}/${downline}/${walletTxnId}`}
          >
            View Top up
          </Button>
        )}
        {withdrawTxnId && (
          <Button
            size="small"
            color="primary"
            component={Link}
            to={`/admin/txn/withdraw/${wallet2}/${userId}/${withdrawTxnId}`}
          >
            View Withdraw
          </Button>
        )}
      </>
    );
  }

  function renderCommTxn() {
    if (!txn) {
      return;
    }

    return (
      <Card>
        <Line status={status} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Txn No." readOnly value={id} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Username" readOnly value={userId} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Status" readOnly value={capitalize(status)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Amount"
                readOnly
                value={formatBetAmount(amount)}
              />
            </Grid>
            {renderTxnDetail()}
          </Grid>
        </CardContent>
        <CardActions>{renderTxnActions()}</CardActions>
      </Card>
    );
  }

  function renderContent() {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid item md={6} xs={12}>
          {loading ? <Loader paper /> : renderCommTxn()}
        </Grid>
      </Grid>
    );
  }

  return renderContent();
}

export default CommTxnDetail;
