import red from "@material-ui/core/colors/red";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LibTextField from "@material-ui/core/TextField";
import Assignment from "@material-ui/icons/Assignment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import WhatsApp from "@material-ui/icons/WhatsApp";
import { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { isEmpty } from "../helpers/String";

const AppTextField = styled(LibTextField)(({ $errorText }) => ({
  "input, textarea": {
    color: $errorText ? red[400] : "",
  },
}));

const UPDATED_TYPE = {
  whatsapp: "tel",
};

const TextField = ({ name, rules, defaultValue, ...props }) => {
  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps = {
    ...props,
    inputRef,
    onChange,
    value,
    message,
    error,
  };

  return <PureTextField {...inputProps} />;
};

export const PureTextField = ({
  label,
  type: oType = "text",
  rules,
  defaultValue = "",
  disabled,
  readOnly,
  maxLength,
  autoComplete,
  noMesssage,
  noForceNumber,
  noDecimalPoint,
  endIcon,

  onBlur,
  onIconClick,

  inputRef,
  onChange,
  value,
  error,
  message,

  rows = 1,
  errorText,

  ...rest
}) => {
  const pType = UPDATED_TYPE[oType] ?? oType;
  const [type, setType] = useState(pType);
  const [showPassword, setShowPassword] = useState(false);

  const props = {
    ...rest,
    label,
    type,
    inputRef,
    onChange,
    onBlur: onInputBlur,
    value: `${isEmpty(value) ? "" : value}`,
    error,
    helperText: !noMesssage && message,
    disabled,
    variant: "outlined",
    fullWidth: true,
    multiline: rows > 1,
    rows,
    onInput: (e) => {
      if (!maxLength) {
        return;
      }

      e.target.value = `${e.target.value}`.slice(0, maxLength);
    },
    $errorText: errorText,
  };

  const InputProps = {
    readOnly,
    endAdornment: endIcon,
  };

  const inputProps = { maxLength, autoComplete };

  const pwdProps = {
    ...InputProps,
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            onIconClick && onIconClick();
            setShowPassword((s) => !s);
            setType(showPassword ? "password" : "text");
          }}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const waProps = {
    ...InputProps,
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            onIconClick && onIconClick();
          }}
        >
          <WhatsApp htmlColor="#128c7e" />
        </IconButton>
      </InputAdornment>
    ),
  };

  const copyProps = {
    ...InputProps,
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            onIconClick && onIconClick();
          }}
        >
          <Assignment />
        </IconButton>
      </InputAdornment>
    ),
  };

  const mutliFieldsProps = {
    password: { ...props, inputProps, InputProps: pwdProps },
    whatsapp: { ...props, inputProps, InputProps: waProps },
    copy: { ...props, inputProps, InputProps: copyProps },
  };

  const textFieldProps = mutliFieldsProps[oType] ?? {
    ...props,
    inputProps,
    InputProps,
  };

  function onInputBlur(e) {
    if ((pType !== "tel" && pType !== "number") || noForceNumber) {
      onBlur && onBlur(e);
      return;
    }

    if (noDecimalPoint) {
      return forceInteger(e);
    }

    const regEx = /^-?\d+(\.\d{0,2})?$/;
    const valid = new RegExp(regEx).test(value);

    const updated = valid ? +value : "";
    onChange(updated);
    onBlur && onBlur(e);
  }

  function forceInteger(e) {
    const valid = Number.parseInt(+value);
    const updated = isNaN(valid) ? "" : valid;
    onChange(updated);
    onBlur && onBlur(e);
  }

  return <AppTextField {...textFieldProps} />;
};

export default TextField;
