import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../components/Button";
import Datepicker from "../components/Datepicker";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import { formatStandard } from "../helpers/DateTime";
import { REQUIRED_RULE } from "../helpers/Form";
import useDownlines from "../hooks/api/useDownlines";
import useForm from "../hooks/useForm";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: status === "inactive" ? red[900] : green[500],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
}));

const TODAY = moment().toJSON();

function Downline() {
  const { setPageTitle, setMessage } = useAppContext();
  const form = useForm({
    defaultValues: {
      from: TODAY,
      to: TODAY,
    },
  });
  const [t] = useTranslation();

  const [searched, setSearched] = useState();
  const { watch, clearErrors, reset, handleSubmit } = form;
  const [items, loading, , search] = useDownlines();

  const [formValue, toValue] = watch(["from", "to"]);

  useEffect(() => {
    setPageTitle("Downline Info");
  }, []);

  const headCells = [
    {
      id: "downlineId",
      numeric: false,
      label: t("User ID"),
      customHeader: renderStatusHeader,
      custom: renderStatus,
    },
    {
      id: "createdAt",
      numeric: true,
      label: t("Join At"),
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  const tableProps = {
    headCells,
    items,
    rowsPerPage: 10,
    uniqueId: "downlineId",
    title: t("Downline"),
    defaultOrder: "desc",
    defaultSort: "createdAt",
    noEmptyRows: true,
  };

  function onReset() {
    clearErrors();

    const defaultValues = {
      from: TODAY,
      to: TODAY,
    };

    reset(defaultValues);
    search(defaultValues);
  }

  async function onSubmit(data) {
    try {
      setSearched(true);
      search(data);
    } catch (ex) {
      setMessage(ex);
    }
  }

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status head>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { downlineId, status } = item;

    return (
      <StatusContent>
        <Dot status={status} />
        <Status>{downlineId}</Status>
      </StatusContent>
    );
  }
  function renderSearchBox() {
    return (
      <FormProvider {...form}>
        <Card component="form" {...formProps}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Datepicker
                  label={t("From Date")}
                  name="from"
                  maxDate={toValue}
                  rules={{
                    ...REQUIRED_RULE,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Datepicker
                  label={t("To Date")}
                  name="to"
                  minDate={formValue}
                  rules={{
                    ...REQUIRED_RULE,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              {t("Search")}
            </Button>
            <Button onClick={onReset} color="secondary">
              {t("Reset")}
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  function renderTable() {
    return <Table {...tableProps} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderSearchBox()}
      </Grid>
      <Grid item xs={12}>
        {!searched ? null : loading ? <Loader paper /> : renderTable()}
      </Grid>
    </Grid>
  );
}
export default Downline;
