import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../context/AppContext";

const Content = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: theme.spacing(1),
  justifyContent: "center",
  flex: 1,
}));

const Menu = styled(Paper).attrs({
  component: Link,
})({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  textDecoration: "none",
  textAlign: "center",
  aspectRatio: "1",
  minHeight: 150,
});

const MENU = [
  {
    id: "/bet",
    text: "Bet Now",
  },
  {
    id: "/record",
    text: "Bet Record",
  },
  {
    id: "/result",
    text: "Result",
  },
  {
    id: "/win",
    text: "Winning Number",
  },
  {
    id: "/topup",
    text: "Topup Credit",
  },
  {
    id: "/withdraw",
    text: "Withdraw / Transfer",
  },
  {
    id: "/qrcode",
    text: "MY QR Code",
  },
  {
    id: "/downline",
    text: "Downline Info",
  },
  {
    id: "/account",
    text: "Account Info",
  },
  {
    id: "/others",
    text: "Others",
  },
];

function Main() {
  const { setPageTitle } = useAppContext();

  const [t] = useTranslation();
  useEffect(() => {
    setPageTitle("Menu");
  }, []);

  function renderItem({ id, text }) {
    return (
      <Menu key={id} to={id}>
        <Typography variant="button" display="block">
          {t(text)}
        </Typography>
      </Menu>
    );
  }

  return <Content>{MENU.map(renderItem)}</Content>;
}
export default Main;
