import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useUsersSummary() {
  const url = `${API_URL}/admin/user/summary`;
  const { response, loading, get } = useAuthFetch({ url });
  return [response, loading, get];
}

export default useUsersSummary;
