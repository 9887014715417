import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { DRAW_TYPE } from "../constants/DrawResult";
import { STATUS_COLOR } from "../constants/Txn";
import { useAppContext } from "../context/AppContext";
import { formatAmount } from "../helpers/Common";
import { formatDateOnly } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";
import useWinningTxn from "../hooks/api/useWinningTxn.Admin";
import ConfirmationAccept from "./Accept";
import ConfirmationReject from "./Reject";
import WithdrawWinning from "./WithdrawWinning";

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function ApproveOrReject({ txn, toggle, refresh }) {
  const { setMessage } = useAppContext();
  const { id = "" } = useParams();
  const [reject, setReject] = useState(false);
  const [accept, setAccept] = useState(false);

  const { status } = txn || {};

  const pending = status === "new";

  if (!pending) {
    return null;
  }

  const acceptProps = {
    id,
    open: accept,
    onConfirm: async () => {
      try {
        await toggle("approved");
        setMessage("Transaction Approved");
        setAccept(false);
        refresh();
      } catch (ex) {
        const { message = "Unable to approve transaction" } = ex;
        setMessage({ message });
        setAccept(false);
      }
    },
    onClose: () => {
      setAccept(false);
    },
  };

  const rejectProps = {
    id,
    open: reject,
    onConfirm: async (desc) => {
      await toggle("rejected", desc);
      setReject(false);
      refresh();
    },
    onClose: () => {
      setReject(false);
    },
  };

  const btnAcceptProps = {
    size: "small",
    color: "primary",
    children: "Approve",
    onClick: async () => {
      setAccept(true);
    },
  };

  const btnRejectProps = {
    size: "small",
    color: "secondary",
    children: "Reject",
    onClick: () => {
      setReject(true);
    },
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Button {...btnAcceptProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button {...btnRejectProps} />
      </Grid>
      <ConfirmationReject {...rejectProps} />
      <ConfirmationAccept {...acceptProps} />
    </>
  );
}

function WinningTxn() {
  const { id = "", userId, wallet } = useParams();

  const [txn, loading, refresh, toggle] = useWinningTxn(wallet, id, userId);

  useEffect(() => {
    refresh();
  }, [id, userId]);

  if (loading) {
    return <Loader paper />;
  }

  if (!txn) {
    return null;
  }

  const {
    drawDate,
    betNumber,
    drawType,
    betPosition,
    betAmount,
    strikePosition,
    strikeAmount,
    status,
    betGameDate,

    // withdraw
    amount,
  } = txn || {};

  if (amount) {
    return <WithdrawWinning txn={txn} />;
  }

  return (
    <Grid item xs={12}>
      <Card>
        <Line status={status} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Txn No." readOnly value={id} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Username" readOnly value={userId} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Status" readOnly value={capitalize(status)} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Bet Number" readOnly value={betNumber} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Draw Date"
                readOnly
                value={formatDateOnly(drawDate)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bet Game Date"
                readOnly
                value={formatDateOnly(betGameDate)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Game"
                readOnly
                value={DRAW_TYPE[drawType]?.text}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Bet Position" readOnly value={betPosition} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bet Amount"
                readOnly
                value={formatAmount(betAmount)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Strike Position"
                readOnly
                value={strikePosition}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Strike Amount"
                readOnly
                value={formatAmount(strikeAmount)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container spacing={1}>
            <BetActions txn={txn} />
            <ApproveOrReject txn={txn} toggle={toggle} refresh={refresh} />
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}

function BetActions({ txn }) {
  const { betTxnId, status, wallet, userId } = txn || {};

  if (!betTxnId) {
    return null;
  }

  const pending = status === "new";

  return (
    <Grid item xs={12}>
      <Button
        size="small"
        component={Link}
        color="primary"
        variant={pending ? "outlined" : "contained"}
        to={`/admin/txn/bet/${wallet}/${userId}/${betTxnId}`}
      >
        View Bet Record
      </Button>
    </Grid>
  );
}

function WinningTxnDetail() {
  const { setPageTitle } = useAppContext();

  useEffect(() => {
    setPageTitle("Winning Detail");
  }, []);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="row"
      justifyContent="center"
    >
      <Grid item md={6} xs={12}>
        <WinningTxn />
      </Grid>
    </Grid>
  );
}

export default WinningTxnDetail;
