import React, { useMemo } from "react";
import styled from "styled-components";
import Table from "../components/Table";
import { getDrawType } from "../constants/DrawResult";
import { formatAmount } from "../helpers/Common";

const Image = styled.div(({ $drawType }) => {
  const { color, image } = getDrawType($drawType);
  return {
    height: 20,
    width: 54,
    background: `${color} url(${image}) no-repeat center`,
    backgroundSize: "contain",
  };
});

function HomeSummary({ summary = [] }) {
  const headCells = [
    {
      id: "drawType",
      numeric: false,
      label: "Game",
      component: ({ drawType }) => <Image $drawType={drawType} />,
    },
    {
      id: "total",
      numeric: false,
      label: "Total",
      format: ({ total }) => formatAmount(total),
    },
  ];

  const totalAmount = useMemo(
    () => summary?.reduce((prev, { total }) => prev + total, 0),
    [summary]
  );

  const tableProps = {
    headCells,
    items: summary,
    rowsPerPage: 10,
    noEmptyRows: false,
    // noPagination: true,
    prefixUniqueId: "drawType",
    defaultOrder: "desc",
    uniqueId: "betNumber",
    title: `Summary (${formatAmount(totalAmount)})`,
    defaultSort: "total",
  };

  return <Table {...tableProps} />;
}

export default HomeSummary;
