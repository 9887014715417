import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useSettings() {
  const url = `${API_URL}/admin/setting`;
  const { response, loading, put } = useAuthFetch({ url });

  function update({ id, value }) {
    return put({ url: `${url}/${id}`, body: { value } });
  }

  return [response, loading, update];
}

export default useSettings;
