import ButtonGroup from "@material-ui/core/ButtonGroup";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";

function LanguageSwitch() {
  const { i18n } = useTranslation();

  const activeProps = {
    variant: "contained",
  };

  const defaultEnProps = {
    color: "secondary",
    onClick: () => i18n.changeLanguage("en"),
  };

  const defaultCnProps = {
    color: "secondary",
    onClick: () => i18n.changeLanguage("cn"),
  };

  const enProps = i18n.language === "en" ? activeProps : {};
  const cnProps = i18n.language === "cn" ? activeProps : {};

  return null;

  return (
    <ButtonGroup size="small">
      <Button {...enProps} {...defaultEnProps}>
        EN
      </Button>
      <Button {...cnProps} {...defaultCnProps}>
        中文
      </Button>
    </ButtonGroup>
  );
}

export default LanguageSwitch;
