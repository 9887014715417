import qs from "query-string";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

function useSearchParams() {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const parsed = qs.parse(search);
  const query = Object.keys(parsed).length === 0 ? undefined : parsed;

  const setQuery = useCallback((params) => {
    history.replace({ pathname, search: qs.stringify(params) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [query, setQuery];
}

export default useSearchParams;
