import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { formatAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import useDownlines from "../hooks/api/useDownlines.Admin";
import useUserAccount from "../hooks/api/useUserAccount.Admin";

const STATUS_COLOR = {
  active: green[500],
  inactive: red[900],
};

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS_COLOR[status],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusHeader = ({ label }) => {
  return (
    <StatusContent>
      <Status head>{label}</Status>
    </StatusContent>
  );
};

const StatusColumn = ({ item }) => {
  const { id, status } = item;

  return (
    <StatusContent>
      <Dot status={status} />
      <StatusText to={`/admin/user/${id}`}>{id} </StatusText>
    </StatusContent>
  );
};

const UserWallet = ({ wallet, id }) => {
  const [account, loading] = useUserAccount(id);
  const { walletGD, walletMKT } = account ?? {};

  if (loading) {
    return <>...</>;
  }

  return <>{formatAmount(wallet === "mkt" ? walletMKT : walletGD)}</>;
};

function Downline() {
  const { id = "" } = useParams();
  const [users = [], loading] = useDownlines(id);

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Username",
      custom: (item) => <StatusColumn item={item} />,
      customHeader: (label) => <StatusHeader label={label} />,
    },
    {
      id: "mobile",
      numeric: false,
      label: "Phone Number",
    },
    {
      id: "walletMKT",
      numeric: false,
      label: "Wallet 1",
      component: (item) => <UserWallet wallet="mkt" {...item} />,
    },
    {
      id: "walletGD",
      numeric: false,
      label: "Wallet 2",
      component: (item) => <UserWallet wallet="gd" {...item} />,
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Registered At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  const tableProps = {
    headCells,
    items: users,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Downlines",
    defaultSort: "createdAt",
  };

  return loading ? <Loader paper /> : <Table {...tableProps} />;
}

export default Downline;
