import LibAppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import LibToolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import LanguageSwitch from "./LanguageSwitch";

const Toolbar = styled(LibToolbar)({
  paddingRight: 24,
});

const MenuButton = styled(IconButton).attrs({
  edge: "start",
  color: "inherit",
})(({ open }) => ({}));

const MainTitle = styled(Typography).attrs({
  component: "h1",
  variant: "h6",
  color: "inherit",
  noWrap: true,
})({
  flexGrow: 1,
});

const AppBar = styled(LibAppBar)({
  transition: ".3s",
});

function NavBar({ title, setOpen }) {
  const [t] = useTranslation();

  const { pathname } = useLocation();
  const history = useHistory();

  const isMain = pathname === "/" || pathname === "/main";

  const handleBack = () => {
    try {
      if (history.length <= 1) {
        throw new Error("No Route");
      }

      history.goBack();
    } catch (ex) {
      history.replace("/");
    }
  };

  return (
    <AppBar position="absolute">
      <Toolbar>
        {!isMain && (
          <MenuButton onClick={handleBack}>
            <ArrowBackIcon />
          </MenuButton>
        )}
        <MainTitle>{t(title)}</MainTitle>

        <LanguageSwitch />
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
