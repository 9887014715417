import axios from "axios";
import packageJson from "../../package.json";

const { version } = packageJson;

export const appHttp = async (url, options) => {
  const {
    headers,
    body,
    method = "GET",
    token,
    refreshToken,
    retried = false,
    ...rest
  } = options ?? {};

  const defaultOptions = {
    url,
    method,
    data: body,
    headers: {
      Authorization: token && `Bearer ${token}`,
      "X-App-Version": version,
    },
    ...rest,
  };

  try {
    const response = await axios(defaultOptions);
    const { data } = response ?? {};

    if (response.status === 204) {
      return null;
    }

    return data;
  } catch (error) {
    const { response } = error;
    const { data, status: resStatus } = response ?? {};

    const { message } = data ?? {};

    if (resStatus === 403) {
      return;
    }

    const isTokenExpired =
      message === "The incoming token has expired" ||
      resStatus === 401 ||
      retried;

    if (!isTokenExpired) {
      throw new Error(message);
    }

    return reTry(message);
  }

  async function reTry(error) {
    try {
      const { token: updatedToken } = await refreshToken();
      return appHttp(url, { ...options, token: updatedToken, retried: true });
    } catch (ex) {
      throw new Error(error);
    }
  }
};
