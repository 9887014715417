import { API_URL } from "../../constants/EnvConfig";
import { useAuthContext } from "../../context/AuthContext";
import useAuthFetch from "../useAuthFetch";

function useAdminAccount() {
  const { user } = useAuthContext();
  const { id, isAdmin } = user ?? {};
  const url = isAdmin ? `${API_URL}/admin/adminAccount/${id}` : "";
  const { response, loading, get } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  return [response, loading, refresh];
}

export default useAdminAccount;
