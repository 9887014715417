import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bottomRoot: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: theme.shadows[4],
    ...theme.mixins.toolbar,
    zIndex: theme.zIndex.appBar,
  },
}));

function BottomMenu() {
  const [t] = useTranslation();

  const { bottomRoot } = useStyles();
  const { pathname } = useLocation();

  const isMain = pathname === "/" || pathname === "/main";

  const ctaMenuProps = {
    to: isMain ? "/" : "/main",
    label: isMain ? t("Switch Site") : t("Home"),
    icon: isMain ? <AccountBalanceWalletIcon /> : <HomeIcon />,
  };

  return (
    <BottomNavigation showLabels className={bottomRoot}>
      <BottomNavigationAction component={Link} {...ctaMenuProps} />
      <BottomNavigationAction
        component={Link}
        to="/logout"
        label={t("Logout")}
        icon={<ExitToAppIcon />}
      />
    </BottomNavigation>
  );
}

export default BottomMenu;
