import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { formatAmount } from "../helpers/Common";
import { PAYMENT_METHOD_BANKS } from "./Withdraw";

export const TXN_TYPE = [
  {
    text: "Wallet",
    value: "MKT",
  },
  {
    text: "Wallet",
    value: "GD",
  },
  {
    text: "Commissions",
    value: "COM",
  },
  {
    text: "Winning",
    value: "WIN",
  },
];

export const getTxnTypes = ({ userAccount, loading }) => {
  const { walletGD, walletMKT, winningGD, winningMKT, commission } =
    userAccount || {};

  const anyLoading = loading || !userAccount;

  return [
    {
      text: `Wallet (${anyLoading ? "..." : formatAmount(walletMKT)})`,
      value: "MKT",
      wallet: "MKT",
    },
    {
      text: `Wallet (${anyLoading ? "..." : formatAmount(walletGD)})`,
      value: "GD",
      wallet: "GD",
    },
    {
      text: `Commissions (${anyLoading ? "..." : formatAmount(commission)})`,
      value: "COM",
      wallet: "MKT",
    },
    {
      text: `Winning (${anyLoading ? "..." : formatAmount(winningMKT)})`,
      value: "WIN",
      wallet: "MKT",
    },
    {
      text: `Winning (${anyLoading ? "..." : formatAmount(winningGD)})`,
      value: "WIN",
      wallet: "GD",
    },
  ];
};

export const PAYMENT_METHOD = {
  ...PAYMENT_METHOD_BANKS.reduce(
    (prev, { value, text }) => ({
      ...prev,
      [value]: `Top up: ${text}`,
    }),
    {}
  ),
  DUITNOW: "Top up: DuitNow",
  WIN: "From Winning",
  COM: "From Comm",
  BONUS: "Topup/Transfer Bonus",
  TRANSFER: "Transfer",
  CASH: "Cash",
};

export const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
};
