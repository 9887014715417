import React from "react";
import Confirmation from "../components/Confirmation";
import useBankAccount from "../hooks/api/useBankAccount.Admin";

function Toggle({ item, open, onClose, onConfirm }) {
  const { id, accountNum, status } = item ?? {};
  const { loading, toggle } = useBankAccount(id);

  const active = status === "active";

  const conProps = {
    title: active ? "Disable Bank Account" : "Enable Bank Account",
    desc: `Are you sure want to ${
      active ? "disable" : "enable"
    } ${accountNum}?`,
    open,
    onClose,
    loading,
    onConfirm: async () => {
      await toggle();
      onConfirm();
      onClose();
    },
  };
  return <Confirmation {...conProps} />;
}

export default Toggle;
