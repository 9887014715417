import Confirmation from "../components/Confirmation";

function Confirm({
  toAccount,
  loading,
  transferUserId,
  amount,
  open,
  onClose,
  onConfirm,
}) {
  const conProps = {
    title: `${!toAccount === "USER" ? "Transfer" : "Withdrawal"}  Confirmation`,
    desc: `Are you sure want to ${
      toAccount === "USER"
        ? `transfer $${amount} to ${transferUserId}`
        : `withdraw $${amount}`
    } `,
    open,
    onClose,
    loading,
    onConfirm: () => {
      onConfirm();
      onClose();
    },
  };
  return <Confirmation {...conProps} />;
}

export default Confirm;
