import damacaiImage from "../assets/small/damacai.png";
import gdImage from "../assets/small/gd.png";
import magnumImage from "../assets/small/magnum.png";
import sabahImage from "../assets/small/sabah.png";
import sarawakImage from "../assets/small/sarawak.png";
import sgpoolImage from "../assets/small/sgpool.png";
import stcImage from "../assets/small/stc.png";
import totoImage from "../assets/small/toto.png";

const DRAW_TYPE = {
  magnum: { image: magnumImage, value: 1 },
  damacai: { image: damacaiImage, value: 2 },
  toto: { image: totoImage, value: 3 },
  sgpool: { image: sgpoolImage, value: 4 },
  sabah: { image: sabahImage, value: 5 },
  stc: { image: stcImage, value: 6 },
  sarawak: { image: sarawakImage, value: 7 },
  gd: { image: gdImage, value: 8 },
};

export const WALLET_GD_DRAWS = {
  "3D": [DRAW_TYPE.gd],
  "4D": [DRAW_TYPE.gd],
  "5D/6D": [DRAW_TYPE.gd],
};

export const WALLET_MKT_DRAWS = {
  "3D": [
    DRAW_TYPE.magnum,
    DRAW_TYPE.damacai,
    DRAW_TYPE.toto,
    DRAW_TYPE.sgpool,
    DRAW_TYPE.sabah,
    DRAW_TYPE.stc,
    DRAW_TYPE.sarawak,
  ],
  "4D": [
    DRAW_TYPE.magnum,
    DRAW_TYPE.damacai,
    DRAW_TYPE.toto,
    DRAW_TYPE.sgpool,
    DRAW_TYPE.sabah,
    DRAW_TYPE.stc,
    DRAW_TYPE.sarawak,
  ],
  "5D/6D": [DRAW_TYPE.toto],
};

const WALLET_GD_DATES = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

const WALLET_MKT_DATES = ["WED", "SAT", "SUN"];

export const DRAW_DATES = {
  GD: WALLET_GD_DATES,
  MKT: WALLET_MKT_DATES,
};

export const DRAW_TYPES = {
  GD: WALLET_GD_DRAWS,
  MKT: WALLET_MKT_DRAWS,
};

// [
//   { image: magnumImage, value: DRAW_TYPE.magnum },
//   { image: damacaiImage, value: DRAW_TYPE.damacai },
//   { image: totoImage, value: DRAW_TYPE.toto },
//   { image: sgpoolImage, value: DRAW_TYPE.sgpool },
//   { image: sabahImage, value: DRAW_TYPE.sabah },
//   { image: stcImage, value: DRAW_TYPE.stc },
//   { image: sarawakImage, value: DRAW_TYPE.sarawak },
//   { image: gdImage, value: DRAW_TYPE.gd },
// ];

export const BET_TYPES = [
  { text: "3D", value: "3D" },
  { text: "4D", value: "4D" },
  { text: "5D/6D", value: "5D/6D" },
];
