import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AmountChips from "../components/AmountChips";
import Button from "../components/Button";
import Dropzone from "../components/Dropzone";
import HelpButton from "../components/HelpButton";
import Loader from "../components/Loader";
import Select from "../components/Select";
import TextField, { PureTextField } from "../components/TextField";
import { TOPUP_AMOUNTS } from "../constants/Topup";
import { PAYMENT_METHODS } from "../constants/Withdraw";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { useWalletContext } from "../context/WalletContext";
import { MAX_RULE, MIN_RULE, REQUIRED_RULE } from "../helpers/Form";
import useBankAccount from "../hooks/api/useBankAccount";
import useTopup from "../hooks/api/useTopup";
import useForm from "../hooks/useForm";

const OTHER_VALUE = "OT";

const ALL_TOPUP_AMOUNTS = [
  ...TOPUP_AMOUNTS,
  { text: "Other", value: OTHER_VALUE },
];

const MAX_LIMIT = 10000;

const QrCodeContent = styled.div(({ type, error, theme }) => ({
  height: 400,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderWidth: 1,
  border: "1px solid rgba(255, 255, 255, 0.23)",
  borderColor: error ? theme.palette.error.main : "rgba(255, 255, 255, 0.23)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  position: "relative",
  marginTop: theme.spacing(2),
  overflow: "hidden",
}));

const Image = styled.div(({ $src }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${$src})`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",

  "&:after": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(66,66,66, .25)",
  },
}));

function Topup() {
  const { setPageTitle, setMessage } = useAppContext();
  const { selected, userAccount } = useWalletContext();
  const [banks, banksLoading] = useBankAccount();
  const { user } = useAuthContext();

  const [, , refreshAccount] = userAccount;

  const { id, mobile } = user ?? {};

  const [t] = useTranslation();

  const form = useForm();

  const { reset, watch, setValue, handleSubmit } = form;

  const [method, amount] = watch(["method", "amount"]);

  const [topupLoading, createTopup] = useTopup();

  const bankMethods = useMemo(() => {
    if (!banks) {
      return [];
    }

    return (
      banks
        .filter((s) => s.method === "BANK")
        .map(({ bank }) => ({
          text: PAYMENT_METHODS[bank],
          value: bank,
        })) ?? []
    );
  }, [banks]);

  const duitNowMethods = useMemo(() => {
    if (!banks) {
      return [];
    }

    return (
      banks
        .filter((s) => s.method === "DUITNOW")
        .map(() => ({
          text: "DuitNow",
          value: "DUITNOW",
        })) ?? []
    );
  }, [banks]);

  const methods = useMemo(() => {
    if (banksLoading) {
      return [];
    }

    const defaultMethods = [
      ...bankMethods,
      ...duitNowMethods,
      {
        text: "Cash",
        value: "CASH",
      },
    ];
    return defaultMethods;
  }, [bankMethods, duitNowMethods, banksLoading]);

  const hasAttachment = useMemo(() => !["CASH"].includes(method), [method]);

  const selectedBank = useMemo(() => {
    if (!banks) {
      return;
    }

    return banks
      .filter((s) => s.method === "BANK")
      .find((s) => s.bank === method);
  }, [banks, method]);

  const selectedQR = useMemo(() => {
    if (!banks) {
      return;
    }

    return banks.find((s) => s.method === method);
  }, [banks, method]);

  const resetForm = useCallback(() => {
    reset({
      method: methods?.[0]?.value,
    });
  }, [methods, reset]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    setPageTitle("Topup Credit");
  }, []);

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  async function onSubmit(data) {
    try {
      await createTopup(data);
      refreshAccount(2500);
      const message = "Pending Admin Approval";
      setMessage(message);
      resetForm();
    } catch (ex) {
      setMessage(ex);
    }
  }

  function renderTopupForm() {
    return (
      <FormProvider {...form}>
        <Card component="form" {...formProps}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  label={t("Payment Method")}
                  items={methods}
                  name="method"
                  rules={{
                    ...REQUIRED_RULE,
                  }}
                  displayEmpty={false}
                />
              </Grid>
              {hasAttachment ? renderBankTransferForm() : renderAnyAmountForm()}

              <Grid item xs={12}>
                {renderTnC()}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" fullWidth color="primary">
              {t("Top up Now")}
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  function renderBankTransferForm() {
    return (
      <>
        {renderAnyAmountForm()}
        <Grid item xs={12}>
          <Dropzone
            label={t("Upload Top Up Proof")}
            name="receipt"
            url={`/walletTxn/${selected}/receipt`}
            rules={{
              ...REQUIRED_RULE,
            }}
          />
        </Grid>
      </>
    );
  }

  function renderAnyAmountForm() {
    const amtProps = {
      rules: {
        ...REQUIRED_RULE,
        ...MIN_RULE(0),
        ...MAX_RULE(MAX_LIMIT),
      },
      endIcon: <InputAdornment position="end">/ {MAX_LIMIT}</InputAdornment>,
    };

    const otherValue = TOPUP_AMOUNTS.some(({ value }) => +amount === value)
      ? amount
      : OTHER_VALUE;

    return (
      <Grid item xs={12}>
        <TextField
          label={t("Total Credit")}
          name="amount"
          type="number"
          {...amtProps}
        />
        <AmountChips
          items={ALL_TOPUP_AMOUNTS}
          value={amount ? otherValue : ""}
          onChange={(value) => setValue("amount", value)}
        />
      </Grid>
    );
  }
  function renderBankAccountForm() {
    if (!selectedBank) {
      return null;
    }

    const { bank, accountNum, accountName } = selectedBank;

    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PureTextField
                label={t("Bank Name")}
                value={PAYMENT_METHODS[bank]}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <PureTextField
                label={t("Account No")}
                value={accountNum}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <PureTextField
                label={t("Account Name")}
                value={accountName}
                readOnly
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  function renderQrCode() {
    if (!selectedQR) {
      return;
    }

    return (
      <QrCodeContent>
        <Image $src={selectedQR.image} />
      </QrCodeContent>
    );
  }

  function renderTnC() {
    if (!hasAttachment) {
      return;
    }

    const txtProps = {
      variant: "body2",
      color: "textSecondary",
      display: "block",
      gutterBottom: true,
    };

    return (
      <>
        <Typography {...txtProps}>
          Please put{" "}
          <b>
            {id} {mobile.substring(mobile.length - 4, mobile.length)}{" "}
          </b>
          in reference.
        </Typography>
        <Typography {...txtProps}>
          Bank account will change frequently. Please make sure transfer to
          latest bank account number.
        </Typography>
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {topupLoading ? <Loader paper /> : renderTopupForm()}
      </Grid>
      <Grid item xs={12}>
        {renderBankAccountForm()}
        {renderQrCode()}
      </Grid>
      <Grid item xs={12}>
        <HelpButton />
      </Grid>
    </Grid>
  );
}
export default Topup;
