import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LibLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import React, { useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { MIN_RULE, MUST_EQUAL, REQUIRED_RULE } from "../helpers/Form";
import useAnyApi from "../hooks/api/useAnyApi";
import useForm from "../hooks/useForm";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword({ username }) {
  const classes = useStyles();

  const [t] = useTranslation();
  const form = useForm();
  const { handleSubmit, watch } = form;

  const password = useRef();
  password.current = watch("password", "");

  const { confirmPassword } = useAuthContext();
  const { setMessage } = useAppContext();
  const history = useHistory();

  const [loading, invokeForget] = useAnyApi();

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  async function onSubmit({ code, password }) {
    try {
      await invokeForget(() => confirmPassword({ username, code, password }));
      setMessage("Password Reset. Please relogin.");
      history.push("/login", { username });
    } catch (ex) {
      setMessage(ex);
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <FormProvider {...form}>
      <form className={classes.form} {...formProps}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label={t("Verification Code")} name="code" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label={t("Password")}
              type="password"
              rules={{ ...REQUIRED_RULE, ...MIN_RULE(6) }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="confPassword"
              label={t("Re-Type Password")}
              type="password"
              rules={{
                ...REQUIRED_RULE,
                ...MUST_EQUAL(
                  password.current,
                  t("The passwords do not match")
                ),
              }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t("Reset Password")}
        </Button>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <LibLink component={Link} to="register" variant="body2">
              {t("Don't have an account? Sign Up")}
            </LibLink>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
