import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { CURRENCY } from "../constants/EnvConfig";
import { isObject } from "./Object";

export const uuid = () => {
  return uuidv4();
};

export const delay = (time) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });

export const chunkArray = (array, size) => {
  const length = array.length;
  const result = [];

  for (let index = 0; index < length; index += size) {
    const chunk = array.slice(index, index + size);
    result.push(chunk);
  }

  return result;
};

// export const debounce = (a, b, c) => {
//   let d, e;
//   return () => {
//     const h = () => {
//       (d = null), c || (e = a.apply(f, g));
//     };
//     let f = this;
//     let g = arguments;
//     return (
//       clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
//     );
//   };
// };

export const maskInput = (value, start = 3, end = 4, replace = "*") => {
  const max = value.length - end;
  const first = value.substring(0, start);
  const last = value.substring(max);
  const { length } = value.substring(start, max);
  const mask = Array(length).fill(replace).join("");

  return `${first} ${mask}${last}`;
};

export const formatDistance = (distance) => {
  if (distance >= 10000) {
    return "10>";
  }

  return (distance * 0.001).toFixed(1);
};

export const formatAmount = (amount = 0, withCurrency = true, dp = 2) => {
  amount = roundTo(amount, dp);
  return `${withCurrency ? `${CURRENCY}` : ""}${Math.max(amount, 0).toFixed(
    dp
  )}`;
};

export const formatBetAmount = (amount = 0, withCurrency = true, dp = 2) => {
  const posOrNeg = amount >= 0 ? `+` : "-";
  amount = roundTo(amount, dp);
  return `${withCurrency ? `${posOrNeg} ${CURRENCY}` : ""}${Math.abs(
    amount
  ).toFixed(dp)}`;
};

export function roundTo(num, dp = 2) {
  const point = Math.pow(10, dp);
  return Math.round((num + Number.EPSILON) * point) / point;
}

export function roundToPercent(num, dp = 4) {
  const per = num * 100;
  const point = Math.pow(10, dp);
  return Math.round((per + Number.EPSILON) * point) / point;
}

export function roundToDigit(num, dp = 4) {
  const per = num / 100;
  const point = Math.pow(10, dp);
  return Math.round((per + Number.EPSILON) * point) / point;
}

export const strongPolicyPassword = (password) => {
  const regex = new RegExp(
    /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/g
  );
  return regex.test(password);
};

export const capitalize = (text = "", lower) =>
  text
    .split(" ")
    .map((t) => capitalizeSingle(t, lower))
    .join(" ");

export const capitalizeSingle = (text = "", lower) => {
  const [first = "", ...rest] = lower ? text.toLowerCase() : text;
  return [first.toLocaleUpperCase(navigator.language), ...rest].join("");
};

export const eq = (s1 = "", s2 = "", ignoreCase) => {
  return ignoreCase ? s1.toLowerCase() === s2.toLowerCase() : s1 === s2;
};

export const distint = (items = []) =>
  Object.keys(
    items.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: 1,
      }),
      {}
    )
  );

export const groupBy = (items, key) =>
  items.reduce((prev, curr) => {
    (prev[curr[key]] = prev[curr[key]] || []).push(curr);
    return prev;
  }, {});

export const getDateOnly = (date) => {
  return moment(moment(date).format("DDMMYYYY"), "DDMMYYYY");
};

export const equalsAll = (value1, value2) => {
  if (isObject(value1) && isObject(value2)) {
    return Object.keys(value1).every((key) => value1[key] === value2[key]);
  }

  return value1 === value2;
};

export const replaceJSX = (str, find, replace) => {
  const parts = str.split(find);
  const result = parts.reduce((prev, part) => [...prev, part, replace], []);
  result.pop();
  return result;
};

export default {};
