import Grid from "@material-ui/core/Grid";
import React from "react";
import CommTxns from "./CommTxns";
import Downline from "./Downline";
import WalletTxns from "./WalletTxns";
import WinningTxns from "./WinningTxns";

function Users() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Downline />
      </Grid>
      <Grid item xs={12}>
        <WalletTxns wallet="mkt" />
      </Grid>
      <Grid item xs={12}>
        <WalletTxns wallet="gd" />
      </Grid>
      <Grid item xs={12}>
        <CommTxns />
      </Grid>
      <Grid item xs={12}>
        <WinningTxns wallet="mkt" />
      </Grid>
      <Grid item xs={12}>
        <WinningTxns wallet="gd" />
      </Grid>
    </Grid>
  );
}

export default Users;
