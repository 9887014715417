import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../components/Button";
import Datepicker from "../components/Datepicker";
import Loader from "../components/Loader";
import Select from "../components/Select";
import { DRAW_TYPE, DRAW_TYPES } from "../constants/DrawResult";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useDrawResult from "../hooks/api/useDrawResult";
import useForm from "../hooks/useForm";

const MainGrid = styled(Grid).attrs({
  container: true,
  spacing: 2,
})({});

const Banner = styled.div(({ type }) => ({
  height: 62,
  background: `${DRAW_TYPE[type]?.color} url('${DRAW_TYPE[type]?.image}') no-repeat right top`,
  padding: 16,
  color: DRAW_TYPE[type]?.textColor,
}));

const MainContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}));

const MainPrize = styled(Typography).attrs({
  variant: "h6",
  color: "textSecondary",
})({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

const PrizeValue = styled(Typography).attrs({
  variant: "h4",
  color: "textPrimary",
})(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

const Prize2Value = styled(Typography).attrs({
  variant: "h6",
  color: "textPrimary",
  align: "center",
})({});

const ResultContainer = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  gridAutoRows: theme.spacing(4),
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

const DrawResultContainer = styled(Paper).attrs({
  variant: "outlined",
})({
  overflow: "hidden",
});

function DrawResult() {
  const { setPageTitle, setMessage } = useAppContext();
  const [t] = useTranslation();

  const form = useForm({
    defaultValues: {
      date: moment().toJSON(),
      type: DRAW_TYPES[0]?.value,
    },
  });
  const { handleSubmit } = form;
  const [items = [], loading, search] = useDrawResult();

  useEffect(() => {
    setPageTitle("Draw Result");
  }, []);

  async function onSubmit({ date, type: tp = "" }) {
    try {
      search({ date, type: tp === "All" ? "" : tp });
    } catch (ex) {
      setMessage(ex);
    }
  }

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  function renderSearchBox() {
    return (
      <FormProvider {...form}>
        <Card component="form" {...formProps}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Datepicker
                  label={t("Draw Date")}
                  name="date"
                  rules={{
                    ...REQUIRED_RULE,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label={t("Draw Game")}
                  name="type"
                  items={DRAW_TYPES}
                  displayEmpty={false}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              {t("Search")}
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  function renderResult(result) {
    const { betType } = result;

    if (betType === "6D") {
      return render6DResult(result);
    }

    if (betType === "5D") {
      return render5DResult(result);
    }

    if (betType === "4D") {
      return render4DResult(result);
    }

    return null;
  }

  function render4DResult(result) {
    const { drawType, p1, p2, p3, special, consolation } = result;

    return (
      <DrawResultContainer>
        <Grid container>
          <Grid item xs={12}>
            <Banner type={drawType}>
              <Typography variant="h6">
                {t(DRAW_TYPE[drawType]?.text)} 4D
              </Typography>
            </Banner>
          </Grid>
          <MainContainer>
            <MainPrize variant="h6">
              {t("1st Prize")} <PrizeValue>{p1}</PrizeValue>
            </MainPrize>
            <MainPrize variant="h6">
              {t("2nd Prize")} <PrizeValue>{p2}</PrizeValue>
            </MainPrize>
            <MainPrize variant="h6">
              {t("3rd Prize")} <PrizeValue>{p3}</PrizeValue>
            </MainPrize>
          </MainContainer>
          <Grid xs={12}>
            <Typography
              variant="overline"
              display="block"
              align="center"
              color="primary"
              gutterBottom
            >
              {t("Special")}
            </Typography>
            <ResultContainer>
              {special.map((s) => (
                <Prize2Value>{s}</Prize2Value>
              ))}
            </ResultContainer>
          </Grid>
          <Grid xs={12}>
            <Typography
              variant="overline"
              display="block"
              align="center"
              color="primary"
              gutterBottom
            >
              {t("Consolation")}
            </Typography>
            <ResultContainer>
              {consolation.map((s) => (
                <Prize2Value>{s}</Prize2Value>
              ))}
            </ResultContainer>
          </Grid>
        </Grid>
      </DrawResultContainer>
    );
  }

  function render5DResult(result) {
    const { drawType, p1, p2, p3 } = result;
    return (
      <DrawResultContainer>
        <Grid container>
          <Grid item xs={12}>
            <Banner type={drawType}>
              <Typography variant="h6">
                {t(DRAW_TYPE[drawType]?.text)} 5D
              </Typography>
            </Banner>
          </Grid>
          <MainContainer>
            <MainPrize variant="h6">
              {t("1st Prize")} <PrizeValue>{p1}</PrizeValue>
            </MainPrize>
            <MainPrize variant="h6">
              {t("2nd Prize")} <PrizeValue>{p2}</PrizeValue>
            </MainPrize>
            <MainPrize variant="h6">
              {t("3rd Prize")} <PrizeValue>{p3}</PrizeValue>
            </MainPrize>
          </MainContainer>
        </Grid>
      </DrawResultContainer>
    );
  }

  function render6DResult(result) {
    const { drawType, p1 } = result;
    return (
      <DrawResultContainer>
        <Grid container>
          <Grid item xs={12}>
            <Banner type={drawType}>
              <Typography variant="h6">
                {t(DRAW_TYPE[drawType]?.text)} 6D
              </Typography>
            </Banner>
          </Grid>
          <MainContainer>
            <MainPrize variant="h6">
              <PrizeValue>{p1}</PrizeValue>
            </MainPrize>
          </MainContainer>
        </Grid>
      </DrawResultContainer>
    );
  }

  function renderResults() {
    return (
      <Grid container spacing={2}>
        {items.map((result) => (
          <Grid item xs={12}>
            {renderResult(result)}
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <MainGrid>
      <Grid item xs={12}>
        {renderSearchBox()}
      </Grid>
      <Grid item xs={12}>
        {loading ? <Loader /> : renderResults()}
      </Grid>
    </MainGrid>
  );
}
export default DrawResult;
