import LibButton from "@material-ui/core/Button";
import React from "react";

function Button(props) {
  const commProps = {
    variant: "contained",
    fullWidth: true,
    ...props,
  };
  return <LibButton {...commProps}></LibButton>;
}

export default Button;
