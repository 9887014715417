import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useMemo } from "react";
import styled from "styled-components";
import { DRAW_TYPE } from "../constants/DrawResult";
import { numberWithCommas } from "../helpers/String";

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#ffc107" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({
  paddingLeft: 8,
  paddingRight: 8,
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

function SideReport({ txns }) {
  const summary = useMemo(() => {
    return txns
      .filter((s) => s.status === "approved")
      .reduce(
        (prev, txn) => ({
          ...prev,
          [txn.drawType]: prev[txn.drawType] + txn.strikeAmount,
        }),
        {
          magnum: 0,
          damacai: 0,
          toto: 0,
          sgpool: 0,
          sabah: 0,
          stc: 0,
          sarawak: 0,
          gd: 0,
        }
      );
  }, [txns]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.magnum)}</Value>
            <Title>{DRAW_TYPE.magnum.text} ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.damacai)}</Value>
            <Title>{DRAW_TYPE.damacai.text} ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.toto)}</Value>
            <Title>{DRAW_TYPE.toto.text} ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.sgpool)}</Value>
            <Title>{DRAW_TYPE.sgpool.text} ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.sabah)}</Value>
            <Title>{DRAW_TYPE.sabah.text} ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.stc)}</Value>
            <Title>{DRAW_TYPE.stc.text} ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.sarawak)}</Value>
            <Title>{DRAW_TYPE.sarawak.text} ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $primary>{numberWithCommas(summary?.gd)}</Value>
            <Title>{DRAW_TYPE.gd.text} ($)</Title>
          </SummaryCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SideReport;
