// import duitnowImage from "../assets/duitnow.png";

export const TOPUP_AMOUNTS = [20, 50, 100, 200, 300, 500, 1000].map(
  (value) => ({
    value,
    text: value,
  })
);

// export const DUITNOW_ACCOUNT = {
//   text: "DuitNow QR",
//   image: duitnowImage,
//   value: "DUITNOW",
// };

export const DEFAULT_PAYMENT_METHODS = [
  { text: "From Winning", value: "WIN" },
  { text: "From Comm", value: "COM" },
];
