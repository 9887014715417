import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddAdminAccount from "../addAdminAccount/AddAdminAccount";
import AddBankAccount from "../addBankAccount/AddBankAccount";
import AdminAccount from "../adminAccount/AdminAccount";
import AdminAccountDetail from "../adminAccountDetail/AdminAccountDetail";
import Logout from "../auth/Logout";
import BankAccount from "../bankAccount/BankAccount";
import BankAccountDetail from "../bankAccountDetail/BankAccountDetail";
import BetTxnDetail from "../betTxnDetail/BetTxnDetail";
import BetTxns from "../betTxns/BetTxns";
import ChangePassword from "../changePassword/ChangePassword";
import CommTxnDetail from "../commTxnDetail/CommTxnDetail";
import CommTxns from "../commTxns/CommTxns";
import SharedLayout from "../components/SharedLayout.Admin";
import Home from "../home/Home.Admin";
import Message from "../message/Message";
import Setting from "../setting/Setting";
import TransferTxns from "../transferTxns/TransferTxns";
import UserDetail from "../userDetail/UserDetail";
import Users from "../users/Users";
import WalletTxnDetail from "../walletTxnDetail/WalletTxnDetail";
import TopupTxns from "../walletTxns/WalletTxns.Topup";
import WinningTxnDetail from "../winningTxnDetail/WinningTxnDetail";
import WinningTxns from "../winningTxns/WinningTxns";
import WithdrawTxnDetail from "../withdrawTxnDetail/WithdrawTxnDetail";
import WithdrawTxns from "../withdrawTxns/WithdrawTxns";

function RootAdmin() {
  // const { user } = useAuthContext();
  return (
    <SharedLayout>
      <Switch>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route exact path="/admin/txn/topup">
          <TopupTxns />
        </Route>
        <Route exact path="/admin/txn/bet">
          <BetTxns />
        </Route>
        {/* <Route exact path="/admin/txn/wallet/:wallet">
          <WalletTxns />
        </Route> */}
        <Route exact path="/admin/txn/wallet/:wallet/:userId/:id">
          <WalletTxnDetail />
        </Route>
        <Route exact path="/admin/txn/win">
          <WinningTxns />
        </Route>
        <Route exact path="/admin/txn/win/:wallet/:userId/:id">
          <WinningTxnDetail />
        </Route>
        <Route exact path="/admin/txn/comm">
          <CommTxns />
        </Route>
        <Route exact path="/admin/txn/comm/:userId/:id">
          <CommTxnDetail />
        </Route>
        <Route exact path="/admin/txn/transfer">
          <TransferTxns />
        </Route>
        <Route exact path="/admin/txn/withdraw">
          <WithdrawTxns />
        </Route>
        <Route exact path="/admin/txn/withdraw/:wallet/:userId/:id">
          <WithdrawTxnDetail />
        </Route>
        <Route exact path="/admin/txn/bet/:wallet/:userId/:id">
          <BetTxnDetail />
        </Route>
        <Route exact path="/admin/user">
          <Users />
        </Route>
        <Route exact path="/admin/password">
          <ChangePassword />
        </Route>
        <Route exact path="/admin/user/:id">
          <UserDetail />
        </Route>
        <Route exact path="/admin/setting">
          <Setting />
        </Route>
        <Route exact path="/admin/message">
          <Message />
        </Route>
        <Route exact path="/admin/bank">
          <BankAccount />
        </Route>
        <Route exact path="/admin/bank/add">
          <AddBankAccount />
        </Route>
        <Route exact path="/admin/bank/:id">
          <BankAccountDetail />
        </Route>
        <Route exact path="/admin/operator">
          <AdminAccount />
        </Route>
        <Route exact path="/admin/operator/add">
          <AddAdminAccount />
        </Route>
        <Route exact path="/admin/operator/:id">
          <AdminAccountDetail />
        </Route>
        <Route exact path="/admin">
          <Home />
        </Route>
        <Redirect to="/admin" />
      </Switch>
    </SharedLayout>
  );
}

export default RootAdmin;
