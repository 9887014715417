import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { useAppContext } from "../context/AppContext";
import Password from "../other/Password";

function ChangePassword() {
  const { setPageTitle } = useAppContext();

  useEffect(() => {
    setPageTitle("Change Password");
  }, []);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="row"
      justifyContent="center"
    >
      <Grid item md={6} xs={12}>
        <Password admin />
      </Grid>
    </Grid>
  );
}
export default ChangePassword;
