import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import LibLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import logoImage from "../assets/logo.png";
import Copyright from "../components/Copyright";
import LanguageSwitch from "../components/LanguageSwitch";
import Loader from "../components/Loader";
import PhoneField from "../components/PhoneField";
import TextField, { PureTextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import {
  MIN_RULE,
  MUST_EQUAL,
  PHONE_RULE,
  REQUIRED_RULE,
} from "../helpers/Form";
import useAnyApi from "../hooks/api/useAnyApi";
import useForm from "../hooks/useForm";
import useLocalStorage from "../hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    width: 150,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();

  const [t] = useTranslation();
  const form = useForm();
  const { handleSubmit, watch } = form;

  const password = useRef();
  password.current = watch("password", "");

  const { signUp } = useAuthContext();
  const { setMessage } = useAppContext();
  const history = useHistory();

  const { reference: refBy = "" } = useParams();

  const [reference, setReference] = useLocalStorage("referece");

  const [loading, invokeSignup] = useAnyApi();

  useEffect(() => {
    if (!refBy) {
      return;
    }

    setReference(refBy);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refBy]);

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  async function onSubmit(data) {
    try {
      const { username } = data;
      await invokeSignup(() => signUp({ ...data, reference }));
      setMessage("Sign up successfully. Please proceed to login");
      history.push("/login", { username });
    } catch (ex) {
      handleError(ex);
    }
  }

  function handleError({ message = "" }) {
    if (message.includes("MobileExistsException")) {
      return setMessage({ message: "Phone number already exists" });
    }

    setMessage({ message });
  }

  function renderContent() {
    return (
      <FormProvider {...form}>
        <form className={classes.form} {...formProps}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PhoneField
                label={t("Phone No")}
                name="mobile"
                rules={{
                  ...REQUIRED_RULE,
                  ...PHONE_RULE,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("Username")}
                name="username"
                rules={REQUIRED_RULE}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                label={t("Password")}
                type="password"
                rules={{ ...REQUIRED_RULE, ...MIN_RULE(6) }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="confPassword"
                label={t("Re-Type Password")}
                type="password"
                rules={{
                  ...REQUIRED_RULE,
                  ...MUST_EQUAL(password.current, "The passwords do not match"),
                }}
              />
            </Grid>
            {reference && (
              <Grid item xs={12}>
                <PureTextField
                  readOnly
                  label={t("Reference By")}
                  value={reference}
                />
              </Grid>
            )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("Register")}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <LibLink
                color="secondary"
                component={Link}
                to="login"
                variant="body2"
              >
                {t("Already have an account? Sign in")}
              </LibLink>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={logoImage} alt="logo" />
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          {t("Register")}
        </Typography>
        {loading ? <Loader /> : renderContent()}
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      <Box mt={2} align="center">
        <LanguageSwitch />
      </Box>
    </Container>
  );
}
