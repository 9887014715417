import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { formatAmount, formatBetAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import { numberWithCommas } from "../helpers/String";
import useAdminCredits from "../hooks/api/useAdminCredits.Admin";
import useMount from "../hooks/useMount";
import Filter from "./Filter";

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#ffc107" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({
  paddingLeft: 8,
  paddingRight: 8,
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

const TYPE = {
  withdraw: "Approved Withdraw",
  topup: "Approved Topup",
  winning: "Approved Winning",
  addCredit: "Add Credit by {{addBy}}",
};

const tStart = moment().startOf("day");
const tEnd = moment().endOf("day");

function CreditTxns({ id }) {
  const [response = [], loading, refresh, search] = useAdminCredits(id);
  const [showFilter, setShowFilter] = useState();

  const summary = useMemo(() => {
    const approved = response.filter((s) => s.type !== "addCredit");

    console.log({ approved });

    const totalAmount = numberWithCommas(
      formatAmount(
        approved.reduce((prev, { amount }) => prev + -amount, 0),
        false
      )
    );

    return {
      totalAmount,
      totalCount: approved.length,
    };
  }, [response]);

  useMount(() => {
    search({
      from: tStart.toJSON(),
      to: tEnd.toJSON(),
    });
  });

  function renderSummary() {
    if (loading) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SummaryCard>
              <Value $primary>{summary?.totalAmount}</Value>
              <Title>Total Amount ($)</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <SummaryCard>
              <Value $primary>{summary?.totalCount}</Value>
              <Title>Total Approved</Title>
            </SummaryCard>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const headCells = [
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
    {
      id: "amount",
      numeric: false,
      label: "Amount",
      format: ({ amount }) => formatBetAmount(amount),
    },
    {
      id: "type",
      numeric: false,
      label: "Remark",
      format: ({ type, addBy }) => `${TYPE[type]}`.replace("{{addBy}}", addBy),
    },
  ];

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      if (!data) {
        await refresh();
      } else {
        await search(data);
      }
    },
  };
  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Credits",
    defaultSort: "createdAt",
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      {renderSummary()}
      <Grid item xs={12}>
        {loading ? <Loader paper /> : <Table {...tableProps} />}
      </Grid>
      <Filter {...filterProps} />
    </>
  );
}

export default CreditTxns;
