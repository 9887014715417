import { API_URL } from "../../constants/EnvConfig";
import { useWalletContext } from "../../context/WalletContext";
import useAuthFetch from "../useAuthFetch";

function useTopup() {
  const { selected } = useWalletContext();

  const url = `${API_URL}/walletTxn/${selected}`;
  const { loading, post } = useAuthFetch({});

  function create(body) {
    return post({ body, url });
  }

  return [loading, create];
}

export default useTopup;
