import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { delay } from "../helpers/Common";
import useUserAccount from "../hooks/api/useUserAccount";
import useLocalStorage from "../hooks/useLocalStorage";
import { useThemeContext } from "./ThemeContext";

export const WalletContext = createContext();

function WalletContextProvider(props) {
  const { setTheme } = useThemeContext();

  const [acc, loading, refresh] = useUserAccount();
  const [selected, setSelected] = useLocalStorage("wallet");
  const [forceAccLoading, setForceAccLoading] = useState();

  const anyLoading = forceAccLoading || loading;

  const account = useMemo(() => {
    if (anyLoading || !acc) {
      return;
    }

    const { winningGD, winningMKT, walletGD, walletMKT } = acc ?? {};
    return {
      ...acc,
      wallet: selected === "GD" ? walletGD : walletMKT,
      winning: selected === "GD" ? winningGD : winningMKT,
    };
  }, [anyLoading, acc, selected]);

  useEffect(() => {
    setTheme(selected);
  }, [selected, setTheme]);

  const delayRefresh = useCallback(
    async (delayTime) => {
      if (!delayTime) {
        return refresh();
      }

      setForceAccLoading(true);
      await delay(delayTime);
      await refresh();
      setForceAccLoading(false);
      return;
    },
    [refresh]
  );

  const userAccount = [account, anyLoading, delayRefresh, setForceAccLoading];

  const value = {
    selected,
    setSelected,

    amount: account?.wallet || 0,

    userAccount,
  };
  const ctxProps = { ...props, value };
  return <WalletContext.Provider {...ctxProps} />;
}

export const useWalletContext = () => useContext(WalletContext);

export default WalletContextProvider;
