import QRCode from "qrcode.react";
import React, { useState } from "react";
import styled from "styled-components";
import { PureTextField as TextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import Modal from "./Modal";

const Content = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 320,
});

const Container = styled.div({
  background: "#fff",
  padding: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 20,
});

const ButtonContainer = styled.div({
  paddingTop: 16,
  width: "100%",
});

function QrCodeModal({ value, open, onClose }) {
  const modalProps = { open, onClose };
  const qrCode = { value, size: 192 };

  const [whatsappPhone, setWhatsappPhones] = useState();

  const { setMessage } = useAppContext();

  function whatsappMobile() {
    const encoded = encodeURIComponent(value);

    if (!whatsappPhone) {
      const url = `https://wa.me/?text=${encoded}`;
      window.open(url, "_blank");
      return;
    }

    const mobile =
      whatsappPhone.startsWith("+6") || whatsappPhone.startsWith("6")
        ? whatsappPhone
        : `6${whatsappPhone}`;
    const url = `https://wa.me/${mobile}?text=${encoded}`;
    window.open(url, "_blank");
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  function copyToClipboard() {
    copyTextToClipboard(value);
    setMessage("Copied to clipboard");
  }

  const waProps = {
    label: "Share To Whatsapp",
    type: "whatsapp",
    value: whatsappPhone,
    noForceNumber: true,
    onChange: ({ target }) => setWhatsappPhones(target?.value),
    onIconClick: () => whatsappMobile(),
  };

  const copyProps = {
    label: "Copy to clipboard",
    type: "copy",
    value,
    onIconClick: () => copyToClipboard(),
    readOnly: true,
  };

  return (
    <Modal {...modalProps}>
      <Content>
        <Container>
          <QRCode {...qrCode} />
        </Container>
        <ButtonContainer>
          <TextField {...copyProps} />
        </ButtonContainer>
        <ButtonContainer>
          <TextField {...waProps} />
        </ButtonContainer>
      </Content>
    </Modal>
  );
}

export default QrCodeModal;
