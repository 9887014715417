import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useNumberSummary(drawDate) {
  const url = drawDate ? `${API_URL}/admin/dashboard/${drawDate}` : "";
  const { response, loading, get } = useAuthFetch({ url });

  function refresh(date) {
    const url = `${API_URL}/admin/dashboard/${date || drawDate}`;
    return get({ url });
  }

  return [response, loading, refresh];
}

export default useNumberSummary;
