import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import LibSelect from "@material-ui/core/Select";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

const AppSelect = styled(LibSelect)(({ $slim9 }) => ({
  fontSize: $slim9 ? 12 : "1rem",
  width: $slim9 ? 75 : "100%",
  marginRight: $slim9 ? 5 : 0,

  select: $slim9 && {
    paddingLeft: 8,
    paddingRight: 0,
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

const Slim9FormControl = styled(FormControl)(({ $slim9 }) => ({
  marginTop: $slim9 && 0,
  marginBottom: $slim9 && 0,
}));

const Select = ({
  items = [],
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
  forceNumber,
  displayEmpty = true,

  onBlur,
  slim9,

  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const props = {
    label,
    inputRef,
    onChange,
    onBlur: onInputBlur,
    value,
    error,
    disabled,
    native: true,
    displayEmpty: true,
    $slim9: slim9,
  };

  const errProps = {
    error,
  };

  function onInputBlur(e) {
    if (!forceNumber) {
      onBlur && onBlur(e);
      return;
    }

    const valid = new RegExp(/^-?\d+(\.\d{0,2})?$/).test(value);
    const updated = valid ? +value : "";
    onChange(updated);
    onBlur && onBlur(e);
  }

  const slim9Props = {
    margin: "dense",
    fullWidth: false,
    $slim9: true,
  };

  const contProps = {
    error,
    variant: "outlined",
    fullWidth: true,
    ...(slim9 ? slim9Props : {}),
    ...rest,
  };

  return (
    <Slim9FormControl {...contProps}>
      <InputLabel>{label}</InputLabel>
      <AppSelect {...props}>
        {displayEmpty && <option value="" />}
        {items.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </AppSelect>
      {message && <FormHelperText {...errProps}>{message}</FormHelperText>}
    </Slim9FormControl>
  );
};

export default Select;
