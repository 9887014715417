import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

export const mktTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#f50057",
    },
    secondary: {
      main: "#ffc107",
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: "rgba(255,255,255,0.7)",
      },
    },
  },
});

// A custom theme for this app
export const gdTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#ffc107",
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: "rgba(255,255,255,0.7)",
      },
    },
  },
});
