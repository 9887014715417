import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import { formatBetAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import { numberWithCommas } from "../helpers/String";
import useCommTxns from "../hooks/api/useCommTxns.Admin";
import useMount from "../hooks/useMount";
import useSearchParams from "../hooks/useSearchParams";
import Filter from "./Filter";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
};

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS_COLOR[status],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#ffc107" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({
  paddingLeft: 8,
  paddingRight: 8,
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

const TODAY_START = moment().startOf("day");
const TODAY_END = moment().endOf("day");

const DEFAULT_PARAMS = {
  createdAtStart: TODAY_START.toJSON(),
  createdAtEnd: TODAY_END.toJSON(),
};

function CommTxns() {
  const { setPageTitle } = useAppContext();

  const [params, setParams] = useSearchParams();

  const [txns = [], loading, refresh1, search1] = useCommTxns();
  const [showFilter, setShowFilter] = useState();

  const { url } = useRouteMatch();

  const summary = useMemo(() => {
    const approved = txns.filter((s) => s.status === "approved");
    const totalAmount = approved.reduce((prev, { amount }) => prev + amount, 0);

    return {
      totalAmount: numberWithCommas(totalAmount),
      totalCount: approved.length,
    };
  }, [txns]);

  const refresh = useCallback(() => {
    setParams();
    refresh1();
  }, [refresh1, setParams]);

  const search = useCallback(
    (data) => {
      setParams(data);
      search1(data);
    },
    [search1, setParams]
  );

  useMount(() => {
    setPageTitle(`Commission Transactions`);
  });

  useMount(() => {
    search(params ?? DEFAULT_PARAMS);
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Txn No.",
      custom: renderStatus,
      customHeader: renderStatusHeader,
    },
    {
      id: "userId",
      numeric: false,
      label: "Username",
    },
    {
      id: "downline",
      numeric: false,
      label: "Downline",
    },
    {
      id: "amount",
      numeric: false,
      label: "Amount",
      format: ({ amount }) => formatBetAmount(amount),
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status head>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { id, userId, status } = item;

    return (
      <StatusContent>
        <Dot status={status} />

        <StatusText to={`${url}/${userId}/${id}`}>{id} </StatusText>
      </StatusContent>
    );
  }

  function renderSummary() {
    if (loading) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SummaryCard>
              <Value $primary>{summary?.totalAmount}</Value>
              <Title>Total Amount ($)</Title>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <SummaryCard>
              <Value $primary>{summary?.totalCount}</Value>
              <Title>Total Approved</Title>
            </SummaryCard>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      if (!data) {
        await refresh();
      } else {
        await search(data);
      }
    },
  };
  const tableProps = {
    headCells,
    items: txns,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Transactions",
    defaultSort: "createdAt",
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      <Grid container spacing={2}>
        {renderSummary()}
        <Grid item xs={12}>
          {loading ? <Loader paper /> : <Table {...tableProps} />}
        </Grid>
      </Grid>
      <Filter {...filterProps} />
    </>
  );
}

export default CommTxns;
