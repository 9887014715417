import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useCommTxns() {
  const url = `${API_URL}/admin/commTxn`;
  const { response, loading, get, post } = useAuthFetch({});

  function refresh() {
    return get({ url });
  }

  function search(body) {
    return post({ url: `${url}/search`, body });
  }

  return [response, loading, refresh, search];
}

export default useCommTxns;
