import { API_URL } from "../../constants/EnvConfig";
import { useWalletContext } from "../../context/WalletContext";
import useAuthFetch from "../useAuthFetch";

function useCreateWithdrawTxn() {
  const { selected } = useWalletContext();
  const { response, loading, post } = useAuthFetch({});

  function create(body) {
    const { fromAccount } = body;
    // COM ONLY AT MKT WALLET
    const wallet = fromAccount === "COM" ? "mkt" : selected;
    const url = `${API_URL}/withdrawTxn/${wallet}`;
    return post({ url, body });
  }

  return [response, loading, create];
}

export default useCreateWithdrawTxn;
