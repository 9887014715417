import { API_URL } from "../../constants/EnvConfig";
import { useWalletContext } from "../../context/WalletContext";
import useAuthFetch from "../useAuthFetch";

function useBetTxn(id) {
  const { selected } = useWalletContext();
  const url = id ? `${API_URL}/betTxn/${selected}/${id}` : "";
  const { response, loading, get } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  return [response, loading, refresh];
}

export default useBetTxn;
