import React, { createContext, useContext, useState } from "react";
import { gdTheme, mktTheme } from "../theme";

export const ThemeContext = createContext();

function ThemeContextProvider(props) {
  const [theme, setTheme] = useState();

  const value = {
    theme: theme !== "GD" ? gdTheme : mktTheme,
    setTheme,
  };
  const ctxProps = { ...props, value };
  return <ThemeContext.Provider {...ctxProps} />;
}

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeContextProvider;
