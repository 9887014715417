import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useCreateAdminCredit() {
  const url = `${API_URL}/admin/adminCredit/`;
  const { response, loading, get, post } = useAuthFetch({});

  function create(body) {
    return post({
      url,
      body,
    });
  }

  return [response, loading, create];
}

export default useCreateAdminCredit;
