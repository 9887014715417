import LibContainer from "@material-ui/core/Container";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import { useAppContext } from "../context/AppContext";
import useWindowSize from "../hooks/useWindowSize";
import NavBar from "./NavBar.Admin";

const Root = styled.div({});

const AppBarFlex = styled.div(({ theme }) => theme.mixins.toolbar);

const Main = styled.main(({ $height, theme }) => ({
  overflow: "auto",
  height: $height,
}));

const Container = styled(LibContainer).attrs({
  component: "main",
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(10),
  paddingLeft: 0,
  paddingRight: 0,
  flex: 1,
}));

function SharedLayout({ children }) {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();

  const { height } = useWindowSize();
  const { pageTitle: title } = useAppContext();

  const drawerProps = {
    open,
    setOpen,
  };

  return (
    <Root>
      <NavBar {...drawerProps} title={title} />
      <Drawer {...drawerProps} />
      <Main $height={height}>
        <AppBarFlex />
        <Container>{children}</Container>
      </Main>
    </Root>
  );
}

export default SharedLayout;
