import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { MIN_RULE, MUST_EQUAL, REQUIRED_RULE } from "../helpers/Form";
import useAnyApi from "../hooks/api/useAnyApi";
import useForm from "../hooks/useForm";

function ChangePassword({ admin }) {
  const { setMessage } = useAppContext();

  const [t] = useTranslation();

  const pwdform = useForm();
  const { handleSubmit, watch, reset } = pwdform;

  const password = useRef();
  password.current = watch("newPassword", "");

  const { user, changePassword } = useAuthContext();

  const [pwdLoading, invokeChangePwd] = useAnyApi();

  useEffect(() => {
    reset(user);
  }, [reset, user]);

  const pwdFormProps = {
    noValidate: true,
    onSubmit: handleSubmit(onPwdSubmit),
  };

  async function onPwdSubmit({ password, newPassword }) {
    try {
      await invokeChangePwd(() => changePassword({ password, newPassword }));
      setMessage("Password updated");
    } catch (ex) {
      setMessage(ex);
    }

    reset({
      password: "",
      confPassword: "",
      newPassword: "",
    });
  }

  if (pwdLoading) {
    return <Loader paper />;
  }

  return (
    <FormProvider {...pwdform}>
      <Card component="form" {...pwdFormProps}>
        <CardContent>
          <Grid container spacing={2}>
            {!admin && (
              <Grid item xs={12}>
                <TextField label={t("Phone No")} name="mobile" readOnly />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="password"
                label={t("Password")}
                type="password"
                rules={{ ...REQUIRED_RULE, ...MIN_RULE(6) }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="newPassword"
                label={t("New Password")}
                type="password"
                rules={{
                  ...REQUIRED_RULE,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="confPassword"
                label={t("Re-Type Password")}
                type="password"
                rules={{
                  ...REQUIRED_RULE,
                  ...MUST_EQUAL(password.current, "The passwords do not match"),
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button type="submit" color="primary">
                {t("Update Password")}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </FormProvider>
  );
}
export default ChangePassword;
