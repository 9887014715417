import LibCard from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { formatAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";
import useBetTxn from "../hooks/api/useBetTxn.Admin";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
  void: grey[500],
};

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function BetNowConfirm() {
  const { setPageTitle, setMessage } = useAppContext();
  const { id = "", wallet, userId } = useParams();
  const [txn, loading, refresh] = useBetTxn(wallet, id, userId);

  const [whatsappPhone, setWhatsappPhones] = useState();

  const { api28, status, grandTotal, drawType, createdAt, updatedAt } =
    txn || {};
  const { receipt, message, orderId } = api28 || {};

  useEffect(() => {
    setPageTitle("Bet Detail");
  }, []);

  useEffect(() => {
    refresh();
  }, [id, wallet, userId]);

  function getReceipt() {
    if (!txn) {
      return;
    }

    return `${receipt.replace("\r\n\r\n", "\r\n")}TXNID=${id}`;
  }

  function whatsappMobile() {
    if (!whatsappPhone) {
      const url = `https://wa.me/?text=${encodeURIComponent(getReceipt())}`;
      window.open(url, "_blank");
      return;
    }

    const mobile =
      whatsappPhone.startsWith("+6") || whatsappPhone.startsWith("6")
        ? whatsappPhone
        : `6${whatsappPhone}`;
    const url = `https://wa.me/${mobile}?text=${encodeURIComponent(
      getReceipt()
    )}`;
    window.open(url, "_blank");
  }

  function renderBetResult() {
    const waProps = {
      label: "Whatsapp Number",
      name: "mboile",
      type: "whatsapp",
      value: whatsappPhone,
      noForceNumber: true,
      onChange: ({ target }) => setWhatsappPhones(target?.value),
      onIconClick: () => whatsappMobile(),
    };
    return (
      <Grid item xs={12} key={orderId}>
        <Card>
          <Line status={status} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Txn No." readOnly value={id} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Username" readOnly value={userId} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Status" readOnly value={capitalize(status)} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Draw Type" readOnly value={drawType} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Grand Total"
                  readOnly
                  value={formatAmount(grandTotal)}
                />
              </Grid>
              {receipt && (
                <Grid item xs={12}>
                  <TextField
                    label="Receipt"
                    readOnly
                    rows={8}
                    value={getReceipt()}
                  />
                </Grid>
              )}
              {message && (
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    readOnly
                    rows={4}
                    value={message}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Last updated at"
                  readOnly
                  value={formatAll(updatedAt)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Created at"
                  readOnly
                  value={formatAll(createdAt)}
                />
              </Grid>
              {receipt && (
                <Grid item xs={12}>
                  <TextField {...waProps} />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function renderContent() {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid item md={6} xs={12}>
          {loading ? <Loader paper /> : renderBetResult()}
        </Grid>
      </Grid>
    );
  }

  return loading ? <Loader paper /> : renderContent();
}
export default BetNowConfirm;
