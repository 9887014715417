import LibCard from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import { PureTextField as TextField } from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { formatBetAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";
import useCommTxn from "../hooks/api/useCommTxn";

const Line = styled.div(({ approved }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: approved ? green[500] : red[900],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function TxnDetailComm() {
  const { setPageTitle } = useAppContext();
  const { id = "" } = useParams();
  const [txn, txnLoading] = useCommTxn(id);

  const loading = txnLoading;

  const { amount, downline, createdAt, status, desc } = txn || {};
  const approved = status === "approved";

  useEffect(() => {
    setPageTitle("Commission Detail");
  }, []);

  function renderTxnDetail() {
    const txnType = amount > 0 ? "Top up" : "Withdraw";
    return (
      <>
        <Grid item xs={12}>
          <TextField label="Txn Type" readOnly value={txnType} />
        </Grid>
        {downline && (
          <Grid item xs={12}>
            <TextField label="Downline" readOnly value={downline} />
          </Grid>
        )}
        {desc && (
          <Grid item xs={12}>
            <TextField rows={4} label="Remark" readOnly value={desc} />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField label="Created at" readOnly value={formatAll(createdAt)} />
        </Grid>
      </>
    );
  }

  function renderWithdrawTxn() {
    if (!txn) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Card>
          <Line status={status} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Txn No." readOnly value={id} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Status" readOnly value={capitalize(status)} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  readOnly
                  value={formatBetAmount(amount)}
                />
              </Grid>
              {desc && (
                <Grid item xs={12}>
                  <TextField rows={4} label="Remark" readOnly value={desc} />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function renderCommTxn() {
    if (!txn) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Card>
          <Line approved={approved} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Txn No." readOnly value={id} />
              </Grid>
              <TextField label="Status" readOnly value={capitalize(status)} />
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  readOnly
                  value={formatBetAmount(amount)}
                />
              </Grid>
              {renderTxnDetail()}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function renderContent() {
    return (
      <Grid container spacing={2}>
        {amount < 0 ? renderWithdrawTxn() : renderCommTxn()}
      </Grid>
    );
  }

  return loading ? <Loader paper /> : renderContent();
}

export default TxnDetailComm;
