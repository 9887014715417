import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import HelpButton from "../components/HelpButton";
import Loader from "../components/Loader";
import Select from "../components/Select";
import TextField from "../components/TextField";
import { BANKS } from "../constants/Withdraw";
import { useAppContext } from "../context/AppContext";
import { useWalletContext } from "../context/WalletContext";
import { formatAmount } from "../helpers/Common";
import { MAX_RULE, MIN_RULE, REQUIRED_RULE } from "../helpers/Form";
import useCreateWithdrawTxn from "../hooks/api/useCreateWithdrawTxn";
import useTopup from "../hooks/api/useTopup";
import useForm from "../hooks/useForm";
import BankAccount from "./BankAccount";
import Confirm from "./Confirm.Withdraw";
import Transfer from "./Transfer";

const MESSAGE = {
  ME: "Withdraw succesful",
  BANK: "Withdrawal request submitted",
  USER: `Transfered to {{transferUserId}}`,
};

const TO_ACCOUNT = [
  {
    text: "My Wallet",
    value: "ME",
  },
  {
    text: "Bank Account",
    value: "BANK",
  },
  {
    text: "Other User",
    value: "USER",
  },
];

function Withdraw() {
  const { setPageTitle, setMessage } = useAppContext();
  const { selected, userAccount } = useWalletContext();
  const [account, accLoading, refreshAccount] = userAccount;
  const { winning, commission } = account ?? {};

  const [, loading, createTxn] = useCreateWithdrawTxn();
  const [topupLoading, createTopup] = useTopup();

  const [showConfirmation, setShowConfirmation] = useState();

  const [t] = useTranslation();

  const methods = useMemo(() => {
    const defaultMethods = [
      {
        text: `${t("Winning Amount")} (${
          accLoading ? "..." : formatAmount(winning)
        })`,
        value: "WIN",
      },
    ];

    if (selected === "GD") {
      return defaultMethods;
    }

    return [
      ...defaultMethods,
      {
        text: `${t("Commission")} (${
          accLoading ? "..." : formatAmount(commission)
        })`,
        value: "COM",
      },
    ];
  }, [t, accLoading, selected, winning, commission]);

  const form = useForm({
    defaultValues: {
      fromAccount: methods[0]?.value,
      toAccount: TO_ACCOUNT[0]?.value,
      bankName: BANKS[0]?.value,
    },
  });

  const { handleSubmit, reset, watch } = form;
  const [fromAccount, toAccount] = watch(["fromAccount", "toAccount"]);

  const isTransfer = toAccount === "USER";

  const maxLimit = useMemo(() => {
    if (accLoading) {
      return "...";
    }

    if (fromAccount === "WIN") {
      return winning;
    }

    if (fromAccount === "COM") {
      return commission;
    }

    return 0;
  }, [commission, fromAccount, winning, accLoading]);

  useEffect(() => {
    setPageTitle("Withdraw / Transfer");
  }, []);

  function checkWalletAmount(fromAccount) {
    if (fromAccount === "WIN") {
      return winning > 0;
    }

    if (fromAccount === "COM") {
      return commission > 0;
    }

    return false;
  }

  async function onSubmit(data) {
    const { fromAccount } = data;
    const valid = checkWalletAmount(fromAccount);
    if (!valid) {
      setMessage({ message: "Amount must more than zero" });
      return;
    }

    setShowConfirmation(data);
  }

  async function onConfirm() {
    toAccount === "ME" ? onTopupConfirm() : onWithdrawConfirm();
  }

  async function onTopupConfirm() {
    const { amount, fromAccount: method } = showConfirmation;

    const topupParams = {
      amount,
      method,
    };

    try {
      await createTopup(topupParams);
      reset();

      setMessage(MESSAGE.ME);
      refreshAccount(2500);
    } catch (ex) {
      setMessage(ex);
    }
  }

  async function onWithdrawConfirm() {
    const { transferUserId, bankName, accountNumber, accountName, ...data } =
      showConfirmation;

    const bankParams = {
      bankName,
      accountNumber,
      accountName,
    };

    const transferParams = {
      transferUserId,
    };

    try {
      await createTxn({
        ...data,
        ...(toAccount === "BANK" && bankParams),
        ...(toAccount === "USER" && transferParams),
      });
      reset();

      const message = MESSAGE[toAccount].replace(
        "{{transferUserId}}",
        transferUserId
      );

      setMessage(message);
      refreshAccount(2500);
    } catch (ex) {
      setMessage(ex);
    }
  }

  const formProps = {
    noValidate: true,
    onSubmit: handleSubmit(onSubmit),
  };

  function renderModal() {
    const props = {
      ...showConfirmation,
      toAccount,
      loading: loading || topupLoading,
      open: !!showConfirmation,
      onClose: () => setShowConfirmation(),
      onConfirm,
    };

    return <Confirm {...props} />;
  }

  function renderContent() {
    const amtProps = {
      type: "tel",
      rules: {
        ...REQUIRED_RULE,
        ...MIN_RULE(0),
        ...MAX_RULE(maxLimit),
      },
      endIcon: <InputAdornment position="end">/ {maxLimit}</InputAdornment>,
    };

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  label={t("Withdraw Type")}
                  name="toAccount"
                  items={TO_ACCOUNT}
                  displayEmpty={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label={t("Withdraw From")}
                  name="fromAccount"
                  items={methods}
                  displayEmpty={false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("Withdraw Amount")}
                  name="amount"
                  noDecimalPoint
                  {...amtProps}
                />
              </Grid>
              {toAccount === "BANK" && <BankAccount />}
              {toAccount === "USER" && <Transfer />}
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              {t(isTransfer ? "Transfer" : "Withdraw")}
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} component="form" {...formProps}>
      <Grid item xs={12}>
        {loading || topupLoading ? <Loader /> : renderContent()}
      </Grid>
      <Grid item xs={12}>
        <HelpButton />
      </Grid>
      {renderModal()}
    </Grid>
  );
}
export default Withdraw;
