import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import { PureTextField as TextField } from "../components/TextField";
import { STATUS_COLOR } from "../constants/Txn";
import { formatBetAmount } from "../helpers/Common";
import { formatAll } from "../helpers/DateTime";
import { capitalize } from "../helpers/String";

const Line = styled.div(({ status }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 5,
  width: "100%",
  backgroundColor: STATUS_COLOR[status],
}));

const Card = styled(LibCard)({
  position: "relative",
  paddingTop: 15,
});

function WithdrawActions({ txn }) {
  const { withdrawTxnId, topupTxnId, wallet, userId } = txn || {};

  return (
    <CardActions>
      {topupTxnId && (
        <Button
          size="small"
          component={Link}
          color="primary"
          to={`/admin/txn/wallet/${wallet}/${userId}/${topupTxnId}`}
        >
          View Top up
        </Button>
      )}
      {withdrawTxnId && (
        <Button
          size="small"
          component={Link}
          color="primary"
          to={`/admin/txn/withdraw/${wallet}/${userId}/${withdrawTxnId}`}
        >
          View Withdraw
        </Button>
      )}
    </CardActions>
  );
}

function WithdrawWinning({ txn }) {
  const { id = "", userId } = useParams();

  const {
    status,

    // withdraw
    amount,
    desc,
    createdAt,
  } = txn || {};

  return (
    <Grid item xs={12}>
      <Card>
        <Line status={status} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Txn No." readOnly value={id} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Username" readOnly value={userId} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Status" readOnly value={capitalize(status)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Amount"
                readOnly
                value={formatBetAmount(amount)}
              />
            </Grid>
            {desc && (
              <Grid item xs={12}>
                <TextField rows={4} label="Remark" readOnly value={desc} />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                label="Created at"
                readOnly
                value={formatAll(createdAt)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <WithdrawActions txn={txn} />
      </Card>
    </Grid>
  );
}

export default WithdrawWinning;
