import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useSettings() {
  const url = `${API_URL}/setting`;
  const { response, loading, get } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  return [response, loading, refresh];
}

export default useSettings;
