import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "../components/TextField";
import { useAuthContext } from "../context/AuthContext";
import { SAME_USER_RULE } from "../helpers/Form";

function Transfer() {
  const [t] = useTranslation();
  const { user } = useAuthContext();

  return (
    <Grid item xs={12}>
      <TextField
        label={t("Transfer User Id")}
        name="transferUserId"
        rules={{
          ...SAME_USER_RULE(user.id),
        }}
      />
    </Grid>
  );
}
export default Transfer;
