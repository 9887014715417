import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { formatBetAmount } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import useUserWalletTxns from "../hooks/api/useUserWalletTxns.Admin";

const STATUS_COLOR = {
  new: amber[500],
  approved: green[500],
  rejected: red[900],
};

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: STATUS_COLOR[status],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusText = styled(Link)(({ head, theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

const StatusHeader = ({ label }) => {
  return (
    <StatusContent>
      <Status head>{label}</Status>
    </StatusContent>
  );
};

const StatusColumn = ({ item }) => {
  const { id, status, wallet, userId } = item;

  return (
    <StatusContent>
      <Dot status={status} />
      <StatusText to={`/admin/txn/wallet/${wallet}/${userId}/${id}`}>
        {id}
      </StatusText>
    </StatusContent>
  );
};

function WalletTxns({ wallet }) {
  const { id = "" } = useParams();

  const [items, loading] = useUserWalletTxns(wallet, id);

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Txn No",
      custom: (item) => <StatusColumn item={item} />,
      customHeader: (label) => <StatusHeader label={label} />,
    },
    {
      id: "amount",
      numeric: false,
      label: "Amount",
      format: ({ amount, bonusAmount = 0 }) =>
        formatBetAmount(amount + bonusAmount),
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  const tableProps = {
    headCells,
    items,
    rowsPerPage: 25,
    noEmptyRows: true,
    defaultOrder: "desc",
    uniqueId: "id",
    title: `Wallet ${wallet === "mkt" ? 1 : 2}`,
    defaultSort: "createdAt",
  };

  return loading ? <Loader paper /> : <Table {...tableProps} />;
}

export default WalletTxns;
