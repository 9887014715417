import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Account from "../account/Account";
import Logout from "../auth/Logout";
import BetHistory from "../betHistory/BetHistory";
import BetNow from "../betNow/BetNow";
import BetNowConfirm from "../betNow/BetNow.Confirm";
import SharedLayout from "../components/SharedLayout";
import { useWalletContext } from "../context/WalletContext";
import Downline from "../downline/Downline";
import DrawResult from "../drawResult/DrawResult";
import Home from "../home/Home";
import Main from "../main/Main";
import Other from "../other/Other";
import QrCode from "../qrCode/QrCode";
import Topup from "../topup/Topup";
import TxnDetailComm from "../txnDetail/TxnDetail.Comm";
import TxnDetailWallet from "../txnDetail/TxnDetail.Wallet";
import TxnDetailWin from "../txnDetail/TxnDetail.Win";
import Withdraw from "../withdraw/Withdraw";

function Root() {
  // const { user } = useAuthContext();
  const { selected } = useWalletContext();

  if (!selected) {
    return (
      <SharedLayout>
        <Switch>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Redirect to="/" />
        </Switch>
      </SharedLayout>
    );
  }

  return (
    <SharedLayout>
      <Switch>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route exact path="/bet">
          <BetNow />
        </Route>
        <Route exact path="/bet/rebuy/:id">
          <BetNow />
        </Route>
        <Route exact path="/bet/:id">
          <BetNowConfirm />
        </Route>
        <Route exact path="/win">
          <Account win />
        </Route>
        <Route exact path="/record">
          <BetHistory />
        </Route>
        <Route exact path="/record/:id">
          <BetNowConfirm />
        </Route>
        <Route exact path="/txn/wallet/:id">
          <TxnDetailWallet />
        </Route>
        <Route exact path="/txn/comm/:id">
          <TxnDetailComm />
        </Route>
        <Route exact path="/txn/win/:id">
          <TxnDetailWin />
        </Route>
        <Route exact path="/topup">
          <Topup />
        </Route>
        <Route exact path="/result">
          <DrawResult />
        </Route>
        <Route exact path="/qrcode">
          <QrCode />
        </Route>
        <Route exact path="/downline">
          <Downline />
        </Route>
        <Route exact path="/withdraw">
          <Withdraw />
        </Route>
        <Route exact path="/account">
          <Account />
        </Route>
        <Route exact path="/others">
          <Other />
        </Route>
        <Route exact path="/main">
          <Main />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Redirect to="/" />
      </Switch>
    </SharedLayout>
  );
}

export default Root;
