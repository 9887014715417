import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";
import Checkbox from "./Checkbox";

const Content = styled(FormControl).attrs({
  component: "fieldset",
})({});

function CheckboxesGroup({
  items = [],
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
  flex,
  slim9,

  noContainer,

  ...rest
}) {
  const { control } = useFormContext();

  const {
    field: { ref, onChange, value = [] },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (key) => {
    const found = value.includes(key);
    onChange(found ? value.filter((s) => s !== key) : [...value, key]);
  };

  const formCtrlProps = {
    ref,
    error,
  };

  if (noContainer) {
    return items.map((item) => (
      <Checkbox
        {...item}
        slim9={slim9}
        checked={value.includes(item.value)}
        onChange={handleChange}
      />
    ));
  }

  return (
    <Content {...formCtrlProps}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row={flex}>
        {items.map((item) => (
          <Checkbox
            {...item}
            slim9={slim9}
            checked={value.includes(item.value)}
            onChange={handleChange}
          />
        ))}
      </FormGroup>
      <FormHelperText>{message}</FormHelperText>
    </Content>
  );
}

export default CheckboxesGroup;
