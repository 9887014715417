import React, { createContext, useCallback, useContext, useState } from "react";
import { delay } from "../helpers/Common";
import useAdminAccount from "../hooks/api/useAdminAccount";

export const AdminContext = createContext();

function AdminContextProvider(props) {
  const [account, loading, refresh] = useAdminAccount();
  const [forceAccLoading, setForceAccLoading] = useState();

  const delayRefresh = useCallback(
    async (delayTime) => {
      if (!delayTime) {
        return refresh();
      }

      setForceAccLoading(true);
      await delay(delayTime);
      await refresh();
      setForceAccLoading(false);
      return;
    },
    [refresh]
  );

  const value = {
    account,
    loading: forceAccLoading || loading,
    refresh: delayRefresh,
  };
  const ctxProps = { ...props, value };
  return <AdminContext.Provider {...ctxProps} />;
}

export const useAdminContext = () => useContext(AdminContext);

export default AdminContextProvider;
